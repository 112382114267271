import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Challenge from '../components/challenge/Challenge';
import { getChallengeById } from '../redux/actions/dataActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
// import { useSwitch, UseSwitchParameters } from '@mui/base/SwitchUnstyled';
import { connect } from 'react-redux';
import { getUserProfileData, getUserQueueData } from '../redux/actions/userActions';
import { getChallenge, donateMoney } from '../redux/actions/dataActions';
import { fulfillMeedPoints } from '../redux/actions/userActions';

import { withStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { fetchConfig } from '../redux/actions/dataActions';
import donateStyles from './styles/donateStyles';
import { getRandomChallenge } from '../util/challengeGenerator';

let gettingChall = false;
let userInterval;
let viewing = false;

const auth = getAuth();
const analytics = getAnalytics();

/**
 * The width threshold (in pixels) below which the screen is considered to be a mobile device.
 * @const {number}
 */
const MOBILE_SCREEN_WIDTH = 960;
/**
 * The time (in milliseconds) after which a specific action or callback should be triggered.
 * In this context, it represents the delay after which the profile's state is set to 'bruh'.
 * @const {number}
 */
const PROFILE_TIMEOUT = 10000;

/**
 * Returns a list of challenges that are in the 'challengesAfter' list but not in the 'challengesBefore' list.
 * This helps identify newly completed challenges.
 * 
 * @function
 * @param {Array} challengesAfter - The list of challenges after the update.
 * @param {Array} challengesBefore - The list of challenges before the update.
 * @returns {Array} An array of challenges that are new in the 'challengesAfter' list.
 */
const getNewCompletedChallenges = (challengesAfter, challengesBefore) => {
  return challengesAfter.filter(challAfter =>
    !challengesBefore.some(challBefore => challAfter.challengeId === challBefore.challengeId)
  );
}

/**
 * Decodes an HTML-encoded string to its original representation.
 * This function creates a new DOMParser instance, parses the input string as HTML, and retrieves the text content,
 * effectively decoding HTML entities back to their original characters.
 *
 * @param {string} input - The HTML-encoded string to decode.
 * @return {string} The decoded string, with HTML entities converted back to their original characters.
 */
const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

/**
   * Filters the provided list of challenges to return only those that are accepted and active.
   * 
   * @function
   * @param {Array} challenges - The list of challenges to filter.
   * @returns {Array} An array of challenges that are both accepted and active.
   */
const filterAcceptedChallenges = (challenges, stateActive) => {
  return challenges.filter(chall => chall.status === 'accepted' && stateActive) || [];
}

const handlePayAmountChange = (value) => {
  if (value < 1) {
    value = '1';
  }
  if (value > 999999) {
    value = '999999';
  }
  return value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
};

/**
 * Compares two values and returns a positive number if the first value is greater, a negative number if the second value is greater, or zero if they are equal.
 *
 * @param {any} x - The first value to compare.
 * @param {any} y - The second value to compare.
 * @returns {number} A positive, negative, or zero value indicating the comparison result.
 */
const cmp = (x, y) => {
  return x > y ? 1 : (x < y ? -1 : 0);
}

/**
 * Sorts an array of challenges based on the provided sorting criteria.
 * 
 * @param {Object} a - The first challenge to compare.
 * @param {Object} b - The second challenge to compare.
 * @param {string} selectedValue - The sorting criteria, determining the attribute by which to sort the challenges.
 * @returns {number} A positive, negative, or zero value indicating the sorting order.
 */
const sortChallenges = (a, b, selectedValue) => {
  if (selectedValue === 'timeRemaining') {
    return new Date(a.createdAt) - new Date(b.createdAt);
  } else if (selectedValue === 'totalUpvotes') {
    return b.likeCount - a.likeCount;
  } else {
    // Assume the default case is to sort by 'initialCreatorPaymentAmount' and then by 'challengeName'
    // Ensure cmp is defined or imported from where it's declared
    return cmp(b.initialCreatorPaymentAmount, a.initialCreatorPaymentAmount) || cmp(a.challengeName, b.challengeName);
  }
};

class donate extends Component {
  state = {
    profile: {},
    challengeIdParam: '',
    clickedChallenge: {},
    payAmount: '',
    handle: '',
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    stripe: null,
    errors: {},
    twitchFrameParent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"],
    challengePanelOpen: false,
    challengeCreatePanelOpen: false,
    challengeSearchPanelOpen: false,
    chatPanel: true,
    chatPanelMobile: true,
    activeChallId: '',
    challengesQueue: [],
    challengesCreated: [],
    challengesRequested: [],
    challengesCompleted: [],
    challengesVoted: [],
    fetched: false,
    totalUpvotes: false,
    money: false,
    timeRemaining: false,
    stateOpen: true,
    stateActive: true,
    acceptTimeSmall: true,
    acceptTimeMedium: true,
    acceptTimeLarge: true,
    hidden: true,
    authed: false,
    hideStream: false,
    celebrate: false,
    sliderChall: 0,
    completeByDate: 'xxxxlarge',
    username: '',
    randomChallenge: '',
  };

  getData = async () => {
    const handle = this.props.match.params.handle,
      challengeId = this.props.match.params.challengeId,
      paymentStatus = this.props.match.params.paymentSuccess || this.props.match.params.paymentFailure,
      handler = e => this.setState({ matches: e.matches });

    //handle && await this.fetchUser(handle.toLowerCase());
    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.fetchUser(handle.toLowerCase())
      } else {
        //user is signed out
        this.fetchUser(handle.toLowerCase())
      }
    });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    this.setState({ handle: handle });
    if (challengeId && challengeId !== 'paymentSuccessful' && this.state.challengesRequested) {
      this.setState({
        challengeId: challengeId,
        challengePanelOpen: true,
        clickedChallenge: this.state.challengesRequested.filter((challenge) => { return challenge.challengeId === challengeId })[0]
      });
      if (challengeId)
        await this.props.getChallengeById(challengeId).then((chall) => {
          this.setState({ clickedChallenge: chall });
          this.viewChallenge(chall);
        });
    } else if (this.state.challengesRequested && this.state.challengesRequested.length < 1) {
      if (challengeId)
        await this.props.getChallengeById(challengeId).then((chall) => {
          this.setState({ clickedChallenge: chall });
        });
    }

    if (this.state.profile && !challengeId && this.state.profile.twitch && this.state.hideStream) {
      this.setState({ chatPanel: true });
    }

    if (paymentStatus === 'paymentSuccessful' || challengeId === 'paymentSuccessful') {
      // create stripe checkout session
      this.props.fulfillMeedPoints({
        approved: true
      }).then(res => {
        toast.success('Funds Added!');
        this.props.history.push(`/users/${this.props.match.params.handle}`)
      }).catch(err => {
        toast.error('Error: Funds did not get added!');
      });

    } else if (paymentStatus === 'paymentFailure') {
      this.props.fulfillMeedPoints({
        approved: false
      })
      toast.error('PAYMENT FAILED!');
    }
    // Function to refresh user data
    const refreshData = () => {
      // Calls the refreshUser method with the current user's handle
      // This is expected to update the user's data from the server
      this.refreshUser(this.props.match.params.handle);
    };

    // Check if the interval for refreshing data is not already set
    if (!userInterval) {
      // Set an interval to call refreshData every 60 seconds
      // This ensures our data stays fresh while the user is on the page
      userInterval = setInterval(refreshData, 60000);
    }

    // Function to handle visibility state changes of the page
    const handleVisibilityChange = () => {
      // When the page becomes visible to the user
      if (document.visibilityState === "visible") {
        // Immediately call refreshData to update the information
        refreshData();
        // Check if our interval is cleared and set it if necessary
        // This re-establishes regular data fetching when the page is in focus
        if (!userInterval) {
          userInterval = setInterval(refreshData, 60000);
        }
      } else {
        // If the page is not visible, clear the interval to stop fetching data
        // This optimizes resource usage when the user is not actively viewing the page
        clearInterval(userInterval);
        userInterval = null;
      }
    };

    // Add an event listener for detecting visibility state changes
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }

  /**
   * Refreshes user data and handles UI updates for challenge events.
   * Triggers toasts for new accepted and completed challenges.
   * 
   * @async
   * @function
   * @param {string} handle - The user's handle.
   * @throws Will throw an error if the request to getUserQueueData fails.
   */
  refreshUser = async (handle) => {
    try {
      // Fetch the latest user queue data
      const res = await this.props.getUserQueueData(handle);

      // If no response, exit the function
      if (!res) return;

      // Define stateActive from the component's state
      const { stateActive, selectedValue } = this.state;

      // Get the list of accepted challenges before the update
      const acceptedChallengesBefore = filterAcceptedChallenges(this.state.challengesQueue, stateActive);

      // Get the list of accepted challenges after the update, ensuring to pass stateActive
      const acceptedChallengesAfter = filterAcceptedChallenges(res.challengesRequested, stateActive);

      // If there's an increase in the number of accepted challenges, show a toast notification
      if (acceptedChallengesAfter.length > acceptedChallengesBefore.length) {
        const newAcceptedChallenge = acceptedChallengesAfter[0].challengeName;
        toast.success(`${handle} just accepted a challenge! Check the history tab below to see: ${htmlDecode(newAcceptedChallenge)}`, { autoClose: false });
      }

      // Update the state with the latest sorted challenges queue
      // TODO: Check why there is the "double sort" here
      this.setState({
        challengesQueue: res.challengesRequested.sort((a, b) => sortChallenges(a, b, selectedValue))
      });

      // If there are completed challenges in both the response and the current state
      if (res.challengesVoted && this.state.challengesVoted) {
        // Identify new completed challenges
        const newCompletedChallenges = getNewCompletedChallenges(res.challengesVoted, this.state.challengesVoted);
        // If there are new completed challenges
        if (newCompletedChallenges.length) {
          // Update the state to reflect the completion and celebrate
          this.setState({
            celebrate: true,
            challengesCompleted: res.challengesCompleted
          });
          // Show a toast notification for the completed challenge
          toast.success(`${handle} just moved a challenge to the voting stage! Check the voting tab to see: ${htmlDecode(newCompletedChallenges[0].challengeName)}`, { autoClose: false });

          // Set a timer to stop celebrating after 5 seconds
          setTimeout(() => {
            this.setState({ celebrate: false });
          }, 5000);
        }
      }

    } catch (error) {
      // Log any errors that occur during the process
      // console.log(error);
    }
  }

  updateChallenge = async (id) => {
    await this.refreshUser(this.props.match.params.handle);

    const matchingChallenges = this.state.challengesQueue?.filter((chall) => chall.challengeId === id);

    if (matchingChallenges.length > 0) {
      this.setState({
        clickedChallenge: matchingChallenges[0],
      });
    } else {
      this.setState({
        clickedChallenge: {},
        chatPanel: true,
        challengePanelOpen: false,
      });
    }
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle the "Challenge" field separately
    if (name === 'newChallenge') {
      this.handleNewChallengeChange(value);
      return;
    }

    // Handle other input fields
    let newValue = value;

    if (name === 'payAmount') {
      newValue = handlePayAmountChange(newValue);
    }

    if (name === 'useExisting') {
      newValue = type === 'checkbox' ? checked : value;
    }

    this.setState({ [name]: newValue });
  };

  handleNewChallengeChange = (value) => {
    // Clear the error for the Challenge field when the user interacts with it
    this.setState({
      newChallenge: value,
      errors: { ...this.state.errors, newChallenge: '' }
    });
  };

  getClass = async (yup) => {
    await setTimeout(() => {
      this.setState({
        moveInLeft: true
      })
    }, 250);
    return '';
  }

  fetchUser = async (handle) => {
    const userAuthed = this.props.user?.authenticated || null;

    try {
      const res = await this.props.getUserProfileData(handle, userAuthed);
      console.log("fetchuser res: ", res);
      console.log("props", this.props.user.credentials);
      if (res && res.user) {
        const {
          user,
          uid,
          challengesRequested,
          challengesCreated,
          challengesCompleted,
          challengesVoted,
          lastCreatedKey1,
          lastRequestedKey1,
          lastCompletedKey1,
          lastCreatedKey2,
          lastRequestedKey2,
          lastCompletedKey2,
          lastVotedKey1,
          lastVotedKey2,
        } = res;

        this.setState({
          profile: user,
          influencerId: uid,
          challengesQueue: challengesRequested,
          challengesCreated,
          challengesCompleted,
          challengesRequested,
          challengesVoted,
          lastCreatedKey1,
          lastRequestedKey1,
          lastCompletedKey1,
          lastCreatedKey2,
          lastRequestedKey2,
          lastCompletedKey2,
          lastVotedKey1,
          lastVotedKey2,
          fetched: true,
          openProfile: 'bruh',
        });
      } else {
        // User does not exist, redirect to NotFound
        this.props.history.push('/not-found');
      }
    } catch (err) {
      console.error(err);
      this.props.history.push('/not-found');
    }
  };

  openProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.innerWidth < 960 && this.state.profile.twitch && !this.state.hideStream) {
      this.setState({ openProfile: 'true' })
      setTimeout(() => this.setState({ openProfile: 'bruh' }), 10000)
    }
  }

  closeChat = () => {
    // Check for mobile screen size
    if (window.innerWidth < MOBILE_SCREEN_WIDTH) {
      this.setState({ openProfile: 'true' });
      setTimeout(() => this.setState({ openProfile: 'bruh' }), PROFILE_TIMEOUT);
      return;
    }

    // Check if profile.twitch is not present or if the stream is hidden
    if ((this.state.profile && !this.state.profile.twitch) || this.state.hideStream) {
      return;
    }

    this.setState({
      moveInLeft: false,
      chatPanel: !this.state.chatPanel,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
      hidden: true,
    });

    this.activeChall('');
  }

  toggleStream = () => {
    this.setState({
      chatPanel: true,
      hideStream: !this.state.hideStream,
    }, () => {
    });
  }

  viewChallenges = () => {
    this.setState({
      hidden: false,
    });
  }

  hideChallenges = () => {
    this.setState({
      hidden: true,
    });
  }

  handleChallengeClose = (event) => {
    this.setState({
      chatPanel: !this.state.chatPanel,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
    });
    this.activeChall('')
  }

  backToTop = (e) => {
    window.scrollTo(0, 0);
  };

  viewChallenge = async (challenge) => {
    if (viewing)
      return;
    viewing = true;
    setTimeout(() => {
      viewing = false;
    }, 1000);
    await this.setState({
      moveInLeft: false,
      challengeId: challenge.challengeId || challenge.objectID,
      clickedChallenge: challenge,
      challengePanelOpen: true,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      chatPanel: false,
      hidden: true
    });
  }

  searchChallenge = () => {
    this.setState({
      moveInLeft: false,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: true,
      challengePanelOpen: false,
      chatPanel: false,
      hidden: true
    });
    this.activeChall('')
  }

  createChallenge = () => {
    this.setState({
      moveInLeft: false,
      challengeCreatePanelOpen: true,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
      chatPanel: false,
      hidden: true
    });
    this.activeChall('')
  }

  activateChall = async (id) => {
    if (gettingChall)
      return;
    gettingChall = true;
    if (id !== this.state.activeChallId) {
      this.setState({
        activeChallId: id
      });
    }
    setTimeout(() => {
      gettingChall = false;
    }, 400);
  }

  activeChall = async (id, action) => {
    if (gettingChall)
      return;
    gettingChall = true;
    if (id !== this.state.activeChallId) {
      this.setState({
        activeChallId: id
      });
    }
    if (action) {
      this.setState({
        action: action
      });
    }
    setTimeout(() => {
      gettingChall = false;
    }, 400);
  }

  handleCheck = (e) => {
    if (e && e.target)
      this.setState({ selectedValue: e.target.value });
  };

  handleFilterCheck = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  /**
   * Handles the submission of the donation form.
   * This method performs validation checks on the form inputs and processes the donation 
   * by either boosting an existing challenge or creating a new challenge based on the user's input.
   * It also handles the state updates related to form processing, including disabling the submit button,
   * setting error messages, and re-enabling the button upon completion or error.
   * 
   * @async
   * @param {Object} event - The event object from the form submission.
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    // Initialize the errors object
    let errors = {};
    let formIsValid = true;

    // Disable the button initially as the form is being processed
    this.setState({ disabled: true });
    try {
      // Validate username
      if (!this.state.username) {
        errors.username = 'Please enter your username';
        formIsValid = false;
      }

      // Validate pay amount
      if (!this.state.payAmount) {
        errors.payAmount = 'Please enter the amount you want to donate';
        formIsValid = false;
      } else if (this.state.payAmount > 999999) {
        errors.payAmount = 'Please enter an amount less than $1,000,000';
        formIsValid = false;
      }

      // Validate challenge
      if (this.state.sliderChall > 0 && !this.state.newChallenge && !this.state.useExisting) {
        errors.newChallenge = 'Please enter or select a challenge, or set the conditional amount to 0%.';
        formIsValid = false;
      }

      // Validate existing challenge selection
      if (this.state.useExisting && !this.state.activeChallId) {
        errors.selectedChallenge = 'Please select a challenge, or un-check the Use Existing Challenge option.';
        formIsValid = false;
      }

      // Update the state with errors if any validations failed
      this.setState({ errors });

      // If the form is not valid, re-enable the button and stop here
      if (!formIsValid) {
        this.setState({ disabled: false });
        return;
      }

      let newDonation = {
        challengeName: this.state.newChallenge,
        // description: this.state.body, 
        handle: this.props.user.credentials.handle,
        givenUsername: this.state.username,
        donationMessage: this.state.message,
        influencer: this.state.handle, //todo:   change to userid
        influencerId: this.state.influencerId,
        // TODO: Can't pass empty values to this as that results in a stripe error.
        // Switch to influencer image and add Meed backup logo for placeholder from backend
        influencerImage: (this.state.profile && this.state.profile.imageUrl) || 'https://firebasestorage.googleapis.com/v0/b/covenant-28c21.appspot.com/o/meed_logo_no_text.png?alt=media&token=41fcd452-a06a-4db9-841c-cc271ea08b18',
        // influencerImage: (this.state.profile && this.state.profile.twitch && this.state.profile.twitch.profile_image_url) || '',
        completeByDate: this.state.completeByDate,
        fromDonation: true,
        description: '',
        // endDate: this.state.endDate,
        endDate: 'xxxxlarge',
        goal: 'No',
        donationAmount: this.state.payAmount - this.state.sliderChall,
        initialCreatorPaymentAmount: this.state.sliderChall, //challenge amount
        payAmount: this.state.payAmount,
        useToken: false,
        boostedChallengeId: this.state.activeChallId ? this.state.activeChallId : '',
      };

      if (newDonation.initialCreatorPaymentAmount > 0) {
        // if the conditional amount is more than 0 then it must be attached to a challenge
        // TODO: create new BE field 'donationAmount' and on challenge completion cron job, add it to the money of the host user. 
        // TODO create donation noti (voting User Doc in BE should take care of noti)
        // Check if a new challenge needs to be created or an existing one used
        if (!this.state.newChallenge && !this.state.useExisting) {
          // If no new challenge name is entered and existing challenge is not chosen, show an error
          this.setState({
            disabled: true,
            errors: { ...this.state.errors, newChallenge: 'Please enter or select a challenge. Or set the conditional amount to 0%.' }
          });
          return;
        }

        if (this.state.useExisting) {
          // If using an existing challenge, check if a challenge is selected
          if (!this.state.activeChallId) {
            // If no challenge is selected, show an error
            this.setState({
              disabled: true,
              errors: { ...this.state.errors, selectedChallenge: 'Please select a challenge. Or un-check the Use Existing Challenge option.' }
            });
            return;
          }
          // Call donation. Old flow had multiple pieces of logic.
          await this.callPostDonation(newDonation);
        } else {
          // If a new challenge is being created
          await this.callPostDonation(newDonation);

        }
        // Log the donation challenge event for analytics
        logEvent(analytics, 'donate_challenge', {
          currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
        });
        // Re-enable the submit button and clear any errors after successful operation
        this.setState({ disabled: false, errors: {} });
      } else {
        // If there's no conditional amount, proceed directly to posting the donation
        await this.callPostDonation(newDonation);
        // Re-enable the submit button and clear any errors after successful donation
        this.setState({ disabled: false, errors: {} });
      }
    } catch (error) {
      // Handle errors in boosting the challenge, processing the donation, or validation
      console.error('Error:', error);
      this.setState({ disabled: false, errors: { ...this.state.errors, submission: 'Error processing your request. Please try again.' } });
    } finally {
      // Add a delay here to prevent immediate re-submission
      setTimeout(() => {
        this.setState({ disabled: false });
      }, 5000);
    }
  };

  async callPostDonation(newDonation) {
    try {
      const { sessionId } = await this.props.donateMoney(newDonation);
      console.log('session id: ', sessionId);

      const { error } = await this.state.stripe.redirectToCheckout({ sessionId });

      logEvent(analytics, 'donate', {
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });

      if (error) {
        console.log(error);
        toast.error('Failed: Try again later.');
      } else {
        // TODO: create another endpoint to complete MP flow and award to user account or webhook
        toast.success('SUCCESS: Donation Complete!');
      }
    } catch (err) {
      console.log('bruh: ', err);
      toast.error('Donation not submitted');
      this.setState({ disabled: false });
    } finally {
      // This will execute regardless of try/catch result
      setTimeout(() => {
        this.setState({ disabled: false });
      }, 5000);
    }
  }

  componentDidUpdate(prev) {
    // Check if the user's display name has changed. (Hacky?) Solution to getting values to display?
    if (this.props.user && this.props.user.credentials && this.props.user.credentials.displayName !== prev.user.credentials.displayName) {
      this.setState({ username: this.props.user.credentials.displayName });
    }

    if (this.state.handle && (this.props.match.params.handle !== prev.match.params.handle)) {
      this.getData();
      this.setState({
        clickedChallenge: {},
        challengePanelOpen: false,
        hideStream: false
      })
    }
  }
  async componentDidMount() {

    // Scroll to the top of the window each time you visit someone's page.
    window.scrollTo(0, 0);
    this.setState({
      randomChallenge: getRandomChallenge(), // Set the random challenge when the component mounts
    });

    try {
      // Check if the user is logged in and has a displayName
      if (this.props.user && this.props.user.credentials && this.props.user.credentials.displayName) {
        this.setState({ username: this.props.user.credentials.displayName });
      }
      // Fetch configuration data for the component.
      const { publicKey, unitAmount, currency } = await this.props.fetchConfig();
      // If the page loads with a challenge ID in the URL parameters, get that challenge from the server.
      if (this.props.match.params?.challengeId) {
        // Using await directly with async function instead of .then for consistency.
        await this.props.getChallenge(this.props.match.params.challengeId);
        await this.getData();
      } else {
        // If no challenge ID is present, just call getData.
        await this.getData();
      }

      // Load the Stripe instance with the fetched public key.
      const stripe = await loadStripe(publicKey);

      // Update the state with the fetched data and loaded Stripe instance.
      this.setState({
        unitAmount,
        currency,
        stripe
      });
    } catch (error) {
      // Log the error to the console and handle any cleanup or user notifications as necessary.
      console.error('Error during component initialization:', error);
      // You can handle the error by setting some state here if needed,
      // like an error message or a flag to indicate loading failed.
    }
  }

  componentWillUnmount() {
    if (userInterval) {
      clearInterval(userInterval);
      userInterval = null;
    }
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  /**
   * Checks if the current donation or challenge amount meets the criteria to trigger Text-to-Speech (TTS) alerts.
   *
   * The method compares the donation amount (`payAmount` minus `sliderChall`) and the challenge amount (`sliderChall`)
   * against the minimum amount thresholds needed to activate TTS alerts (`minBoostAmount` for donations and `minCreateAmount` for challenges).
   * It also checks the `boostAlertsActive` and `createAlertsActive` flags to determine if TTS alerts are enabled for the user.
   *
   * @returns {boolean} Returns true if the TTS alerts will be triggered based on the current amounts and user settings; otherwise, false.
   */
  willTriggerTTS = () => {
    const donationAmount = this.state.payAmount - this.state.sliderChall;
    const challengeAmount = this.state.sliderChall;
    const { minCreateAmount, minBoostAmount } = this.state.profile; // Assuming these are set from the fetched user data

    // Logic to determine if TTS should be triggered
    // This assumes 'boostAlertsActive' or 'createAlertsActive' should be true
    // and the donation or challenge amount should meet the minimum requirements.
    // Adjust the logic as necessary based on your specific requirements.
    return (this.state.profile.boostAlertsActive && donationAmount >= minBoostAmount) ||
      (this.state.profile.createAlertsActive && challengeAmount >= minCreateAmount);
  };

  render() {
    const { errors, selectedValue, challengesQueue } = this.state;
    const { loading } = this.props.data,
      { challengeIdParam, influencerId } = this.state,
      {
        classes,
        user,
      } = this.props,

      challengesMarkupAccepted = loading ? (
        <div></div>
      ) : challengesQueue === null ? (
        <div></div>
      ) : !challengeIdParam && challengesQueue && challengesQueue.length ? (
        challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return sortChallenges(a, b, selectedValue);
          })
          .map((currentChallenge) =>
            <div className={`${viewing ? classes.viewing : ''} ${classes.challWrap}`} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
              <Challenge
                challengeId={currentChallenge.challengeId}
                spacing={'none'}
                donate={'true'}
                key={currentChallenge.challengeId}
                challenge={currentChallenge}
                activeChall={this.activeChall}
                clickedChallId={this.state.activeChallId}
              />
            </div>
          )
      ) : challengesQueue && challengesQueue.length ? (
        challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return sortChallenges(a, b, selectedValue);
          }).map((challenge) => {
            if (challenge.challengeId !== challengeIdParam)
              return <Challenge spacing="none" donate={'true'} key={challenge.challengeId} challenge={challenge} />;
            else return <Challenge spacing="none" donate={'true'} key={challenge.challengeId} challenge={challenge} openDialog />;
          })
      )
        : (<div></div>);

    const challengesMarkupCreated = loading ? (
      <div></div>
    ) : challengesQueue === null ? (
      <div></div>
    ) : !challengeIdParam && challengesQueue && challengesQueue.length ? (
      challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return sortChallenges(a, b, selectedValue);
        })
        .map((currentChallenge) =>
          <div className={classes.challWrap} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
            <Challenge
              queue={true}
              challengeId={currentChallenge.challengeId}
              spacing="none" donate={'true'}
              key={currentChallenge.challengeId}
              challenge={currentChallenge}
              activeChall={this.activeChall}
              clickedChallId={this.state.activeChallId}
            />
          </div>
        )
    ) : challengesQueue && challengesQueue.length ? (
      challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return sortChallenges(a, b, selectedValue);
        }).map((challenge) => {
          if (challenge.challengeId !== challengeIdParam)
            return <Challenge spacing="none" donate={'true'} key={challenge.challengeId} challenge={challenge} />;
          else return <Challenge spacing="none" donate={'true'} key={challenge.challengeId} challenge={challenge} openDialog />;
        })
    )
      : (<div></div>);

    // Check if the current user is banned by the influencer
    const isBanned = user.bannedBy && user.bannedBy.some(banEntry => banEntry.influencerId === influencerId);

    return (
      <div>
        <Grid container className={classes.form}>
          <Grid className={`${classes.gang} ${classes.gang2}`} item sm={6} xs={12}>
            <Typography
              variant="h4"
              className={classes.pageTitle2}
            >
              Donation
            </Typography>
            <br></br>
            <div className={classes.userTopElem}>
              <form noValidate className={classes.cpiece} onSubmit={this.handleSubmit}>
                <div>
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Username
                  </Typography>
                  <TextField
                    id="username"
                    name="username"
                    type="username"
                    label="Username"
                    variant="outlined"
                    inputProps={{
                      maxLength: 30,
                    }}
                    className={classes.donateInput}
                    helperText={errors.username}
                    error={errors.username ? true : false}
                    value={this.state.username || ''}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Amount
                  </Typography>
                  <div className={classes.donateInputRow}>
                    <Input
                      disableUnderline={true}
                      variant='outlined'
                      className={classes.donateInputNumber}
                      placeholder="5"
                      name='payAmount'
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      value={this.state.payAmount}
                      onChange={this.handleChange}
                      error={errors.body ? true : false}
                      inputProps={{ min: 1, max: 999999 }}
                      type="number"
                      fullWidth
                    />
                    <div className={classes.buttonRow}>
                      <Button
                        variant="contained"
                        onClick={() => { this.setState({ payAmount: 1, sliderChall: 0, button1: true, button2: false, button3: false, button4: false }) }}
                        className={`${classes.buttonThird} ${this.state.button1 ? classes.buttonActive : ''}`}
                        disabled={this.state.submitted || this.state.loading}
                      >
                        $1
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => { this.setState({ payAmount: 3, sliderChall: 0, button2: true, button1: false, button3: false, button4: false }) }}
                        className={`${classes.buttonThird} ${this.state.button2 ? classes.buttonActive : ''}`}
                        disabled={this.state.submitted || this.state.loading}
                      >
                        $3
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => { this.setState({ payAmount: 5, sliderChall: 0, button3: true, button1: false, button2: false, button4: false }) }}
                        className={`${classes.buttonThird} ${this.state.button3 ? classes.buttonActive : ''}`}
                        disabled={this.state.submitted || this.state.loading}
                      >
                        $5
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => { this.setState({ payAmount: 10, sliderChall: 0, button4: true, button1: false, button2: false, button3: false }) }}
                        className={`${classes.buttonThird} ${this.state.button4 ? classes.buttonActive : ''}`}
                        disabled={this.state.submitted || this.state.loading}
                      >
                        $10
                      </Button>
                    </div>
                  </div>
                  <Typography id="label">${(this.state.payAmount - this.state.sliderChall).toFixed(2)} as a donation and ${(this.state.sliderChall).toFixed(2)} for a challenge chosen below</Typography>
                  {/* Check and message for TTS trigger */}
                  <Typography style={{ color: this.willTriggerTTS() ? "green" : "red" }}>
                    {this.willTriggerTTS() ? "This will trigger TTS alerts if the user has them enabled" : "This will not trigger TTS alerts"}
                  </Typography>
                  <div className={classes.sliderWrap}>
                    <span>0%</span>
                    <Slider
                      classes={{ container: classes.slider }}
                      value={this.state.sliderChall}
                      aria-labelledby="label"
                      defaultValue={0}
                      max={this.state.payAmount}
                      name='sliderChall'
                      onChange={this.handleChange}
                      sx={{
                        color: '#fff',
                      }}
                    />
                    <span>100%</span>
                  </div>
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Challenge
                  </Typography>
                  <TextField
                    id="newChallenge"
                    name="newChallenge"
                    type="newChallenge"
                    placeholder={
                      isBanned ? 
                      "You are banned from making challenges for this user" : // Message for banned users
                      (!this.props.user.authenticated ? "Log in to submit a new challenge" : `Enter your challenge. Example: ${this.state.randomChallenge}`) // Default placeholder text
                    }
                    variant="outlined"
                    multiline
                    rows="4"
                    disabled={!this.props.user.authenticated || isBanned || this.state.useExisting} // Disable the field if the user is not authenticated, is banned, or has chosen to use an existing challenge
                    inputProps={{
                      maxLength: 1000,
                    }}
                    className={classes.donateInput}
                    error={errors.newChallenge ? true : false}
                    value={this.state.newChallenge}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <div className={classes.toggleChallenges}>
                    <Switch
                      className={classes.toggleSwitch}
                      name="useExisting"
                      helperText={errors.selectedChallenge}
                      error={errors.selectedChallenge ? true : false}
                      onChange={this.handleChange}
                    />
                    <span className={classes.pageTitle2}>Use existing challenge</span>
                  </div>
                  <div className={`${classes.challengeQueue} ${!this.state.useExisting ? classes.hide : ''}`}>
                    `
                    {challengesMarkupAccepted}
                    {challengesMarkupCreated}

                  </div>
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Donation Message
                  </Typography>
                  <TextField
                    id="message"
                    name="message"
                    type="message"
                    label="Donation Message"
                    variant="outlined"
                    multiline
                    rows="4"
                    inputProps={{
                      maxLength: 1000,
                    }}
                    className={classes.donateInput}
                    helperText={errors.message}
                    error={errors.message ? true : false}
                    value={this.state.message}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.buttonSecondary}
                    disabled={this.state.submitted || this.state.loading || (!this.state.username && !this.props.user.credentials.handle) || !this.state.payAmount || parseInt(this.state.payAmount) <= 0}
                  >
                    Donate
                    {this.state.loading && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

donate.propTypes = {
  getUserQueueData: PropTypes.func.isRequired,
  donateMoney: PropTypes.func.isRequired,
  getChallenge: PropTypes.func.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  getChallengeById: PropTypes.func.isRequired,
  fulfillMeedPoints: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { getUserProfileData, getUserQueueData, fulfillMeedPoints, fetchConfig, donateMoney, getChallenge, getChallengeById }
)(withStyles(donateStyles)(donate));
