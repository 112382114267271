const NavbarStyles = {
    appBar: {
        background: '#fff',
        boxShadow: '0 0 3px black',
        color: '#5B5A57'
    },
    leftNav: {
        flex: '1'
    },
    rightNav: {
        color: '#5B5A57'
    },
    meedLink: {
        fontWeight: '400',
        fontSize: '14px',
        color: '#5B5A57',
        margin: '5px 30px',
        textTransform: 'uppercase',
        '&:hover': {
            zIndex: 1,
            opacity: .9,
            transition: 'all .4s',
        },
        '@media (max-width: 960px)': {
            margin: 0,
            position: 'absolute',
            display: 'inline-block',
            left: '50%',
            transform: 'translateX(-50%)'
        },
    },
    homeLink: {
        fontWeight: '400',
        fontSize: '1em',
        color: '#5B5A57',
        margin: '5px 0px',
        textTransform: 'uppercase',
        '& p': {
            fontSize: '1em',
        },
        '&:hover': {
            color: '#B464FA',
        },
        '@media (max-width: 960px)': {
            display: 'none'
        },
    },
    mobileLink: {
        fontSize: '1em !important',
        fontWeight: '400',
        color: '#fff',
        display: 'none',
        '& p': {
            fontSize: '1em !important',
        },
        '@media (max-width: 960px)': {
            display: 'block'
        },
    },
    signUpButton: {
        marginRight: '20px',
        fontWeight: '100',
        '@media (max-width: 960px)': {
            marginRight: '0px',
        },
    },
    createButton: {
        borderRadius: '20px',
        fontWeight: '100'
    },
    loginButton: {
        fontWeight: '100'
    },
    contactButton: {
        fontWeight: '100',
        '@media (max-width: 960px)': {
            display: 'none',
        },
    },
    buttonGroup: {
        fontWeight: '100',
        marginLeft: 'auto'
    },
    profileImage: {
        width: '36px',
        height: '36px',
        borderRadius: '100%',
        objectFit: 'contain',
        cursor: 'pointer',
        marginLeft: 30,
        '@media (max-width: 960px)': {
            marginLeft: 0
        },
    },
    purpleCircle: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#54148B',
        color: '#5B5A57',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        marginLeft: 30,
        '@media (max-width: 960px)': {
            marginLeft: 0
        },
    },
    coins: {
        margin: '5px 0px',
        color: '#5B5A57',
        '&:hover': {
            color: '#B464FA',
        },
        '@media (max-width: 960px)': {
            color: '#FFDF57',
            margin: 0
        },
    },
    searchInput: {
        cursor: 'pointer',
        display: 'inline-block',
        height: 30,
        width: 30,
        opacity: .9,
        '&:hover': {
            zIndex: 1,
            opacity: 1,
            transform: 'scale(1.1)',
            transition: 'all .4s',
        },
    },
    menuWrap2: {
        top: 50,
        borderRadius: 0,
        color: '#fff',
        padding: '0 !important',
        '& div': {
            borderRadius: 0
        },
        "&ul": {
            padding: 0,
        },
    },
    menuItem2: {
        borderRadius: 0,
        background: '#5B5A57',
        color: '#fff',
        "&:hover": {
            background: "#B464FA"
        },
        "&:focus": {
            background: "#B464FA"
        },
    },
    menuWrap: {
        top: 50,
        borderRadius: 0,
        color: '#fff',
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 50px)', // Adjust based on your navbar's height
        '& div': {
            borderRadius: 0
        },
        "& ul": {
            padding: 0,
        },
    },
    notificationsContainer: {
        overflowY: 'auto',
        flex: 1, // Allows this container to grow and scroll within the flex parent
    },
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        zIndex: 2,
        backgroundColor: '#fff', // Or any color that matches your design
        borderTop: '1px solid rgba(0, 0, 0, 0.12)', // Material-UI style for a divider
    },
    markAllReadButton: {
        justifyContent: 'center', // Centers the button text if using a flex container
        padding: '8px 16px', // Standard Material-UI padding for MenuItems
        fontWeight: 'bold', // Optional: if you want the text to be bold
        fontSize: '0.875rem', // Standard Material-UI font size for MenuItems
        lineHeight: '1.5em', // Standard Material-UI line height for MenuItems
        color: '#5B5A57', // Optional: if you want a specific color for the text
        background: '#e0e0e0', // A light grey background to stand out, adjust as needed
        '&:hover': {
            background: '#bdbdbd', // Darken the button on hover, adjust as needed
        },
        // If you're using a flex container for the button, this centers the content
        display: 'flex',
        alignItems: 'center',
    },
    menuItem: {
        borderRadius: 0,
        background: '#5B5A57',
        position: 'relative',
        padding: '0px 16px',
        height: 'auto',
        color: '#fff',
        maxWidth: 350,
        wordBreak: 'normal',
        whiteSpace: 'normal',
        borderBottom: 'solid 1px #fff',
        "&:hover": {
            background: "#6E55D9"
        },
        "&:focus": {
            background: "#6E55D9"
        },
    },
    notificationMenuItem: {
        borderRadius: '4px', // Slightly rounded corners as per the design
        backgroundColor: 'white', // White background for each item
        position: 'relative',
        height: 'auto',
        padding: '20px 20px', // More vertical padding, comfortable horizontal padding
        borderBottom: '1px solid #E0E0E0', // Light gray separator between items
        paddingRight: '8px', // Adjust right padding to ensure spacing for time
        color: '#333', // Dark gray color for text
        fontWeight: 'normal', // Normal font weight for the message text
        maxWidth: 350, // Maximum width to bound the menu items
        wordBreak: 'normal',
        whiteSpace: 'normal',
        "&:hover": {
            background: "#F8F8F8" // Very subtle color change on hover
        },
        "&:focus": {
            background: "#F8F8F8" // Consistent with hover state
        },
    },
    notificationUnread: {
        backgroundColor: '#edf2fa', // Light blue background for unread notifications
        fontWeight: 'bold', // Make the text bold for unread notifications
        // Any other styles you want to apply to unread notifications
    },
    notificationContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%', // Ensure the div takes up all the available space
        paddingRight: '16px', // Add padding to prevent the time from being too close to the edge
    },
    notificationMessage: {
        margin: 0,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3, // Limit to three lines
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis', // Add ellipsis if the text is too long
        marginBottom: '4px', // Add some margin between message and time
    },
    notificationTime: {
        fontSize: '0.75rem',
        color: '#5E2A84',
        alignSelf: 'flex-end', // Align time to the end of the container
    },
    whiteText: {
        fontSize: '1em',
        color: '#fff'
    },
    dropdownStyle:
    {
        borderRadius: 0,
    },
    onlineToggle: {
        padding: '11px 16px',
        background: '#5B5A57',
        outline: 'none',
        '& span': {
            background: '#E1E0DE',
            padding: '5px 16px',
            fontSize: 16,
            fontWeight: 700,
            color: '#5B5A57',
            border: 'solid .5px #707070',
            cursor: 'pointer',
            textTransform: 'uppercase'
        }
    },
    online: {
        background: '#FFDF57 !important'
    },
    offline: {

    },
    logout: {
        textAlign: 'left',
        marginTop: '10px',
        background: '#F1B6D4',
        color: '#fff'
    },
    vertSep: {
        marginLeft: '30px',
        marginRight: '30px',
        '@media (max-width: 960px)': {
            display: 'none'
        },
    },
    logo: {
        maxWidth: 133,
        '@media (max-width: 960px)': {
            maxWidth: 100,
        },
    },
    fullLine: {
        height: 15,
        width: '70%',
        backgroundColor: 'rgba(0,0,0, 0.6)',
        '@media (max-width: 960px)': {
            display: 'none'
        },
    },
    halfLine: {
        height: 15,
        width: 60,
        backgroundColor: 'rgba(0,0,0, 0.6)',
        '@media (max-width: 960px)': {
            display: 'none'
        },
    },
    progress: {
        position: 'absolute',
        left: 'calc(50% - 20px)',
        top: '50%',
        transform: 'translate(50%, -50%)',
        transition: 'none',
        animation: 'none'
    },
}

export default NavbarStyles;