import { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { clearToastMessage } from '../../redux/actions/userActions';

/**
 * ToastManager is a Redux-connected component that listens for toast messages in the application state.
 * It displays toast notifications based on the received messages.
 * 
 * This component supports different types of toast notifications, including regular and error messages.
 * Error messages are displayed with a distinct style (red background) to differentiate them from regular messages.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.toastMessage - An object containing the type and message of the toast. 
 *                                      The 'type' can be 'error' or other types for different styling.
 * @param {Function} props.clearToast - A function to clear the toast message from the state.
 */
const ToastManager = ({ toastMessage, clearToast }) => {
    useEffect(() => {
        if (toastMessage) {
            const { type, message } = toastMessage;

            // Display an error toast if the type is 'error', otherwise display a regular toast
            if (type === 'error') {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    onClose: clearToast
                });
            } else {
                toast(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    onClose: clearToast
                });
            }
        }
    }, [toastMessage, clearToast]);

    return null;
};

const mapStateToProps = state => ({
    toastMessage: state.UI.toastMessage
});

const mapDispatchToProps = dispatch => ({
    clearToast: () => dispatch(clearToastMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastManager);