import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import serviceStyles from './styles/serviceStyles';

class Service extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  render() {
    return (
      <div className="container">
        <p>
          To view our policy, please visit the following link: 
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=3ee00fff-6d82-431c-b1f9-0a642d5c2c10" target="_blank" rel="noopener noreferrer">
            View Policy
          </a>
        </p>
      </div>
    );
  }
}

Service.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(serviceStyles)(Service));
