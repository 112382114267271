import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
// Redux stuff
import { connect } from 'react-redux';
import { signupUser, authenticateViaTwitter, authenticateViaGoogle, authenticateViaFacebook } from '../redux/actions/userActions';
import signupStyles from './styles/signupStyles';
import Filter from 'bad-words';

const filter = new Filter();

/**
   * Performs a custom validation on a given string. The method slices the input string into all possible
   * substrings, then applies a filter (presumably for checking profanity or inappropriate content) on each substring.
   * The method determines if any substring fails the filter's clean check.
   * 
   * @param {string} str - The string to be validated.
   * @returns {boolean} Returns true if any of the substrings fail the filter's clean check, 
   *                    indicating the presence of potentially inappropriate content.
   *                    Otherwise, returns false, indicating the string is considered 'clean'.
   */
const clean = (str) => {
  var i, j, result = [];
  for (i = 0; i < str.length; i++) {
    for (j = i + 1; j < str.length + 1; j++) {
      result.push(str.slice(i, j));
    }
  }
  for (let x = 0; x < result.length + 1; x++) {
    let now = result[x];
    if (now && now.length > 2 && now !== filter.clean(now)) {
      return true;
    }
  };
  return false;
}

/**
 * Validates the input field, specifically focusing on the 'handle' input. It performs two main checks:
 * 1. Verifies if the 'handle' input value is not only alphanumeric but also passes the custom clean check
 *    provided by the `cleanFunc` function. The clean check involves validating the input string against
 *    specific criteria defined in `cleanFunc`, such as checking for profanity or inappropriate content.
 * 2. Ensures that the 'handle' input value consists solely of alphanumeric characters.
 *
 * @param {Object} target - The target input element from the event, containing `name` and `value` properties.
 * @param {Function} cleanFunc - A function that takes a string and returns a boolean indicating whether the
 *                               string passes the custom clean check. It should return `true` if the string
 *                               fails the check (i.e., is considered "dirty" or inappropriate).
 * @returns {string|null} Returns a string with an error message if validation fails, or `null` if the input
 *                        passes all validation checks. Possible error messages are "That is not nice" if the
 *                        input fails the `cleanFunc` check, or "Please only use alpha numeric characters." if
 *                        the input contains non-alphanumeric characters.
 */
const validateInput = (target, cleanFunc) => {
  let regEx = /^[0-9a-zA-Z]+$/;

  if (target.name === 'handle') {
    if (target.value && target.value.length > 1 && cleanFunc(target.value)) {
      return 'That is not nice';
    }

    if (target.value && !target.value.match(regEx)) {
      return 'Please only use alpha numeric characters.';
    }
  }

  return null;
};

class signup extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      handle: '',
      errors: {},
      open: false
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  
  authenticateWithSocialMedia = async (event, authenticationFunction) => {
    event.preventDefault();
    this.setState({ submitted: true });
  
    try {
      await authenticationFunction(this.props.history, this.props.match.params.code || '');
      toast.success('SUCCESS: Logged in! Redirecting...');
      // Redirect logic after successful login
    } catch (err) {
      toast.error('Authentication failed.');
    } finally {
      this.setState({ submitted: false });
    }
  };  
  authenticateViaGoogle = async (event) => {
    await this.authenticateWithSocialMedia(event, this.props.authenticateViaGoogle);
  }; 
  authenticateViaTwitter = async (event) => {
    await this.authenticateWithSocialMedia(event, this.props.authenticateViaTwitter);
  }; 
  authenticateViaFacebook = async (event) => {
    await this.authenticateWithSocialMedia(event, this.props.authenticateViaFacebook);
  };

  
  handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateInput(event.target, clean);
  
    if (validationError) {
      this.setState({ invalid: validationError });
      return;
    } else {
      this.setState({ invalid: '', loading: true });
    }
  
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      handle: this.state.handle
    };
  
    if (newUserData.password !== newUserData.confirmPassword) {
      toast.error('Passwords do not Match', { autoClose: false });
      this.setState({ loading: false });
      return;
    }
  
    try {
      await this.props.signupUser(newUserData, this.props.history, this.props.match.params.code || '');
    } catch (err) {
      // Handle error if needed
    } finally {
      this.setState({ loading: false });
    }
  };
  
  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    const cleanedValue = value.replace(/\s/g, '');
    const regEx = /^[0-9a-zA-Z]+$/;
  
    this.setState({ [name]: cleanedValue });
  
    if (name === 'handle') {
      if (!cleanedValue.match(regEx) && cleanedValue) {
        this.setState({ invalid: 'Please only use alpha numeric characters.' });
      } else {
        this.setState({ invalid: '' });
      }
    }
  };  
  async componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {
      classes,
    } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Grid container className={classes.form}>
          <Grid className={`${classes.gang} ${classes.gang2}`} item sm={6} xs={12}>
            <div className={classes.formLogin}>
              <Typography 
                variant="h4" 
                className={classes.label}
              >
                Sign Up
              </Typography>
              <form value="Outlined" noValidate className={classes.cpiece} onSubmit={this.handleSubmit}>
                <div>
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Username
                  </Typography>
                  <TextField
                    id="handle"
                    name="handle"
                    type="text"
                    label="Create a Username"
                    variant="outlined"
                    className={classes.loginInput}
                    helperText={this.state.invalid}
                    error={this.state.invalid ? true : false}
                    value={this.state.handle ?? ""}
                    onChange={this.handleChange}
                    fullWidth
                    InputLabelProps = {{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 20,
                      style: { borderRadius: 30 },
                    }}
                  />
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    
                    className={classes.loginInput}
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={this.state.email}
                    onChange={this.handleChange}
                    fullWidth
                    InputLabelProps = {{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 100,
                      style: { borderRadius: 30 },
                    }}
                  />
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    
                    className={classes.loginInput}
                    helperText={errors.password}
                    error={errors.password ? true : false}
                    value={this.state.password ?? ""}
                    onChange={this.handleChange}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 100,
                      style: { borderRadius: 30 },
                      //shrink: "true",
                    }}
                  />
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Confirm Password
                  </Typography>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    className={classes.loginInput}
                    helperText={errors.confirmPassword}
                    error={errors.confirmPassword ? true : false}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                      style: { borderRadius: 30 },
                    }}
                  />
                  {errors.general && (
                    <Typography variant="body2" className={classes.customError}>
                      {errors.general}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonSecondary}
                    disabled={this.state.loading || this.state.invalid ? true : false}
                  >
                    Sign Up
                    {this.state.loading && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                </div>
                <br />
                <span className={`${classes.extraAction} ${classes.bold}`}>
                  Already have an account ? Login <Link to="/login" className={classes.purple}>here</Link>.
                </span>
                <br />
                <br />
                <div>
                  <div className={classes.horizLine}></div>
                  <small className={classes.extraAction2}>
                    Or Sign up with
                  </small>
                  <div className={classes.horizLine}></div>
                </div>
                <div className={classes.socials}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.authenticateViaGoogle}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-google"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                  {/*<Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.authenticateViaTwitter}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-twitter"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                    </Button>*/}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.authenticateViaFacebook}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-facebook-f"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                </div>
              </form>
            </div>
            
          </Grid>
          <Grid className={`${classes.gang}`} item sm={6} xs={12}>
            <div className={classes.bgImgTitle}>
              <Typography
                variant="h1"
                className={classes.pageTitle}
              >
                Challenge someone or get ready to be Challenged!
              </Typography>
              <div className={classes.downArrow}>
                <i className={"fa-solid fa-arrow-down"}></i>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

signup.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired,
  authenticateViaGoogle: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  authenticateViaFacebook: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  signupUser,
  authenticateViaGoogle,
  authenticateViaTwitter,
  authenticateViaFacebook
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withStyles(signupStyles)(signup));