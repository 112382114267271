import React, { Component } from 'react';
import MyButton from '../../util/MyButton';
import PropTypes from 'prop-types';
// Icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// Redux
import { connect } from 'react-redux';
import { castPassVote, castFailVote, removeVote } from '../../redux/actions/dataActions';

export class VoteButton extends Component {
  userVote = () => {
    // Check how it's stored handlers/user.js/(getUserQueueAuth and getUserDatailsAuth)
    // Set to null in the challenge object if no vote
    if (this.props.challenge.userVote) {
      return {
        vote: this.props.challenge.userVote,
        qualifiedVote: this.props.challenge.voteQualified
      };
    }
    else {
      return null;
    }
  };

  castPassVote = () => {
    this.props.castPassVote(this.props.challengeId);
  };

  castFailVote = () => {
    this.props.castFailVote(this.props.challengeId);
  };

  removeVote = () => {
    this.props.removeVote(this.props.challengeId);
  };

  render() {
    const { user, challenge } = this.props;
    console.log('votebutton log: ', this.props);
    // Check if the required props are available
    if (!user || !challenge) {
      console.error("User or Challenge prop is not available");
      return null; // Or you could return a fallback UI here
    }
    const { authenticated } = this.props.user; // Get authentication status
    const userVoteData = this.userVote(); // Call userVote function
    const userVote = userVoteData ? userVoteData.vote : null; // Check if it returned a value, if yes then destructure vote

    return (
      <div>
        <MyButton
          className={this.props.className}
          tip="Pass"
          onClick={authenticated ? (userVote === 'pass' ? this.removeVote : this.castPassVote) : null}
        >
          <ThumbUpIcon
            style={{ color: authenticated ? (userVote === 'pass' ? '#0000FF' : 'gray') : 'gray' }}
          />
          {authenticated && (this.props.challenge.status === 'completed' || this.props.challenge.status === 'failed' || userVote) ?
           // <span>{this.props.challenge.passVotesOfficial} ({this.props.challenge.passVotesAll})</span>
            <div>{this.props.challenge.passVotesOfficial} ({this.props.challenge.passVotesAll})</div> 
            :
            <div></div> 
          }
        </MyButton>
        <MyButton
          className={this.props.className}
          tip="Fail"
          onClick={authenticated ? (userVote === 'fail' ? this.removeVote : this.castFailVote) : null}
        >
          <ThumbDownIcon
            style={{ color: authenticated ? (userVote === 'fail' ? '#F44336' : 'gray') : 'gray' }}
          />
          {authenticated && (this.props.challenge.status === 'completed' || this.props.challenge.status === 'failed' || userVote) ?
            // <span>{this.props.challenge.failVotesOfficial} ({this.props.challenge.failVotesAll})</span>
            <div>{this.props.challenge.failVotesOfficial} ({this.props.challenge.failVotesAll})</div>
            :
            <div></div>
          }
        </MyButton>
      </div>
    );
  }

}

VoteButton.propTypes = {
  user: PropTypes.object.isRequired,
  challenge: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired,
  castPassVote: PropTypes.func.isRequired,
  castFailVote: PropTypes.func.isRequired,
  removeVote: PropTypes.func.isRequired,
  votingType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
});

// The actions that can be triggered by the component?
// We set this up so that we can connect the VoteButton class to the Redux store
const mapActionsToProps = {
  castPassVote,
  castFailVote,
  removeVote,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(VoteButton);