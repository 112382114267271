import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { format } from "date-fns";
import { getAnalytics, logEvent } from "firebase/analytics";
// MUI Stuff
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/layout/TabPanel'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
// Redux stuff
import { connect } from 'react-redux';
import { clearErrors, fetchConfig } from '../redux/actions/dataActions';
import { purchaseMeedPoints, getMeedPointPurchases, getMeedPointPayouts } from '../redux/actions/userActions';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import buyMeedPointsStyles from './styles/buyMeedPointsStyles';

const analytics = getAnalytics();

class buyMeedPoints extends Component {
  state = {
    payAmount: 'medium',
    open: false,
    body: '',
    endDate: '',
    errors: {},
    influencer: '',
    timeout: null,
    loading: false,
    stripe: null,
    tabValue: 0,
    Twitch: window.Twitch,
    checkedAgree: true,
    purchases: {
      transactions: []
    },
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: 'createdAt',
    page2: 0,
    rowsPerPage2: 10,
    order2: 'asc',
    orderBy2: 'createdAt',
  };

  async componentDidMount(){
    window.scrollTo(0, 0);
    // Fetch config from our backend.
    const { publicKey, unitAmount, currency } = await this.props.fetchConfig()
    const purchases = await this.props.getMeedPointPurchases();
    const payouts = await this.props.getMeedPointPayouts();
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    this.setState({
      unitAmount, currency, stripe: await loadStripe(publicKey), 
      payouts: payouts,
      purchases: purchases
    });
    
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {} });
  };
  handleChecked = (event) => {
    this.setState({
      checkedAgree: !this.state.checkedAgree
    })
  }
  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeDate = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangePayAmount = (event) => {
    // let name = event.target.value;
  }
  handleChipClick = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {} });
  };
  handleChipDelete = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    // redirect to stripe session to complete purchase
    try {
      // create stripe checkout session
      const {sessionId} = await this.props.purchaseMeedPoints({ 
        payAmount: this.state.payAmount
      })
      logEvent(analytics, 'load_wallet', {
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });
      const { error } = await this.state.stripe.redirectToCheckout({
        sessionId,
      });
      if (error) {
        console.log(error)
        toast.error('Failed: Funds Not Added. Try again later.');
      } else {
        //todo create another endpoint to complete MP flow and award to user account or webhook
        toast.success('SUCCESS: Funds Added!');
      }
    } catch (err) {
      console.log(err)
    }
  };
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangePage2 = (event, page2) => {
    this.setState({ page2 });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangeRowsPerPage2 = event => {
    this.setState({ rowsPerPage2: event.target.value });
  };

  desc = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  stableSort = (array, cmp)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting = (order, orderBy)=> {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  render() {
    const {
      classes,
      user: {
        authenticated,
        credentials: { handle, payments }
      }
    } = this.props;

    let thisPurchases = this.state.purchases && this.state.purchases.transactions ? this.state.purchases.transactions : [{handle: 'test user', duration: 'permenant', createdAt: '2021-06-23T06:33:24.397Z'}, {handle: 'test user', duration: 'permenant', createdAt: '2021-06-23T06:33:24.397Z'}];
    let thisPayments = this.state.payouts && this.state.payouts.transactions ? this.state.payouts.transactions : [{handle: 'test user', duration: 'permenant', createdAt: '2021-06-23T06:33:24.397Z'}, {handle: 'test user', duration: 'permenant', createdAt: '2021-06-23T06:33:24.397Z'}];
    return (
      <Fragment>
        <div className="container">
          <Grid className={classes.wrapper} container spacing={16}>
            <Grid className={classes.gridSpacingNone} item sm={9} xs={12}>
              <AppBar className={classes.tabHeader} position="static" color="default">
                <Tabs
                  value={this.state.tabValue}
                  onChange={this.handleChangeTab}
                  indicatorColor="secondary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  className={classes.tabHeader}
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Your Wallet" {...this.a11yProps(0)} />
                  <Tab label="Payment History" {...this.a11yProps(1)} />
                  <Tab label="User Activity" {...this.a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={0}>
                <div className={classes.balances}>
                  <span className={classes.bal}>Current <br></br>Balances</span>
                  <div className={classes.balval}>
                    <span className={classes.bal}>${this.props.user.credentials.meedPointsBalance}</span> 
                    <span className={classes.val}></span>
                  </div>
                  <div className={classes.vertLine}></div>
                  <div className={classes.balval}>
                    <span className={classes.val}>Funds to Spend</span>
                  </div>
                </div>
                <hr></hr>
                <div className={classes.balances}>
                  <span className={classes.bal}>Money</span>
                  <div >
                    <span className={classes.bal}>${this.props.user.credentials.meedPointsEarned ? this.props.user.credentials.meedPointsEarned : 0}</span> 
                    <span className={classes.val}>Earned in Current Period</span>
                    <br></br>
                    <span className={classes.bal}>${this.props.user.credentials.meedPointsEarnedTotal ? this.props.user.credentials.meedPointsEarnedTotal : 0}</span>
                    <span className={classes.val}>Earned Total</span>
                  </div>
                </div>
                <hr></hr>
                <div className={classes.balances}>
                  <span className={classes.bal}>Challenges</span>
                  <div >
                    <span className={classes.bal}>{this.props.user.credentials.followerCount ? this.props.user.credentials.followerCount : 0}</span> 
                    <span className={classes.val}>Followers</span>
                    <br></br>
                    <span className={classes.bal}>{this.props.user.credentials.challengeCount ? this.props.user.credentials.challengeCount : 0}</span>
                    <span className={classes.val}>Challenges Completed</span>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={1}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell>Date</TableCell>
                      <TableCell align="center">Product Name</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.stableSort(thisPurchases, this.getSorting(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {payment && payment._fieldsProto && payment._fieldsProto.createdAt.stringValue ? format(new Date(payment._fieldsProto.createdAt.stringValue), "MMMM do, yyyy H:mma") : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          Funds
                        </TableCell>
                        <TableCell align="center">+ ${payment && payment._fieldsProto && payment._fieldsProto.meedPointsAdding ? payment._fieldsProto.meedPointsAdding.integerValue : 100}</TableCell>
                        <TableCell align="center">{payment && payment._fieldsProto && payment._fieldsProto.status.stringValue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={thisPurchases.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TabPanel>
              <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={2}>
                <div>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell>Date</TableCell>
                      <TableCell align="center">Target User</TableCell>
                      <TableCell align="center">Product</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.stableSort(thisPayments, this.getSorting(this.state.order2, this.state.orderBy2))
                    .slice(this.state.page2 * this.state.rowsPerPage2, this.state.page2 * this.state.rowsPerPage2 + this.state.rowsPerPage2)
                    .map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {payment && payment._fieldsProto && payment._fieldsProto.createdAt.stringValue ? format(new Date(payment._fieldsProto.createdAt.stringValue), "MMMM do, yyyy H:mma") : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment._fieldsProto ? payment._fieldsProto.influencerAtName.stringValue : ''}
                        </TableCell>
                        {/* Change this to just be a log of challenge activity. There's no need to do "Tokens" 
                        <TableCell align="center">{payment && payment._fieldsProto && payment._fieldsProto.initialCreatorPaymentAmount.integerValue > 0 ? 'Funds' : 'Tokens'}</TableCell>
                        */}
                        <TableCell align="center">{'Challenge'}</TableCell>
                        <TableCell align="center">${payment && payment._fieldsProto && payment._fieldsProto.initialCreatorPaymentAmount.integerValue > 0 ? payment._fieldsProto.initialCreatorPaymentAmount.integerValue : '0'}</TableCell>
                        <TableCell align="center">{payment && payment._fieldsProto ? payment._fieldsProto.status.stringValue: ''}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={thisPayments.length}
                  rowsPerPage={this.state.rowsPerPage2}
                  page={this.state.page2}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onPageChange={this.handleChangePage2}
                  onRowsPerPageChange={this.handleChangeRowsPerPage2}
                />
              </TabPanel>
            </Grid>
            <Grid className={classes.balInfo} item sm={3} xs={12}>
              <Card className={classes.card}>
                <CardContent className={classes.content}>
                  <Typography variant="h4" className={classes.pageTitle}>
                    Add Funds
                  </Typography>
                  
                  <form onSubmit={this.handleSubmit}>
                    <div className={classes.selectPoints}>
                      <Typography variant="body1" className={classes.pageTitle}>
                      Funds
                      </Typography>
                      <Select
                        variant="standard"
                        className={classes.endDate}
                        id="date"
                        name='payAmount'
                        value={this.state.payAmount}
                        onChange={this.handleChange}
                        disableUnderline={true}>
                        <MenuItem value={'xxxsmall'}>$1</MenuItem>
                        <MenuItem value={'xxsmall'}>$5</MenuItem>
                        <MenuItem value={'xsmall'}>$10</MenuItem>
                        <MenuItem value={'small'}>$25</MenuItem>
                        <MenuItem value={'medium'}>$50</MenuItem>
                        <MenuItem value={'large'}>$100</MenuItem>
                        <MenuItem value={'xlarge'}>$1,000</MenuItem>
                        <MenuItem value={'xxlarge'}>$10,000</MenuItem>
                      </Select> 
                    </div>  
                    <hr></hr>  
                    <Typography
                      variant="body1"
                      className={classes.legalText}>
                    I agree to and understand the terms 
                    &#38; conditions of this purchase.      
                    </Typography>
                    <div className={classes.checkAgree}>
                      <Typography
                        variant="body1"
                        className={classes.legalText}>
                        I understand and agree.            
                      </Typography>
                      <Checkbox
                        checked={this.state.checkedAgree}
                        onChange={this.handleChecked}
                        className={classes.checkBox}
                      />
                    </div>
                    <hr></hr>   
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!this.state.checkedAgree}
                      className={classes.submitButton}
                    >
                      Add Funds
                    </Button>
                  </form>
                </CardContent>
              </Card>
              </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

buyMeedPoints.propTypes = {
  purchaseMeedPoints: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  getMeedPointPurchases: PropTypes.func.isRequired,
  getMeedPointPayouts: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  user: state.user,
  stripeSession: state.stripeSession
});

export default connect(
  mapStateToProps,
  { purchaseMeedPoints, clearErrors, fetchConfig, getMeedPointPurchases, getMeedPointPayouts }
)(withStyles(buyMeedPointsStyles)(buyMeedPoints));
