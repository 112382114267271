import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
// Redux stuff
import { connect } from 'react-redux';
import { contactUs } from '../redux/actions/userActions';
import { toast } from 'react-toastify';
import gotSkittles from '../images/gotSkittles.png';

import contactStyles from './styles/contactStyles';

class contact extends Component {
  constructor() {
    super();
    this.state = {
      reason: 'question',
      errors: {},
      submitted: false,
      auth: {},
      email: '',
      firstName: '',
      lastName: '',
      message: ''
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
  
    // Input validation
    if (!this.state.email) {
      toast.error('Failed: Please enter your email.');
      return;
    }
    if (!this.state.message) {
      toast.error('Failed: Please enter a message.');
      return;
    }
  
    // Prepare user data
    const userData = {
      reason: this.state.reason,
      message: this.state.message,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    };
  
    // Set state to submitted
    this.setState({ submitted: true });
  
    try {
      const res = await this.props.contactUs(userData);
      if (res) {
        toast.success('Contact form submitted.');
      } else {
        // Handle the case where res is not as expected
      }
    } catch (err) {
      // Handle errors from contactUs
      toast.error('Failed to submit contact form.');
    } finally {
      // Reset submitted state regardless of outcome
      this.setState({ submitted: false });
    }
  };  
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  render() {
    const {
      classes,
    } = this.props;
    const { errors } = this.state;

    return (
      <div className="container">
        <Grid container className={classes.form}>
          <Grid item md={7} sm={12}>
            <Typography
              variant="h2"
              className={classes.pageTitle}
              /*InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}*/>
              Need to talk to us?
            </Typography>
             <div className={`${classes.subHeader}`} item sm={12} xs={12}>
                Enter your information here and we’ll get back to you!
             </div>
             <br></br>
            <form className={classes.gang} noValidate onSubmit={this.handleSubmit}>
              <div className={`${classes.fieldFormat}`}> 
                <div>
                  
                    <TextField
                      className={`${classes.roundedRadius} ${classes.fieldFormatLeft}` }
                      variant='outlined'
                      width="25ch"
                      id="firstName"
                      name="firstName"
                      type="firstName" //Text
                      label="First Name"
                      margin="normal"
                      fieldset=''
                      inputProps={{
                      maxLength: 100,
                        disableUnderline: true, 
                        style: { borderRadius: 25 },             
                      }}
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      //fullWidth
                    />
            
                    <TextField
                      className={`${classes.roundedRadius} ${classes.fieldFormatRight}` }
                      //style={styles.roundedRadius}
                      variant='outlined'
                      id="lastName"
                      name="lastName"
                      type="lastName" //Text
                      label="Last Name"
                      margin="normal"
                      width="25ch"
                      inputProps={{
                        maxLength: 100,
                        disableUnderline: true,
                        style: { borderRadius: 25 },
                      }}
                      
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      //fullWidth
                    />
                </div>

              <div>
                <TextField
                  className={`${classes.roundedRadius} ${classes.fieldFormatLeft}` }
                  variant='outlined'
                  id="email"
                  name="email"
                  type="email" //Text
                  label="Email"
                  width="25ch"
                  margin="normal"
                  inputProps={{
                    maxLength: 100,
                    disableUnderline: true,
                    style: { borderRadius: 25 },
                  }}
                  value={this.state.email}
                  onChange={this.handleChange}
                  //fullWidth
                />
        
                <TextField
                    select
                    //width='95%'
                    disableUnderline={true}
                    className={`${classes.menuFormat} ${classes.fieldFormatRight}`}
                    id="reason"
                    //style={`${classes.roundedRadius}`}
                    name='reason'
                    value={this.state.reason}
                    variant='outlined'
                    margin="normal"
                    onChange={this.handleChange}
                >
                    <MenuItem value={'feature'}>Feature Request</MenuItem>
                    <MenuItem value={'question'}>Question</MenuItem>
                    <MenuItem value={'other'}>Other</MenuItem>
                </TextField>
  
                <TextField
                    className={`${classes.roundedRadius} ${classes.messageFormat}`}
                    variant='outlined'
              
                    id="message"
                    name="message"
                    type="text"
                    label="Message"
                    margin="normal"
                    multiline
                    rows="4"
                    inputProps={{
                      maxLength: 1000,
                      disableUnderline: true
                    }}
                    value={this.state.message}
                    onChange={this.handleChange}
                    //fullWidth
                  />
          
                  </div>
              </div>
              {errors.general && (
                <Typography color="#fff" variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}

              <Button
                type="submit"
                //variant="contained"
                //color="7B5DF9"
                className={classes.buttonDefault}
                disabled={this.state.submitted}
              >
                Send
                {this.state.submitted && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
          </Grid>
          <Grid item md={5} sm={12}>

            <div className={`${classes.contactImg}`} src={gotSkittles}>
            </div>

          </Grid>
        </Grid>
      </div>
    );
  }
}

contact.propTypes = {
  classes: PropTypes.object.isRequired,
  contactUs: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  contactUs
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(contactStyles)(contact));
