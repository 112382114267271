import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Challenge from '../components/challenge/Challenge';
import ChallengeSkeleton from '../util/ChallengeSkeleton';

import { connect } from 'react-redux';
import { getAcceptingUsers } from '../redux/actions/dataActions';
import { getCompletedChallenges } from '../redux/actions/dataActions';
import { showNav } from '../redux/actions/dataActions';
import { hideNav } from '../redux/actions/dataActions';

import { withStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import mascot from '../images/no-img.png';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, RefinementList } from 'react-instantsearch-dom';
import searchStyles from './styles/searchStyles';

const auth = getAuth();
const algoliaClient = algoliasearch(
  'NGAO27P2B5',
  'cb398cda25d3e47c5d89065f53de82cd'
);
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

class search extends Component {
  state = {
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    searchChall: false,
    searchUser: true,
    searchFollowers: false,
    searchFollowing: false,
    socialName: '',
  }
  searchChall = () => {
    this.setState({
      searchChall: true,
      searchUser: false,
      searchFollowers: false,
      searchFollowing: false
    })
  }
  searchUser = () => {
    this.setState({
      searchChall: false,
      searchUser: true,
      searchFollowers: false,
      searchFollowing: false
    })
  }
  searchFollowers = () => {
    this.setState({
      searchChall: false,
      searchUser: false,
      searchFollowers: true,
      searchFollowing: false
    })
  }
  searchFollowing = () => {
    this.setState({
      searchChall: false,
      searchUser: false,
      searchFollowers: false,
      searchFollowing: true
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const defaultTab = this.props.match.params.default,
      handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({
          socialName: user.displayName
        });
      }
    })
    if (defaultTab && defaultTab === 'following') {
      this.searchFollowing();
    }
  }
  componentWillUnmount() {
    this.props.showNav();
  }
  render() {
    const { loading } = this.props.data;
    const {
      classes,
      user: {
        credentials: { handle },
      }
    } = this.props;

    let Hit = !loading ? (
      (challenge) => <Challenge home='true' key={challenge.hit.challengeId} challenge={challenge.hit} />
    ) : (
      <ChallengeSkeleton />
    );

    let HitUsers = !loading ? ((user) =>
      <Card key={user.hit.handle} className={`${classes.userCard}`} component={Link} to={`/users/${user.hit.handle}`}>
        {
          user.hit.twitch && user.hit.twitch.profile_image_url ?
            <img
              src={user.hit.twitch.profile_image_url}
              alt="profile"
              className={classes.profileImage}
            />
            :
            <div className={classes.yellowCircle}>
              <img
                src={mascot}
                alt="profile"
                className={classes.profileImage}
                onClick={this.menuClick}
              />
            </div>
        }
        <CardContent className={classes.content}>
          <Typography className={classes.hideOverflow} variant="body1">{user.hit.handle}</Typography>
          <Typography
            className={classes.verifiedUser}
            variant="body2"
          >
            Challenges to Date [{`${user.hit.challengeCount ? user.hit.challengeCount : 0}`}]
            {/* <img className={classes.verified} src={logo}/> */}
          </Typography>
          <Typography
            className={classes.verifiedUser}
            variant="body2"
          >
            Followers [{`${user.hit.followerCount ? user.hit.followerCount : 0}`}]
            {/* <img className={classes.verified} src={logo}/> */}
          </Typography>
        </CardContent>
      </Card>
    )
      : (
        <ChallengeSkeleton />
      );

    let HitFollowers = !loading ? ((user) =>
      user.hit.influencerAtName === handle ?
        <Card key={user.hit.userHandle} className={`${classes.userCard}`} component={Link} to={`/users/${user.hit.userHandle}`}>
          {
            user.hit.userImageUrl ?
              <img
                src={user.hit.userImageUrl}
                alt="profile"
                className={classes.profileImage}
              />
              :
              <div className={classes.yellowCircle}>
                <img
                  src={mascot}
                  alt="profile"
                  className={classes.profileImage}
                  onClick={this.menuClick}
                />
              </div>
          }
          <CardContent className={classes.content}>
            <Typography className={classes.hideOverflow} variant="body1">{user.hit.userHandle}</Typography>
            <Typography
              className={classes.verifiedUser}
              variant="body2"
            >
              Challenges to Date [{`${user.hit.challengeCount ? user.hit.challengeCount : 0}`}]
              {/* <img className={classes.verified} src={logo}/> */}
            </Typography>
            <Typography
              className={classes.verifiedUser}
              variant="body2"
            >
              Followers [{`${user.hit.followerCount ? user.hit.followerCount : 0}`}]
              {/* <img className={classes.verified} src={logo}/> */}
            </Typography>
          </CardContent>
        </Card>
        :
        <div></div>
    )
      : (
        <ChallengeSkeleton />
      );

    let HitFollowing = !loading ? ((user) =>
      user.hit.userHandle === handle ?
        <Card key={user.hit.influencerAtName} className={`${classes.userCard}`} component={Link} to={`/users/${user.hit.influencerAtName}`}>
          {
            user.hit.userImageUrl ?
              <img
                src={user.hit.userImageUrl}
                alt="profile"
                className={classes.profileImage}
              />
              :
              <div className={classes.yellowCircle}>
                <img
                  src={mascot}
                  alt="profile"
                  className={classes.profileImage}
                  onClick={this.menuClick}
                />
              </div>
          }
          <CardContent className={classes.content}>
            <Typography className={classes.hideOverflow} variant="body1">{user.hit.influencerAtName}</Typography>
            <Typography
              className={classes.verifiedUser}
              variant="body2"
            >
              Challenges to Date [{`${user.hit.challengeCount ? user.hit.challengeCount : 0}`}]
              {/* <img className={classes.verified} src={logo}/> */}
            </Typography>
            <Typography
              className={classes.verifiedUser}
              variant="body2"
            >
              Followers [{`${user.hit.followerCount ? user.hit.followerCount : 0}`}]
              {/* <img className={classes.verified} src={logo}/> */}
            </Typography>
          </CardContent>
        </Card>
        :
        <div></div>
    )
      : (
        <ChallengeSkeleton />
      );
    return (
      <Fragment>
        <div className={classes.searchHeader}>
          <div className={classes.notMobile}>Search For</div>
          <Button onClick={this.searchUser} className={`${this.state.searchUser ? classes.active : ''}`}>Users</Button>
          {/* This is omitted because we aren't currently using challenges for algolia searching due to cost concerns
           <Button onClick={this.searchChall} className={`${this.state.searchChall ? classes.active : ''}`}>Challenges</Button>
          This is the user-facing code for searching followers and following, which are not implemented atm.  
          <Button onClick={this.searchFollowers} className={`${this.state.searchFollowers ? classes.active : ''}`}>Followers</Button>
          <Button onClick={this.searchFollowing} className={`${this.state.searchFollowing ? classes.active : ''}`}>Following</Button> */}
        </div>
        <div className={classes.challWrap}>
          <Grid className={classes.challs} container spacing={16}>
            <div className={classes.challsBox}>
              {/* Commented out since we're not currently using challenges, followers, and following search functionalities */}
              {/*
      this.state.searchChall ?
        <InstantSearch searchClient={searchClient} indexName="dev_CHALLENGES">
          <SearchBox className={classes.searchInput} />
          <h2 className={classes.homeTitle}>Search Results</h2>
          <hr className={classes.horizLine}></hr>
          <Hits className={classes.results} hitComponent={Hit} />
        </InstantSearch>
        :
      */}
              {this.state.searchUser ?
                <InstantSearch searchClient={searchClient} indexName="dev_USERS">
                  <SearchBox className={classes.searchInput} />
                  <h2 className={classes.homeTitle}>Search Results</h2>
                  <hr className={classes.horizLine}></hr>
                  <Hits className={classes.results} hitComponent={HitUsers} />
                </InstantSearch>
                :
                <div></div>
              }
              {/* Additional search functionalities for followers and following are also commented out
      this.state.searchFollowers ?
        <InstantSearch
          searchClient={algoliaClient}
          indexName="dev_FOLLOWERS"
        >
          <SearchBox className={classes.searchInput} />
          <RefinementList
            attribute='influencerAtName'
            transformItems={items =>
              items.filter(item => item.influencerAtName === handle)
            }
          />
          <h2 className={classes.homeTitle}>Search Results</h2>
          <hr className={classes.horizLine}></hr>
          <Hits className={classes.results} hitComponent={HitFollowers} />
        </InstantSearch>
        :
      this.state.searchFollowing ?
        <InstantSearch
          searchClient={algoliaClient}
          indexName="dev_FOLLOWERS"
        >
          <SearchBox className={classes.searchInput} />
          <RefinementList
            attribute='userHandle'
            transformItems={items =>
              items.filter(item => item.userHandle === handle)
            }
          />
          <h2 className={classes.homeTitle}>Search Results</h2>
          <hr className={classes.horizLine}></hr>
          <Hits className={classes.results} hitComponent={HitFollowing} />
        </InstantSearch>
        :
      */}
            </div>
          </Grid>
        </div>

      </Fragment>
    );
  }
}

search.propTypes = {
  getAcceptingUsers: PropTypes.func.isRequired,
  getCompletedChallenges: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  authenticated: state.user.authenticated,
  data: state.data
});

export default connect(
  mapStateToProps,
  { getCompletedChallenges, getAcceptingUsers, hideNav, showNav }
)(withStyles(searchStyles)(search));
