import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { reportChallenge } from '../../redux/actions/dataActions';
import reportChallengeStyles from './styles/reportChallengeStyles';

// page 6 - 7 https://docs.google.com/document/d/1-hxknVHV4a38-sV3liT32i2VU2qRUewDqXNuhdAKsJk/edit CMS & CRM Needs Doc
class ReportChallenge extends Component {
  state = {
    open: false
  };
  handleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  reportChallenge = async (event) => {
    try {
      const res = await this.props.reportChallenge(this.props.challengeId);
      if (res && res.success) {
        toast.success('SUCCESS: Challenge Reported!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        toast.error('Failure: Challenge not Reported!');
      }
    } catch (err) {
      // This will catch any errors that might occur during the await operation
      toast.error('Failure: Challenge not Reported!');
    } finally {
      // Update the state regardless of the operation's outcome
      this.setState({ open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MyButton
          tip="Report this Challenge"
          onClick={this.handleOpen}
          btnClassName={classes.reportChallenge}
          disabled={this.state.disabled}
        >Report this Challenge</MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to report this challenge for being inappropriate?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.reportChallenge}>
              Report
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ReportChallenge.propTypes = {
  reportChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired
};

export default connect(
  null,
  { reportChallenge }
)(withStyles(reportChallengeStyles)(ReportChallenge));
