import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
// Need to do still
import { othersDecideChallenge } from '../../redux/actions/dataActions';
import othersDecideStyles from './styles/othersDecideStyles';

class OthersDecideChallenge extends Component {
    state = {
        open: false,
        disabled: false
    };
    handleOpen = (event) => {
        this.setState({ disabled: true });
        event.preventDefault();
        event.stopPropagation();
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    othersDecideChallenge = async () => {
        this.setState({ disabled: true });
        try {
            const res = await this.props.othersDecideChallenge(this.props.challengeId);
            if (res && res.success) {
                toast.success('SUCCESS: Voting begins with type "All"!');
                this.props.updateChallenge(this.props.challengeId);
            } else {
                toast.error('Failure: Challenge not Completed!');
            }
        } catch (err) {
            // This will catch any errors that might occur during the await operation
            toast.error('Failure: Challenge not Completed!');
        } finally {
            // Update the state regardless of the operation's outcome
            this.setState({ disabled: false, open: false });
        }
    };
    render() {
        const { classes } = this.props;
        // TODO: Move this outside of render?
        const votingTypeTips = {
            all: "Moves Challenge to 24hr voting stage without casting a vote.",
            both: "Moves Challenge to 24hr voting stage without casting a vote. Challenge will fail at end of voting period if you don't vote.",
            boost: "Moves Challenge to 24hr voting stage without casting a vote.",
            creator: "Moves Challenge to 24hr voting stage without casting a vote.",
            custom: "Moves Challenge to 24hr voting stage without casting a vote.",
            default: "Switches Challenge to 24hr voting stage with voting type \"all\"; all votes count towards the decision",
        };

        // Get the tip based on the voting type from the dictionary
        const tip = votingTypeTips[this.props.votingType];

        return (
            <Fragment>
                <MyButton
                    tip={tip}
                    onClick={this.handleOpen}
                    btnClassName={classes.othersDecideButton}
                    disabled={this.state.disabled}
                >
                    Let Others Decide
                </MyButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        Are you sure you want to complete this challenge ?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button onClick={this.othersDecideChallenge}>
                            Let Others Decide
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

OthersDecideChallenge.propTypes = {
    othersDecideChallenge: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    challengeId: PropTypes.string.isRequired,
    votingType: PropTypes.string.isRequired
};

export default connect(
    null,
    { othersDecideChallenge }
)(withStyles(othersDecideStyles)(OthersDecideChallenge));
