import img1 from '../../images/img1.jpg';
import img2 from '../../images/img2.jpg';
import bgFace from '../../images/bgFace2.jpg';
import thumb from '../../images/topBanner.jpg';
const integrationsTwitchStyles = (theme) => ({
  ...theme.custom,
  team: {
    background: '#9322D4',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  reelWrap: {
    position: 'relative',
    maxHeight: '75vh',
    overflow: 'hidden',
    height: '100%',
    '@media (max-width: 960px)': {
      height: 'unset',
      marginTop: 64,
      maxHeight: 'unset',
      position: 'relative'
    },
    '@media (max-width: 600px)': {
      marginTop: 56
    },
  },
  moreWrap: {
    background: 'rgba(236, 236, 236, 1)',
    paddingBottom: 0,
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#5B5A57',
    position: 'relative',
    textAlign: 'center',
    zIndex: 1,
    justifyContent: 'center',
    '@media (max-width: 960px)': {
      padding: 20
    },
    '& h1': {
      fontSize: '2.5em',
      color: '#5C5C5C',
      textTransform: 'initial',
      textAlign: 'center',
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 700,
      '@media (max-width: 960px)': {
        fontSize: '1.5em'
      },
    },
    '& p': {
      fontSize: '2.5em',
      margin: 'auto',
      maxWidth: 1200,
      textAlign: 'center',
      fontFamily: '"Roboto", sans-serif',
      textTransform: 'initial',
      '@media (max-width: 960px)': {
        fontSize: '1.5em'
      },
    },
  },
  pTextSize: {
    fontSize: '1.25em !important',
    fontWeight: 500,
    textAlign: 'left'
  },
  pTextSizeBold: {
    fontSize: '1.15em !important',
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 700,
    textAlign: 'left'
  },
  needHelpImg: {
    width: '100%',

  },
  exampleWrap: {
    backgroundImage: `url(${bgFace})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    maxHeight: '70vh',
    '& h1': {
      fontSize: '2.5em',
      color: 'rgba(255,255,255, .67)',
      padding: '50px 100px',
      '@media (max-width: 1450px)': {
        fontSize: '2em',
      },
      '@media (max-width: 1230px)': {
        padding: 20
      },
      '@media (max-width: 960px)': {
        fontSize: '1.5em',
        padding: 20
      },
    },
    '@media (max-width: 960px)': {
      padding: 20,
      maxHeight: 'unset',
      height: 'unset'
    },
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#5B5A57',
    position: 'relative',
  },
  cardsWrap: {
    background: '#F9F9F9',
    padding: '50px 100px',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 600px)': {
      justifyContent: 'center'
    },
    '@media (max-width: 960px)': {
      padding: 20
    },
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    color: '#9322D4',
    position: 'relative'
  },
  reel: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    filter: 'blur(8px)',
    zIndex: 1,
    '@media (max-width: 1325px)': {
      width: 'unset',
      height: '100%',
    },
    '@media (max-width: 960px)': {
      width: '100%',
      height: 'unset',
    },
    '@media (max-width: 600px)': {
      width: 'unset',
      height: '100%',
    },
  },
  bgFilter: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(147, 34, 212, .5)',
    zIndex: 2
  },
  bgThumb: {
    backgroundImage: `url(${bgFace})`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    filter: 'blur(8px)',
    zIndex: 0
  },
  mainGridModal: {
    marginTop: 75,
    padding: '20px 10px'
  },
  footerGridModal: {
    minHeight: 40
  },
  mainGrid: {
    height: '100%',
    position: 'relative',
    padding: '50px 100px',
    '& div': {
      boxShadow: 'none'
    },
    '@media (max-width: 960px)': {
      padding: 20
    },
    '@media (max-width: 600px)': {
      width: '100%',
      textAlign: 'center'
    },
  },
  cardCta: {
    borderRadius: 8,
    padding: 20
  },
  hideMobile: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  topJoint: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    backgroundImage: `url(${thumb})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: 3,
    '@media (max-width: 960px)': {
      padding: '80px 20px'
    },
    '& h1': {
      margin: 10,
      fontSize: '3em',
      '@media (max-width: 960px)': {
        fontSize: '1.5em'
      },
    }
  },
  cta: {
    minWidth: 500,
    color: '#FFF',
    textAlign: 'center',
    marginBottom: 200,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& h1': {
      fontFamily: '"Roboto", sans-serif',
    },
    '& p': {
      fontFamily: '"Roboto", sans-serif',
      fontWeight: 700
    },
    '& span': {
      fontSize: '2.0rem',
      '@media (max-width: 960px)': {
        fontSize: '1.5em'
      },
      '@media (max-width: 600px)': {
        fontSize: '1.2em'
      },
    },
    '@media (max-width: 960px)': {
      minWidth: 'unset',
      marginBottom: 0,
    },
  },
  dripDown: {
    maxHeight: 400,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: -200,
    zIndex: 0,
    '@media (max-width: 690px)': {
      top: -200
    },
    '@media (max-width: 600px)': {
      top: -100
    },
  },
  dripUp: {
    maxHeight: 400,
    width: '100%',
    position: 'relative',
    left: 0,
    top: -1,
    zIndex: 0,
    transform: 'rotate(180deg)',
    background: '#F9F9F9'
  },
  planets: {
    textAlign: 'right',
    width: '100%',
    maxWidth: 500
  },
  planets2: {
    position: 'absolute',
    right: 20,
    top: 0,
    zIndex: 1,
    textAlign: 'right',
    width: '100%',
    maxWidth: 300,
    '@media (max-width: 600px)': {
      maxWidth: 170
    },
  },
  exampleCta: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 690px)': {
      marginTop: 100
    },
  },
  callCardWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  callCardWrap1: {
    marginTop: -50,
  },
  callCard: {
    display: 'flex',
    flexDirection: 'column',
    background: '#062A4E',
    color: '#fff',
    borderRadius: 8,
    padding: 20,
    margin: '0 10px',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 278,
    textAlign: 'center',
    minHeight: 370,
    '& p': {
      margin: 5
    },
    '@media (max-width: 700px)': {
      padding: 5,
    },
    '@media (max-width: 600px)': {
      margin: 10,
      padding: 20,
    },
  },
  cardIcon: {
    width: 170,
    marginBottom: 20
  },
  mascotGrid: {
    '@media (max-width: 600px)': {
      //   height: 'unset',
      //   position: 'absolute',
      //   top: 0
    }
  },
  mascot: {
    width: '100%',
    '@media (max-width: 600px)': {
      maxWidth: '100px',
      height: 'unset'
    },
  },
  buttonDefault: {
    width: '100%',
    marginTop: 30,
    borderRadius: '2px',
    maxWidth: '220px'
  },
  label: {
    color: '#fff',
    borderColor: '#fff',
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
  },
  submitButtonChall: {
    background: '#7B5DF9',
    color: '#fff',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '10px',
  },
  submitButton: {
    minWidth: 100,
    background: 'transparent linear-gradient(270deg, #00B4FF 0%, #565FFF 47%, #B600FF 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    padding: '16x 36px',
    opacity: 1,
    transition: 'all .4s',
    borderRadius: 80,
    fontSize: '.75rem',
    '&:hover': {
      opacity: 0.9,
    },
    '@media (max-width: 960px)': {
      padding: '8px 18px',
    },
  },
  flexCol: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  faqButton: {
    backgroundColor: '#68DEFC',
    color: '#fff',
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px #68DEFC',
    borderRadius: 20,
    marginTop: 100,
    maxWidth: 278,
    '&:hover': {
      opacity: 0.9,
      border: 'solid 1px #5B5A57',
      background: '#68DEFC'
    },
    '@media (max-width: 960px)': {
      marginTop: 10,
    },
  },
  footerButton1: {
    backgroundColor: '#8065FF',
    color: '#FAFEFF',
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px #8065FF',
    borderRadius: 20,
    margin: 20,
    marginTop: 0,
    maxWidth: 278,
    '&:hover': {
      opacity: 0.9,
      border: 'solid 1px #5B5A57',
      background: '#8065FF'
    },
    '@media (max-width: 960px)': {
      marginTop: 10,
    },
  },
  footerButton2: {
    backgroundColor: '#fff',
    color: '#8065FF',
    opacity: 1,
    transition: 'all .4s',
    border: 'solid 1px #fff',
    borderRadius: 20,
    margin: 20,
    marginTop: 0,
    maxWidth: 278,
    '&:hover': {
      opacity: 0.9,
      border: 'solid 1px #5B5A57',
      background: '#fff'
    },
    '@media (max-width: 960px)': {
      marginTop: 10,
    },
  },
  //tabPanel: {
  //'& [class*="--selected"]': {
  //   opacity: 1,
  //    animation: "fade-and-slide-in 250ms ease-out",
  //. },   
  gang: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  card: {
    boxShadow: 'none',
    textAlign: 'center',
    '& textarea': {
      textAlign: 'center',
    }
  },
  footer: {
    background: '#fff',
    position: 'relative',
    paddingBottom: 100,
    color: '#5B5A57',
    '& p': {
      color: '#5B5A57',
    },
    '& h1': {
      '@media (max-width: 960px)': {
        fontSize: '1.5em',
        textAlign: 'left'
      },
    },
  },
  footerGrid: {
    padding: '0 100px',
    '@media (max-width: 960px)': {
      padding: '0 20px',
    },
    '@media (max-width: 600px)': {
      textAlign: 'left',
      alignItems: 'center'
    },
  },
  footerHead: {
    height: 'unset',
    position: 'absolute',
    top: 150,
    textAlign: 'left',
    '& p': {
      color: 'rgba(255,255,255,.4)',
      cursor: 'default',
      '&:hover': {
        color: 'rgba(255,255,255,.4)'
      }
    },
    '@media (max-width: 600px)': {
      textAlign: 'center'
    },
  },
  footerActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  copyRight: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    padding: '0 100px',
    '& a': {
      cursor: 'pointer'
    },
    '@media (max-width: 960px)': {
      padding: '0 20px',
    },
    '@media (max-width: 600px)': {
      textAlign: 'center',
      width: '100%',
      padding: 0
    },
  },
  footerSocial: {
    width: 25,
    marginRight: 10
  },
  sitelinks: {
    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
  },
  sitelink: {
    color: 'rgba(255,255,255,.4)',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff'
    },
    margin: 10,
  },
  ctaText: {
    textTransform: 'capitalize',
    fontWeight: 700
  },
  startIntegrationButton: {
    minWidth: 100,
    background: 'transparent linear-gradient(270deg, #947BFF 0%, #947BFF 47%, #947BFF 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    padding: '16x 36px',
    opacity: 1,
    transition: 'all .4s',
    borderRadius: 80,
    fontSize: '.75em',
    boxShadow: 'none',

    justifyContent: 'center !important',
    //background: '#886EFE',
    //color: '#fff',
    //padding: '8px 20px',
    //opacity: 1,
    //borderRadius: 8,
    //fontSize: '1.3em',
    //position: 'absolute',
    //left: '50%',
    //top: '100%',
    //transform: 'translate(-50%, -50%)',
    //textTransform: 'none'
    margin: "auto"
  },
  integrationSection: {
    fontWeight: 700,
    verticalAlign: 'middle',
    padding: '40px',
    minWidth: 100,
    //width: '100%',
    height: '100%',
    background: '#7B5DF9',
    color: '#fff',
    opacity: 1,
    borderRadius: '80px',
    fontSize: '1.25em',
    '@media (max-width: 1450px)': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'unset',
      justifyContent: 'center',
      alignItems: 'center',
    },

  },
  integrationSectionText2: {
    display: 'flex',
    justifyContent: 'center !important',
    minWidth: 215,
    maxWidth: '100%',
    //backgroundColor: '#886EFE',
    color: '#fff',
    padding: '8px 20px',
    opacity: 1,
    borderRadius: 8,
    fontSize: '1.3em',

  },
  integrationSectionText1: {
    //justifyContent: 'center !important',
    minWidth: 100,
    maxWidth: '100%',
    //height: '100% !important',
    //backgroundColor: '#886EFE',
    //color: '#fff',
    padding: '8px 20px',
    opacity: 1,
    borderRadius: 8,
    fontSize: '1.3em',

  },
  needHelpHeader: {
    textTransform: 'capitalize',
    fontWeight: 700,
    '& h1': {
      textAlign: 'left',
    },
    '& p': {
      textAlign: 'left',
    },
  },
  needHelpImgFormat: {
    padding: 0,
    paddingTop: 110,
    paddingLeft: 120,
    paddingRight: 25,
    //paddingBottom: 120,
    '& h1': {
      textAlign: 'left',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
    '@media (max-width: 960px)': {
      padding: '20px',
      paddingTop: 'unset',
      paddingLeft: 'unset',
      paddingRight: 'unset',
    },
  },

  needHelpIntegFormat: {
    height: '100%',
    position: 'relative',

    paddingbottom: 60,
    paddingTop: 80,
    paddingRight: 120,
    paddingLeft: 40,
    //paddingBottom: 120,
    //'& h1': {
    // textAlign: 'left',
    // },

    //paddingTop: '80px',
    //paddingRight: '120px',
    //paddingLeft: '40px',
    //'& div': {
    // boxShadow: 'none'
    // },

    '@media (max-width: 600px)': {
      width: '100%',
      textAlign: 'center',
      padding: 20,
    },
  },
  needHelpDescFormat: {
    paddingbottom: 60,
    paddingTop: 80,
    paddingLeft: 120,
    paddingRight: 40,
    //paddingBottom: 120,
    '& h1': {
      textAlign: 'left',
    },
    '@media (max-width: 600px)': {
      padding: 20,
    },

  },
  tabHeader: {
    boxShadow: 'none',
    //background: 'transparent'
  },
  commandContainerFormat1: {
    backgroundColor: 'rgba(236,236,236,1)',
    paddingBottom: '750px',
  },
  commandContainerFormat: {
    padding: 0,
    //paddingTop: '30px',
    //paddingBottom: '30px',
    paddingLeft: '120px',
    paddingRight: '120px',
    justifyContent: 'center',
    '@media (max-width: 960px)': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  commandListFormat: {
    padding: 0,
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '65px',
    paddingRight: '65px',
    backgroundColor: 'rgba(236,236,236,1)',
  },

  commandFormat1: {
    padding: 0,
    paddingTop: 0,
    paddingLeft: '120px',
    paddingRight: '120px'

  },
  commandFormat2: {
    paddingLeft: '25px',
    paddingRight: '25px',
    background: '#fff',
    //maxHeight: 160,
    marginBottom: '20px',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 960px)': {
      display: 'none'
    },
  },
  commandFormat3: {
    padding: '15px 90px',
    marginBottom: '20px',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    background: 'transparent linear-gradient(180deg, #7A5DF9 0%, #6C4FEA 100%) 0% 0% no-repeat padding-box',
    //maxHeight: 160
    '@media (max-width: 960px)': {
      padding: '15px 20px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'unset',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  commandDescription: {
    paddingBottom: '25px',
    fontSize: '1.2em !important',
    paddingRight: '50px'
  },
  commandExample: {
    justifyContent: 'center !important',
    minWidth: 100,
    background: '#886EFE',
    color: '#fff',
    padding: '8px 20px',
    opacity: 1,
    borderRadius: 8,
    fontSize: '1.3em',
    //position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'none'
  },
  commandHeaderButton: {
    //justifyContent: 'center !important',
    minWidth: 100,
    width: 130,
    height: 50,
    background: '#D5D5D5',
    color: '#fff',
    padding: '0px',
    marginRight: '15px',
    opacity: 1,
    borderRadius: '35px',
    fontSize: '1.25em',
    //position: 'absolute',
    //left: '50%',
    //top: '50%',
    //transform: 'translate(-50%, -50%)',
    textTransform: 'none'
  },
  commandActiveButton: {
    //justifyContent: 'center !important',
    minWidth: 100,
    width: 130,
    height: 50,
    background: '#7B5DF9',
    color: '#fff',
    padding: '0px',
    marginRight: '15px',
    opacity: 1,
    borderRadius: '35px',
    fontSize: '1.25em',
    //position: 'absolute',
    //left: '50%',
    //top: '50%',
    //transform: 'translate(-50%, -50%)',
    textTransform: 'none'
  },
  pink: {
    color: '#B600FF'
  },
  whiteBg: {
    background: '#fff'
  },
  mid: {
    //maxHeight: 354,
    height: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    justifyContent: 'center !important',
    '& p': {
      fontSize: '2em',
      '@media (max-width: 960px)': {
        fontSize: '1.05em',
      },
      '@media (max-width: 600px)': {
        fontSize: '1.5em',
      },
    },
    '@media (max-width: 600px)': {
      height: 'unset',
    },
  },
  smoll: {
    background: '#fff',
    padding: '54px 0',
    '& p': {
      fontSize: '1.5em',
      '@media (max-width: 960px)': {
        fontSize: '1em',
      },
    },
  },
  img1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '15px !important',
    aspectRatio: 1,
    position: 'relative',
    backgroundImage: `linear-gradient(180deg, #7B5DF9F0 0%, #5537D2 100%), url(${img1})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    '@media (max-width: 960px)': {
      maxHeight: 300,
    },

  },
  img2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '15px !important',
    aspectRatio: 1,
    position: 'relative',
    backgroundImage: `linear-gradient(180deg, #7B5DF9F0 0%, #5537D2 100%), url(${thumb})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    '@media (max-width: 960px)': {
      maxHeight: 300,
    },

  },
  img3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '15px !important',
    aspectRatio: 1,
    position: 'relative',
    backgroundImage: `linear-gradient(180deg, #7B5DF9F0 0%, #5537D2 100%), url(${img2})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '0 auto',
    '@media (max-width: 960px)': {
      maxHeight: 300,
    },

  },
  ctaTextCard: {
    width: '100%',
    zIndex: 1,
    color: '#fff',
    fontSize: '.785em',
    textAlign: 'left',
    '& img': {
      width: 50
    },
    '& p': {
      textAlign: 'left',
    }
  },
  thick: {
    fontSize: '3em !important',
    fontWeight: 600,
    margin: '15px 0px !important'
  },
  ctaImgs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& img': {
      border: 'solid 1px #9322D4',
      width: 200,
      borderRadius: '50%',
      '@media (max-width: 960px)': {
        width: 150,
      },
      '@media (max-width: 760px)': {
        width: 100,
      },
    },
    '@media (max-width: 600px)': {
      width: '100%',
      justifyContent: 'center'
    },
  },
  team2: {
    zIndex: 1,
  },
  team1: {
    zIndex: 2,
    marginLeft: -30
  },
  team3: {
    zIndex: 3,
    marginLeft: -30
  },
  team4: {
    zIndex: 4,
    marginLeft: -30
  },
  dialog: {
    borderRadius: 0,
    // '& div': {
    //   borderRadius: 0,
    //   maxWidth: 'unset',
    //   '@media (max-width: 960px)': {
    //     overflowY: 'auto'
    //   },
    // },
  },
  dialogPaper: {
    overflowX: 'hidden',
    position: 'relative',
    background: '#fff',
    maxWidth: 'unset',
    '@media (max-width: 960px)': {
      maxHeight: 'unset',
      width: '100%',
      height: '100%',
      margin: 0
    },
  },
  modalWrap: {
    position: 'relative',
    width: '100%',
    minWidth: '730px',
    maxWidth: 730,
    '@media (max-width: 960px)': {
      minWidth: '460px',
      padding: 0,
      height: '100vh',
    },
  },
  searchBar: {
    position: 'relative',
    background: "#7B5DF9",
    height: '227px',
  },
  pageText: {
    fontWeight: 500,
    color: '#8C8C8C',
    margin: '8px 0px'
  },
  textField: {
    borderRadius: 35,
    '& fieldset': {
      borderRadius: 35
    },
  },
  ideas: {
    padding: '40px 30px',
    color: '#fff',
    background: 'linear-gradient(to bottom, #7B5DF9, #5B43C1)'
  },
  shareideas: {
    border: 'solid 1px #7B5DF9',
    padding: '40px 30px',
    color: '#7B5DF9',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    cursor: 'pointer',
    '& p': {
      display: 'flex'
    },
    '& svg': {
      marginRight: 10
    }
  },
  dice: {
    maxWidth: 100
  },
  jobs: {
    width: 82,
    height: 82,
    //margin: '0 5px',
  },
  outlined: {
    margin: 8,
    width: '100%',
    border: 'solid 1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '35px',
  },
  whiteTxt: {
    textAlign: 'center'
  },
  endDate: {
    textAlign: 'center'
  },
  planeIcon: {
    marginLeft: 5
  },
  role: {
    position: 'absolute',
    bottom: 15,
    left: 15,
    fontSize: '.85em !important'
  }
});

export default integrationsTwitchStyles;