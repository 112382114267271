import {
  SET_CHALLENGES,
  SET_ACCEPTING_USERS,
  SET_COMPLETED_CHALLENGES,
  SET_STAFF_CHALLENGES,
  UNLIKE_CHALLENGE,
  LOADING_DATA,
  DELETE_CHALLENGE,
  POST_CHALLENGE,
  POST_SUGGESTION,
  SET_CHALLENGE,
  SUBMIT_COMMENT,
  GET_TOTAL_RAISED
} from '../types';

const initialState = {
  challenges: [],
  completed: [],
  users: [],
  challenge: {},
  loading: false
};

let index;

function dataReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case SET_CHALLENGES:
      return {
        ...state,
        challenges: action.payload,
        loading: false
      };
    case SET_COMPLETED_CHALLENGES:
      return {
        ...state,
        completed: action.payload,
        loading: false
      };
    case SET_STAFF_CHALLENGES:
      return {
        ...state,
        staff: action.payload,
        loading: false
      };
    case SET_ACCEPTING_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    case SET_CHALLENGE:
      return {
        ...state,
        challenge: action.payload
      };
      // Why do we use a reducer for UNLIKE_CHALLENGE but not for LIKE_CHALLENGE?
      // What does this mean for voting?   
    case UNLIKE_CHALLENGE:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload.challengeId
      );
      state.challenges[index] = action.payload;
      if (state.challenge.challengeId === action.payload.challengeId) {
        state.challenge = action.payload;
      }
      return {
        ...state
      };
    case DELETE_CHALLENGE:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload
      );
      state.challenges.splice(index, 1);
      return {
        ...state
      };
    case POST_CHALLENGE:
      return {
        ...state,
        stripeSession: action.payload.sessionId
      };
    case POST_SUGGESTION:
      return {
        ...state
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        challenge: {
          ...state.challenge,
          comments: [action.payload, ...state.challenge.comments]
        }
      };
    case GET_TOTAL_RAISED:
      index = state.challenges.findIndex(
        (challenge) => challenge.challengeId === action.payload.challengeId
      );
      state.challenges[index] = {...state.challenges[index], ...action.payload};    
      return {
        ...state
      };
    default:
      return state;
  }

}

export default dataReducer;