const buyMeedPointsStyles = (theme) => ({
    ...theme.custom,
    card: {
        background: '#fff',
        position: 'relative',
        marginBottom: 20,
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0',
        maxWidth: '550px',
        minWidth: '290px',
        minHeight: '500px',
        margin: '0 auto'
    },
    content: {
        padding: 25,
        objectFit: 'cover'
    },
    endDate: {
        width: '100%',
        background: '#fff',
        padding: 10
    },
    legalText: {
        color: "#828282",
        textAlign: 'center',
        padding: '10px 0',
        fontWeight: 300
    },
    submitButton: {
        width: '100%',
        backgroundColor: "#22C7FC",
        color: 'white',
        borderRadius: '20px',
        marginBottom: '15px',
        padding: '10px 0'
    },
    pageText: {
        marginBottom: '5px'
    },
    progress: {
        position: 'absolute',
        right: '2rem'
    },
    hidden: {
        display: 'none'
    },
    tabHeader: {
        boxShadow: 'none',
        background: 'transparent'
    },
    tabPanel: {
        padding: '20px 56px',
        '& hr': {
            margin: 0,
            opacity: .2,
            border: 'solid .5px purple'
        },
        '@media (max-width: 600px)': {
            padding: '20px 0px',
        },
    },
    balances: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        background: '#7A5DF9',
        padding: '33px 40px',
        color: '#fff',
        '@media (max-width: 600px)': {
            padding: '20px 0px',
        },
    },
    balval: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    bal: {
        fontSize: 30,
        fontWeight: 300,
        '@media (max-width: 600px)': {
            fontSize: 22,
        },
    },
    val: {
        marginLeft: 18,
        fontSize: 10,
        fontWeight: 700,
        '@media (max-width: 600px)': {
            fontSize: 12,
        },
    },
    vertLine: {
        width: 1,
        height: 20,
        background: '#fff'
    },
    pageTitle: {
        fontWeight: 300,
        padding: 10
    },
    selectPoints: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 0'
    },
    checkAgree: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    checkBox: {
        color: '#5B5A57'
    },
    modPanelHeader: {
        width: '100%',
        display: 'flex',
        fontWeight: 700,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    table: {
        textTransform: 'uppercase',
        '& th': {
            '@media (max-width: 600px)': {
                padding: 5,
            },
        },
        '& td': {
            '@media (max-width: 600px)': {
                padding: 5,
            },
        }
    },
    tableBtn: {
        background: '#FFDF57',
    },
    tableHeader: {
        verticalAlign: 'top',
        '& th': {
            fontWeight: 900,
        }
    },
});

export default buyMeedPointsStyles;