import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';
import { authenticateViaTwitter } from '../redux/actions/userActions';

import { toast } from 'react-toastify';
import forgotPasswordStyles from './styles/forogtPasswordStyles';

const auth = getAuth();

class forgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
    };

    this.setState({ submitted: true });

    try {
      await sendPasswordResetEmail(auth, userData.email);
      toast.success('SUCCESS: Password reset email sent!');
    } catch (error) {
      // console.error('Password reset error:', error.message); // More detailed error logging
      toast.error('Password reset email not sent.');
    } finally {
      this.setState({ submitted: false });
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {
      classes,
    } = this.props;
    const { errors } = this.state;

    return (
      <div className={"container"}>
        <Grid container className={classes.form}>
          <Grid className={classes.gang} item xs={12}>
            <Typography
              variant="h4"
              className={classes.pageTitle}
            >
              Reset Password
            </Typography>
            <form className={classes.gang} noValidate onSubmit={this.handleSubmit}>
              <TextField
                variant="standard"
                id="email"
                name="email"
                type="email"
                label="Email"
                className={classes.pageTitle}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.pageTitle,
                  },
                }}
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth />
              {errors.general && (
                <Typography color="#fff" variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonDefault}
                disabled={!this.state.email || this.state.submitted}
              >
                Submit
              </Button>
              <br />
              <small className={classes.pageTitle}>
                Don't have an account? Sign up <Link to="/signup">here</Link>.
              </small>
              <br />
              <small className={classes.pageTitle}>
                Having trouble signing in? <Link to="/forgotPassword">Reset Password</Link>.
              </small>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

forgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  loginUser,
  authenticateViaTwitter
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(forgotPasswordStyles)(forgotPassword));