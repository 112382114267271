import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  SET_AUTHENTICATED,
  LOADING_USER,
  LOADED_USER,
  MARK_NOTIFICATIONS_READ,
  REPORT_CHALLENGE,
  SET_CSRF_TOKEN,
  CLEAR_CSRF_TOKEN,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE,
} from '../types';
import axios from 'axios';
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  sendEmailVerification
} from "firebase/auth";
import { toast } from 'react-toastify';
import { ERROR_MAPPING } from '../errorMapping';
axios.defaults.withCredentials = true;
const auth = getAuth();
var googleProvider = new GoogleAuthProvider();
var facebookProvider = new FacebookAuthProvider();
var twitterProvider = new TwitterAuthProvider();
var emailProvider = new EmailAuthProvider();


export const showToastMessage = message => ({
  type: SHOW_TOAST,
  payload: message
});

export const clearToastMessage = () => ({
  type: CLEAR_TOAST_MESSAGE
});


/**
 * Authenticates a user via Google and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Facebook and Twitter versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaGoogle(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Google!');
 * });
 */
export const authenticateViaGoogle = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const result = await signInWithPopup(auth, googleProvider);
    if (!result) {
      console.error('Failed to sign in with Google.');
      return;
    }

    // const credential = result.credential;
    const user = result.user;
    // Get the ID token after third-party authentication
    const idToken = await user.getIdToken(true);
    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'google',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/google', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }

        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }
        }

        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };

    const userData = {
      uid: user.uid || '',
      authProvider: 'google',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || ''
    };

    const response = await handleUserDataOnServer(userData);
    return response;

  } catch (error) {
    console.log('Error during Google authentication: ', error);
    if (error && error.message) {
      toast.error(error.message);
    }

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        dispatch({ type: SET_ERRORS, payload: error });
      }
    }
  }
};

/**
 * Authenticates a user via Facebook and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Google and Twitter versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaFacebook(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Facebook!');
 * });
 */
export const authenticateViaFacebook = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const result = await signInWithPopup(auth, facebookProvider);
    if (!result) {
      console.log('Failed to sign in with Facebook.');
      return;
    }

    // const credential = result.credential;
    const user = result.user;

    // Get the ID token after third-party authentication
    const idToken = await user.getIdToken(true);

    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'facebook',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/facebook', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }

        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }
        }

        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response;
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };

    const userData = {
      uid: user.uid || '',
      authProvider: 'facebook',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || ''
    };

    const response = await handleUserDataOnServer(userData);
    return response;
  } catch (error) {
    console.log('Error during Facebook authentication: ', error);

    if (error && error.message) {
      toast.error(error.message);
    }

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        dispatch({ type: SET_ERRORS, payload: error });
      }
    }
  }
};

/**
 * Authenticates a user via Twitter and handles subsequent user data processing, including obtaining a CSRF token for added security.
 * This function is kept separate from the Google and Facebook versions to accommodate potential provider-specific changes and nuances.
 * 
 * @function
 * @async
 * @param {Object} history - The history object used for navigation.
 * @param {string|null} referralCode - The referral code associated with user registration, if available.
 * @returns {Promise<void>} - Resolves upon successful authentication, user data processing, and CSRF token retrieval.
 * 
 * @throws Will throw an error if there is an issue during the authentication process, during the communication with the server, or if CSRF token retrieval fails.
 *
 * @example
 * authenticateViaTwitter(history, 'XYZ123').then(() => {
 *   console.log('User authenticated via Twitter!');
 * });
 */
export const authenticateViaTwitter = (history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  try {
    const result = await signInWithPopup(auth, twitterProvider);
    if (!result) {
      console.log('Failed to sign in with Twitter.');
      return;
    }

    const credential = result.credential;
    const user = result.user;
    const idToken = await user.getIdToken(true);

    const twitterTokens = {
      accessToken: credential?.accessToken,
      secret: credential?.secret
    };

    /**
     * A helper function to handle user data on the server. It facilitates the user login or creation process using third-party authentication data.
     * This function is internally called within the third-party authentication functions and is not exported for use elsewhere.
     *
     * @function
     * @async
     * @param {Object} data - An object containing the user data to be sent to the server. It is expected to have properties such as `uid`, `authProvider`, `handle`, etc., with `token` being added within the function.
     * 
     * @returns {Promise<void>} - A promise resolving with no value upon the successful handling of user data on the server, including CSRF token retrieval and setup.
     * 
     * @throws Will throw an error if there's an issue during the server communication, during the CSRF token retrieval, or if there's an issue sending the email verification.
     *
     * @example
     * // Note: This function is not exported and is only intended to be called internally within third-party authentication functions.
     * handleUserDataOnServer({
     *   uid: '12345',
     *   authProvider: 'twitter',
     *   handle: 'user12345',
     *   // ... other properties
     * }).then(() => {
     *   console.log('User data handled on server successfully.');
     * });
     */
    const handleUserDataOnServer = async (data) => {
      data.token = idToken;
      try {
        const response = await axios.post('/thirdParty/twitter', data, {
          withCredentials: true
        });

        if (!user.emailVerified) {
          try {
            await sendEmailVerification(user);
          } catch (error) {
            console.log('Error sending verification email: ', error);
          }
        }

        if (response.data && (response.data.status === 'existing' || response.data.status === 'new')) {

          const csrfToken = response.data.csrfToken;
          if (csrfToken) {
            dispatch({ type: SET_CSRF_TOKEN, payload: csrfToken });
            axios.defaults.headers.common['x-csrf-token'] = csrfToken;
          }
        }

        if (idToken) {
          dispatch(getUserData());
          dispatch({ type: SET_AUTHENTICATED });
          dispatch({ type: CLEAR_ERRORS });
          history.push('/home');
        }
        return response;
      } catch (error) {
        console.log('Error during process on server: ', error);
        dispatch({ type: SET_ERRORS, payload: error });
      }
    };


    const userData = {
      uid: user.uid || '',
      authProvider: 'twitter',
      handle: 'user' + Math.floor(100000 + Math.random() * 900000),
      email: user.email || '',
      thirdPartySignup: true,
      userImage: user.photoURL || '',
      referralCode: referralCode || '',
      token: twitterTokens // TODO: Verify this information is being used correctly by Twitter in BE.
    };

    const response = await handleUserDataOnServer(userData);
    return response;

  } catch (error) {
    console.log('Error during Twitter authentication: ', error);

    if (error && error.message) {
      toast.error(error.message);
    }

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        dispatch({ type: SET_ERRORS, payload: error });
      }
    }
  }
};

/**
 * Links a user's email with their current authentication method.
 * @param {Object} userData - The user data.
 * @param {string} userData.email - The email of the user.
 * @param {string} userData.password - The password of the user.
 * @param {Object} history - The browser history object.
 * @param {Function} dispatch - Redux dispatch method.
 * @returns {Promise<Object>} A promise that resolves with the response data or error.
 */
export const linkEmail = (userData, history) => async (dispatch, getState) => {
  // Dispatch the loading UI action
  dispatch({ type: LOADING_UI });

  // Generate the email and password credential for linking
  const credential = emailProvider.credential(userData.email, userData.password);

  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;

    if (!firebaseUser) {
      throw new Error("No authenticated user found.");
    }

    // Try to link the credential with the current Firebase user
    const usercred = await linkWithCredential(firebaseUser, credential);

    // Try to post the user's email to the backend
    const res = await axios.post('/user', { email: userData.email });

    // Check the response and dispatch further actions if necessary
    if (res && res.data) {
      dispatch(getUserData());
      return res.data;
    } else if (res) {
      return res;
    }

  } catch (error) {
    console.error("Error during account linking or data posting:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  }
};

/**
 * Creates an action to set the CSRF token in the Redux store.
 * 
 * @function setCsrfToken
 * @param {string} token - The CSRF token obtained from the server.
 * @returns {Object} The action object with the type `SET_CSRF_TOKEN` and the provided token as its payload.
 */
export const setCsrfToken = (token) => ({
  type: SET_CSRF_TOKEN,
  payload: token
});

/**
 * Creates an action to clear the CSRF token from the Redux store.
 * 
 * @function clearCsrfToken
 * @returns {Object} The action object with the type `CLEAR_CSRF_TOKEN`.
 */
export const clearCsrfToken = () => ({
  type: CLEAR_CSRF_TOKEN
});

/**
 * @function loginUser
 * @description Logs the user in using Firebase's client-side authentication. On successful authentication, sends the user's ID token to the backend to set a session cookie and then dispatches actions to retrieve user data, set CSRF cookie, and clear errors.
 * 
 * @param {Object} userData - The user data for logging in.
 * @param {string} userData.email - The email address of the user.
 * @param {string} userData.password - The user's password.
 * @param {Object} history - The browser's history object, which can be used for navigation if necessary.
 * @param {Function} dispatch - The Redux dispatch method.
 * 
 * @returns {Promise<boolean|Object>} Returns a promise that resolves with:
 * - `true` if login was successful.
 * - `false` if there was a failure in server-side session creation.
 * - An `error` object if an exception occurred during the process.
 * 
 * @throws Will throw an error if the login process encounters an issue.
 * 
 * @example 
 * const result = await loginUser({ email: "user@example.com", password: "securepass" }, history);
 * if(result === true) {
 *   // Login successful, navigate or trigger other events
 * } else if(result === false) {
 *   // Server-side session creation failed
 * } else {
 *   // Some other error occurred during the process
 * }
 */
export const loginUser = (userData, history) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    // Authenticate with Firebase on the client-side.
    const userCredential = await signInWithEmailAndPassword(auth, userData.email, userData.password);
    const token = await userCredential.user.getIdToken();

    // Send the token to the backend.
    const res = await axios.post('/login', { token: token });

    if (res && res.data && res.data.success) {

      if (res.data.csrfToken) {
        dispatch({ type: SET_CSRF_TOKEN, payload: res.data.csrfToken });
        axios.defaults.headers.common['x-csrf-token'] = res.data.csrfToken;
      }

      dispatch(getUserData());
      dispatch({ type: SET_AUTHENTICATED });
      dispatch({ type: CLEAR_ERRORS });

      return true;  // Indicates a successful login attempt
    } else {
      return false;  // Indicates a failed login attempt
    }
  } catch (err) {
    console.error("Error during login:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, payload: err.response.data });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
    } else {
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    }

    return false; //{ error: 'Error during login.' };
  }
};


/**
 * Fetches an anonymous token and sets it as an authorization header.
 *
 * @async
 * @function
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {void}
 * @throws {Error} When unable to fetch the anonymous token.
 */
/*
export const getAnonymousToken = () => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const response = await axios.get('/getAnonymousToken');

    if (response && response.data) {
      const token = response.data.user?.stsTokenManager?.accessToken;

      if (token) {
        setAuthorizationHeader(token);
      }
      dispatch({ type: CLEAR_ERRORS });
    }

  } catch (err) {
    console.error("Error fetching anonymous token:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
    } else {
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    }

    throw err;
  }
}
*/
// export const authenticateViaTwitter = (userToken, history) => (dispatch) => {
//   dispatch({ type: LOADING_UI });
//   setAuthorizationHeader(userToken);
//   dispatch(getUserData());
//   history.push('/home');
// };

/**
 * Signs up a new user, and handles subsequent operations like dispatching the new user data to the Redux store,
 * sending an email verification (if required), and navigating to the appropriate page post-signup.
 *
 * @function
 * @async
 *
 * @param {Object} newUserData - User data required for the signup process.
 * @param {string} newUserData.email - The user's email address.
 * @param {string} newUserData.password - The user's chosen password.
 * @param {Object} history - Browser history object to control navigation post-signup.
 * @param {string} [referralCode] - Optional referral code provided by the user.
 *
 * @returns {Promise<void>} A promise that resolves when the signup process and all subsequent operations are complete.
 * 
 * @throws Will throw an error if any error occurs during the signup process, which will be caught and handled by dispatching to the Redux store.
 * 
 * @example
 * signupUser({
 *   email: 'john.doe@example.com',
 *   password: 'securepassword123'
 * }, history, 'XYZ123')(dispatch);
 */
export const signupUser = (newUserData, history, referralCode) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  if (referralCode) {
    newUserData.referralCode = referralCode;
  }

  try {
    const signupResponse = await axios.post('/signup', newUserData);
    if (signupResponse && signupResponse.data && signupResponse.data.created) {
      // Sign in the user client-side
      const userCredential = await signInWithEmailAndPassword(auth, newUserData.email, newUserData.password);
      const idToken = await userCredential.user.getIdToken();

      // Call backend endpoint to set session cookie and CSRF token using the ID token
      const sessionResponse = await axios.post('/sessionLogin', { idToken });

      // Check for CSRF token in the response and set it in axios defaults
      if (sessionResponse.data.csrfToken) {
        dispatch({ type: SET_CSRF_TOKEN, payload: sessionResponse.data.csrfToken });
        axios.defaults.headers.common['x-csrf-token'] = sessionResponse.data.csrfToken;
      }

      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_AUTHENTICATED });
      dispatch(getUserData());

      // Redirect user after successful signup and session setup
      history.push(`/users/${signupResponse.data.handle || 'home'}`);

      toast.success("Sign Up complete, redirecting...");
    } else {
      toast.error("Sign Up Failed");
      dispatch({
        type: SET_ERRORS,
        payload: signupResponse.data
      });
    }
  } catch (err) {
    console.error("Error during signup:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // If it's an unknown error, dispatch a generic error action.
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
    }

    // Potentially throw the error if you want the calling function to know about it.
    throw err;
  }
};

/**
 * Logs the user out and redirects them to the login page.
 *
 * @function
 * @param {Object} [history] - The history object from React Router for navigation.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {void}
 */
export const logoutUser = (history, isForced = false) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    console.log({ history, isForced });
    delete axios.defaults.headers.common['Authorization'];

    // Make an API call to the server-side logout endpoint
    await axios.post('/logout');

    // Handle local app logout operations after the server has cleared the session cookie and token
    dispatch({
      type: SET_USER,
      payload: {
        authenticated: false,
        loading: false,
        likes: [],
        notifications: [],
        credentials: {
          handle: '',
          createdAt: '',
          imageUrl: '',
          bio: '',
          website: '',
          location: '',
          meedPointsBalance: '',
          tokens: '',
          twitch: ''
        }
      }
    });

    // Firebase sign out
    await signOut(auth);

  } catch (error) {
    console.error("Error during logout:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

  } finally {
    dispatch({ type: SET_UNAUTHENTICATED });

    if (isForced && history) {
      history.push('/login');
    }
  }
}

/**
 * Fetches the user data.
 * 
 * This function attempts to fetch user-specific data when a user is logged in.
 * 
 * @returns {Function} A dispatch function
 */
export const getUserData = () => async (dispatch) => {
  // Dispatch the loading state for the user data.
  dispatch({ type: LOADING_USER });

  try {
    // Attempt to fetch the user data from the backend.
    const res = await axios.get('/user', { withCredentials: true });
    if (res && res.data) {
      // Dispatch the user data to the store.
      dispatch({
        type: SET_USER,
        payload: res.data
      });
    }
  } catch (err) {
    console.error('Error occurred while fetching user data:', err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    }

    throw err;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Asynchronously fetches refreshed user data from the server and dispatches actions based on the fetched data.
 * 
 * The function listens for changes in the user's Firebase authentication state. If the user is authenticated, 
 * a GET request is made to the `/userRefresh` endpoint to retrieve the latest user data.
 * 
 * Depending on the returned data, actions are dispatched to either indicate that the user data has been loaded 
 * or to update the user data in the store.
 *
 * If any error occurs during this process, it will be logged and can be extended to handle or notify the error state.
 *
 * @function
 * @async
 *
 * @returns {Function} A Redux thunk that dispatches actions based on the state of the fetched user data.
 *
 * @example
 * dispatch(getUserRefreshedData());
 */
export const getUserRefreshedData = () => async (dispatch) => {
  try {
    // Dispatch the loading state for the user data.
    dispatch({ type: LOADING_USER });

    // Listen for changes in the user's authentication state.
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        // No user is authenticated at the moment.
        console.log("No user authenticated.");
        return;
      }

      // Attempt to fetch the refreshed user data from the backend.
      const res = await axios.get('/userRefresh');

      if (res.data && res.data.message && res.data.message === 'No new notifications') {
        // No new notifications found.
        dispatch({ type: LOADED_USER });
      } else {
        // Dispatch the refreshed user data to the store.
        dispatch({
          type: SET_USER,
          payload: res.data
        });
      }
    });
  } catch (error) {
    console.error("Error while fetching refreshed user data:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Asynchronously fetches refreshed user data and dispatches relevant actions based on the fetched data.
 * 
 * A GET request is made to the '/userRefresh' endpoint. Depending on the returned data, various actions might be dispatched.
 *
 * @returns {Function} A Redux thunk that dispatches actions based on the state of the fetched user data.
 */
export const getUserDataReturned = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });

    const res = await axios.get('/userRefresh');

    if (res.data && res.data.message && res.data.message === 'No new notifications') {
      // No new notifications
    } else if (res.data) {
      dispatch({ type: SET_USER, payload: res.data });

      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching user data:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // If the error doesn't match any known error in the ERROR_MAPPING
        dispatch({ type: SET_ERRORS, payload: error.response.data });
      }
    } else {
      // If there's no specific error message in the response, use the general error
      dispatch({ type: SET_ERRORS, payload: error });
    }

    throw error; // Optionally throw the error if you'd like to propagate it
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Fetches the next set of challenges requested by a user.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreRequestedChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.post(`/getNextChallengesRequested/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch requested challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching requested challenges:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // Example:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Fetches the next set of challenges sent by a user.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreSentChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.post(`/getNextChallengesCreated/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch sent challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching sent challenges:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // Example:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Fetches the next set of challenges that the user has completed.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreHistoricChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.post(`/getNextChallengesCompleted/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch historic challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching historic challenges:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // Example:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Fetches the next set of challenges the user has voted on.
 *
 * @async
 * @function
 * @param {string} handle - The user's unique handle.
 * @param {string|number} key1 - The first key for pagination/filtering.
 * @param {string|number} key2 - The second key for pagination/filtering.
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched data.
 * @throws Will throw an error if the network request fails.
 */
export const moreVotedChallenges = (handle, key1, key2) => async (dispatch) => {
  try {
    const response = await axios.post(`/getNextChallengesVoted/${handle}`, { key1, key2 });
    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch voted challenges.');
    }
  } catch (error) {
    console.error("Error occurred while fetching more voted challenges:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // Example:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Asynchronously fetches user profile data from the server based on the provided handle and the authentication state.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store or the 'authed' parameter), 
 * the profile data is fetched from the `/userAuth` endpoint. 
 * If the user isn't authenticated, the profile data is fetched from the `/user` endpoint.
 *
 * @param {string} handle - The user's handle to fetch the profile data for.
 * @param {boolean} [authed=false] - Optionally, a flag indicating if the user is considered authenticated outside of Firebase auth state. Defaults to false.
 * @returns {Promise<object>} A promise that resolves with the user's profile data or an error object.
 */
export const getUserProfileData = (handle, authed = false) => async (dispatch, getState) => {
  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;
    // Determine if the user is authenticated based on Firebase user or the 'authed' flag
    const isAuthenticated = firebaseUser || authed;

    let res;
    if (isAuthenticated) {
      // Fetch data from `/userAuth` endpoint if the user is authenticated
      res = await axios.get(`/userAuth/${handle}`);
    } else {
      // Fetch data from `/user` endpoint if the user is not authenticated
      res = await axios.get(`/user/${handle}`);
    }

    return res.data || {};

  } catch (error) {
    console.error("Error fetching user profile data:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // Example:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Asynchronously fetches user queue data from the server based on the provided handle.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store), 
 * the queue data is fetched from the `/userAuthQueue` endpoint. 
 * If the user isn't authenticated, the queue data is fetched from the `/userQueue` endpoint.
 *
 * @param {string} handle - The user's handle to fetch the queue data for.
 * @returns {Promise<object>} A promise that resolves with the user's queue data or an error object.
 */
export const getUserQueueData = (handle) => async (dispatch, getState) => {
  try {
    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;

    let res;
    // If the user is authenticated, fetch data from `/userAuthQueue` endpoint
    if (firebaseUser) {
      res = await axios.get(`/userAuthQueue/${handle}`);
    } else {
      // If the user isn't authenticated, fetch data from the `/userQueue` endpoint
      res = await axios.get(`/userQueue/${handle}`);
    }

    return res.data || {};

  } catch (error) {
    console.error("Error fetching user queue data:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Fetches Meed point payouts.
 *
 * @async
 * @function
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched payout data.
 * @throws Will throw an error if the network request fails.
 */
export const getMeedPointPayouts = () => async (dispatch) => {
  try {
    const response = await axios.get('/getMeedPointPayouts');

    if (response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch Meed point payouts.');
    }

  } catch (error) {
    console.error("Error occurred while fetching Meed point payouts:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Fetches Meed point purchases.
 *
 * @async
 * @function
 * @param {function} dispatch - Redux's dispatch function.
 * @returns {Promise<Object>} The fetched purchase data.
 * @throws Will throw an error if the network request fails.
 */
export const getMeedPointPurchases = () => async (dispatch) => {
  try {
    const response = await axios.get('/getMeedPointPurchases');

    if (response && response.data) {
      return response.data;
    } else {
      console.error('No data found in response:', response);
      throw new Error('Failed to fetch Meed point purchases.');
    }
  } catch (error) {
    console.error("Error occurred while fetching Meed point purchases:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Uploads a user image.
 *
 * @param {Object} formData - The data for the image to be uploaded.
 * @returns {Function} A redux thunk action.
 */
export const uploadImage = (formData) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    await axios.post('/user/image', formData);
    dispatch(getUserData());
  } catch (error) {
    console.error("Error uploading image:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Edits the details of a user.
 *
 * @param {Object} userDetails - The new details for the user.
 * @returns {Function} A redux thunk action that returns the server's response on success.
 */
export const editUserDetails = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  try {
    const response = await axios.post('/user', userDetails);

    if (response && response.data) {
      dispatch(getUserData());
      return response.data;
    } else if (response) {
      // console.log("Response received without expected data:", response);
      return response;
    }
  } catch (error) {
    console.error("Error editing user details:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Sends user details to the server via the `/contact` endpoint.
 * 
 * If the user is authenticated (determined by the Firebase auth state in Redux store), 
 * the user's Firebase UID is appended to the userDetails object.
 *
 * @param {object} userDetails - Details of the user making the contact request.
 * @returns {Promise<object|null>} A promise that resolves with the server's response data or null if an error occurs.
 */
export const contactUs = (userDetails) => async (dispatch, getState) => {
  try {
    // Dispatch the loading user action
    dispatch({ type: LOADING_USER });

    // Accessing the Redux store to get the firebaseUser
    const firebaseUser = getState().user.firebaseUser;

    // If the user is authenticated, add their Firebase UID to the userDetails object
    if (firebaseUser) {
      userDetails.uid = firebaseUser.uid;
    }

    // Make a POST request to the `/contact` endpoint with the userDetails
    const res = await axios.post('/contact', userDetails);

    if (res) {
      return res;
    }

  } catch (error) {
    console.error("Error while contacting:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error; // Throwing the error after logging and dispatching the appropriate error action.
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Reports a user by their ID.
 *
 * @param {string} userId - The ID of the user to report.
 * @returns {Function} A redux thunk action that returns the server's response on successful reporting.
 */
export const reportUser = (userId) => async (dispatch) => {
  try {
    const response = await axios.post(`/reportUser`, { userId });  // Assuming userId should be in an object format in the POST request

    if (response && response.data) {
      dispatch({
        type: REPORT_CHALLENGE,
        payload: response.data
      });
      return response.data;
    }
  } catch (error) {
    console.error("Error reporting user:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Follows a user based on their details.
 *
 * @param {Object} userDetails - The details of the user to follow.
 * @returns {Function} A redux thunk action that returns the server's response upon successful following.
 */
export const follow = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/follow', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }

  } catch (error) {
    console.error("Error following user:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Unfollows a user based on their details.
 *
 * @param {Object} userDetails - The details of the user to unfollow.
 * @returns {Function} A redux thunk action that returns the server's response upon successful unfollowing.
 */
export const unfollow = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/unfollow', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error unfollowing user:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Ban a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to ban.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const ban = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/ban', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error occurred while banning user:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Unban a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to unban.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */

export const unban = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/unban', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error("Error occurred while unbanning user:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Assigns moderator privileges to a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to be granted mod privileges.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the full response (as the original function returns the entire response for `mod`).
 */
export const mod = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/mod', userDetails);

    if (response) {
      dispatch(getUserRefreshedData());
      return response; // returning the full response as was done in the original function.
    }
  } catch (error) {
    console.error('Error occurred while granting moderator privileges:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * TODO: Do we need to use getUserRefreshedData here?
 * 
 * Updates moderator privileges for an existing moderator using a POST request.
 * 
 * @param {Object} modDetails - Details of the moderator whose privileges are to be updated.
 * @returns {Promise<Object>} - A promise that resolves with the full response.
 */
export const updateModLevel = (modDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/updateModLevel', modDetails);

    if (response) {
      dispatch(getUserRefreshedData());
      return response; // returning the full response as was done in the original function.
    }
  } catch (error) {
    console.error('Error occurred while updating moderator privileges:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
};

/**
 * Revokes moderator privileges from a user based on the provided details.
 * 
 * @param {Object} userDetails - Details of the user to revoke mod privileges from.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const unmod = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/unmod', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while revoking moderator privileges:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Makes a purchase of Meed points for a user.
 * 
 * @param {Object} userDetails - Details of the user making the purchase.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const purchaseMeedPoints = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/user/buyMeedPoints', userDetails);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while purchasing Meed points:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Applies meed points to the user and dispatches actions to fetch refreshed user data.
 * @param {Object} userDetails - The details of the user applying the meed points.
 * @returns {Promise} - A promise that resolves with the server's response or rejects with an error.
 */
export const fulfillMeedPoints = (userDetails) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  try {
    const response = await axios.post('/user/applyMeedPointPurchase', userDetails);

    if (response.data) {
      dispatch(getUserRefreshedData());
    }

    return response.data;
  } catch (error) {
    console.error('Error occurred while fulfilling Meed points:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Marks notifications as read on the server.
 *
 * @param {Array} notificationIds - Array of notification IDs to be marked as read.
 * @returns {Function} Redux Thunk function.
 */
export const markNotificationsRead = (notificationIds) => async (dispatch) => {
  try {
    await axios.post('/notifications', notificationIds);
    dispatch({ type: MARK_NOTIFICATIONS_READ });
  } catch (err) {
    console.error("Error marking notifications as read:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    }

    throw err;
  }
};

/**
 * Unlinks Twitch from the user's account.
 *
 * @returns {Function} Redux Thunk function.
 */
export const unlinkTwitch = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const res = await axios.get('/unlinkTwitch');

    if (res && res.data) {
      dispatch(getUserRefreshedData());
      return res.data;
    }
  } catch (err) {
    console.error("Error unlinking Twitch:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    }

    throw err;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Initiates a transfer to a connected account and fetches refreshed user data upon successful transfer.
 * 
 * This function dispatches the `LOADING_USER` action before initiating the transfer.
 * Once the transfer is complete, it fetches refreshed user data by dispatching the `getUserRefreshedData` action.
 *
 * @returns {Promise<object|null>} A promise that resolves with the server's response data upon successful transfer or null if an error occurs.
 * @throws {Error} Throws an error if there's an issue with the axios request.
 */
export const transferToConnectedAccount = () => async (dispatch) => {
  // try {
  //   dispatch({ type: LOADING_USER });
  //   return await axios
  //     .get('/transferToConnectedAccount')
  //     .then((res) => {
  //       if (res && res.data) {
  //         dispatch(getUserRefreshedData());
  //         return res.data;
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // } catch(error) {

  // }
};

/**
 * Initiates the Stripe connection process.
 * 
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const stripeConnect = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.get('/stripeConnect');

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while initiating Stripe connection:', error);

    // If error message indicates CSRF token issue, force a logout.
    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        //dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Initiates the Stripe connection process for the dashboard.
 * 
 * @param {string} id - The id for the Stripe connection.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the response data.
 */
export const stripeConnectDash = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const response = await axios.post('/stripeConnectDash', id);

    if (response && response.data) {
      dispatch(getUserRefreshedData());
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while initiating Stripe dashboard connection:', error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        //dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Authenticates the application with Twitch.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Twitch authentication.
 * @param {string} auth.redirectUri - The redirect URI for Twitch authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<Object>} - A promise that resolves with the authentication status and message.
 */
export const AuthenticateWithTwitch = (auth) => async (dispatch) => {
  try {
    const { clientId, redirectUri } = auth;
    const codeUri =
      `https://id.twitch.tv/oauth2/authorize?` +
      `client_id=${clientId}&` +
      `redirect_uri=${redirectUri}&` +
      `response_type=code&` +
      `scope=user_read`;

    const code = await getCode(codeUri);

    if (!code) {
      console.error("No code received from Twitch.");
      return { status: false, message: 'Failed to authenticate with Twitch.' }
    }

    // Authorization retrieved, make BE call
    const twitchAuthResponse = await dispatch(getAuthTokenTwitch(code));
    // Handle the response based on the action dispatched by getAuthTokenTwitch
    if (twitchAuthResponse.message) {
      return { status: true, message: twitchAuthResponse.message };
    } else {
      console.error("Failed to process Twitch authentication.");
      return { status: false, message: 'Failed to process Twitch authentication.' };
    }
  } catch (error) {
    console.error('Error occurred while authenticating with Twitch:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
    }

    throw error;
  }
};

/**
 * Authenticates the application with Streamlabs.
 * @warning This has some weird structure for how it returns data. Recommend investigating how it returns
 * info and how the rest of the application handles that info.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Streamlabs authentication.
 * @param {string} auth.redirectUri - The redirect URI for Streamlabs authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<string>} - A promise that resolves with the authentication status ("linked" or "not linked").
 */
export const AuthenticateWithStreamlabs = (auth) => async (dispatch) => {
  try {
    const { clientId, redirectUri } = auth;
    const codeUri =
      `https://streamlabs.com/api/v2.0/authorize?` +
      `client_id=${clientId}&` +
      `redirect_uri=${redirectUri}&` +
      `response_type=code&` +
      `scope=alerts.create+alerts.write`;

    const code = await getCode(codeUri);

    if (!code) {
      return { success: false };  // No code obtained
    }
    const tokenResponse = await getAuthTokenStreamlabs(code)(dispatch);
    console.log({tokenResponse});

    return tokenResponse;
  } catch (error) {
    console.error('Error occurred while authenticating with Streamlabs:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
    }

    throw error;
  }
};

/**
 * Authenticates the application with Discord.
 * @warning This has some weird structure for how it returns data. Recommend investigating how it returns
 * info and how the rest of the application handles that info.
 * 
 * @param {Object} auth - The authentication parameters.
 * @param {string} auth.clientId - The client ID for Discord authentication.
 * @param {string} auth.redirectUri - The redirect URI for Discord authentication.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Promise<string>} - A promise that resolves with the authentication status ("linked" or "not linked").
 */
export const AuthenticateWithDiscord = (auth) => async (dispatch) => {
  try {
    const { clientId, redirectUri } = auth;
    const codeUri =
      `https://discord.com/api/oauth2/authorize?client_id=1044064242598412359&redirect_uri=https%3A%2F%2Fmeed.app%2Fauth%2Fdiscord&response_type=code&scope=identify%20email`;

    const code = await getCode(codeUri);

    if (code) {
      await dispatch(getAuthTokenDiscord(code)); // Ensure getAuthTokenDiscord is awaited and dispatched
      return 'linked';
    } else {
      return 'not linked';
    }
  } catch (error) {
    console.error("Error occurred while authenticating with Discord:", error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
      }
    }

    return 'not linked';
  }
};

/**
 * Unlinks Discord from the user's account.
 *
 * @returns {Function} Redux Thunk function.
 */
export const unlinkDiscord = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const res = await axios.get('/unlinkDiscord');

    if (res && res.data) {
      dispatch(getUserRefreshedData());
      return res.data;
    }
  } catch (err) {
    console.error("Error unlinking Discord:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
      }
    }
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
};

/**
 * Unlinks Streamlabs from the user's account.
 *
 * @returns {Function} Redux Thunk function.
 */
export const unlinkStreamlabs = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER });
    const res = await axios.get('/unlinkStreamlabs');

    if (res && res.data) {
      dispatch(getUserRefreshedData());
      return res.data;
    }
  } catch (err) {
    console.error("Error unlinking Streamlabs:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    }

    throw err;
  } finally {
    // Dispatch LOADED_USER in the finally block to ensure it runs regardless of success or error
    dispatch({ type: LOADED_USER });
  }
}

/**
 * Opens a new window with the given URI to retrieve an authorization code.
 * Continuously polls the new window's URL every 500ms to capture the code 
 * appended as a query parameter.
 * 
 * @param {string} uri - The authentication URI which, when accessed, will provide an auth code in the URL.
 * @returns {Promise<string>} - A promise that resolves with the authorization code if successful.
 * 
 * @recommendations
 * - **Error Handling**: Enhance the error handling mechanism to reject the promise in cases of failure. 
 *   Consider checking if the user closes the auth window manually, and then reject the promise.
 *   A timeout or a maximum number of attempts can be beneficial.
 * 
 * - **Polling Strategy**: Relying on polling every half-second might not be the most efficient. 
 *   Consider using techniques like `window.postMessage` if the third-party authentication service supports it. 
 *   Remember to clear the interval when it's no longer needed to prevent potential memory leaks.
 * 
 * - **Parsing**: The use of `JSON.parse` and `JSON.stringify` for parsing the URL seems redundant. 
 *   Consider simplifying the URL extraction using just `substring` or other string manipulation methods.
 */
const getCode = (uri) => {
  return new Promise((resolve, reject) => {
    const authWindow = window.open(
      uri,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,width=700,height=700"
    );

    let url;

    setInterval(async () => {
      try {
        url = authWindow && authWindow.location && authWindow.location.search;
      } catch (e) { }
      if (url) {
        const parsedCode = JSON.parse(JSON.stringify(url.substring(1)));
        const urlParams = new URLSearchParams(parsedCode);
        const code = urlParams.get('code')
        if (authWindow && code) {
          authWindow.close();
          resolve(code);
        }
      }
    }, 500);
  });
};

/**
 * Retrieves an authentication token from Twitch by sending the received code.
 *
 * @param {string} code - The authorization code from Twitch.
 * @returns {Promise<string>} - A promise that resolves with the access token if successful.
 * @throws Will throw an error if no token is found in the response or if any other error occurs.
 */
const getAuthTokenTwitch = (code) => async (dispatch) => {
  try {
    const response = await axios.post('/twitchAuthUserWithToken', { code });

    // Instead of expecting a token, handle based on the response data
    if (response && response.data) {
      // Dispatch an action based on the success of the Twitch auth
      // For example, you could have actions like TWITCH_LINK_SUCCESS, TWITCH_ALREADY_LINKED, etc.
      if (response.data.alreadyLinked) {
        dispatch({ type: 'TWITCH_ALREADY_LINKED', payload: response.data });
      } else {
        dispatch({ type: 'TWITCH_LINK_SUCCESS', payload: response.data });
      }
      return response.data; // Return the whole response data for further processing
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format from Twitch auth.');
    }
  } catch (error) {
    console.error('Error occurred while getting access token:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
};

/**
 * Sends the received code to Streamlabs for user authentication.
 * @warning Code is somewhat strange, since it doesn't return anything. Worth investigating usage more.
 * @param {string} code - The authorization code from Streamlabs.
 */
const getAuthTokenStreamlabs = (code) => async (dispatch) => {
  try {
    console.log("GetAuthTOkenStreamlabs");
    const response = await axios.post('/streamlabsAuthUserWithToken', { code });

    // If you have any specific logic to handle the response, you can add it here.
    return response;
  } catch (error) {
    console.error('Error occurred while authenticating with Streamlabs:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  }
};

/**
 * Sends the received code to Discord for user authentication.
 *
 * @param {string} code - The authorization code from Discord.
 * @returns {Promise<void>} - A promise that resolves if the post is successful.
 * @throws Will throw an error if the request fails.
 */
const getAuthTokenDiscord = (code) => async (dispatch) => {
  try {
    await axios.post('/discordAuthUserWithToken', { code });
    // If you have any specific logic to handle the response, you can add it here.
  } catch (error) {
    console.error('Error occurred while authenticating with Discord:', error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance:
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
};

/**
 * Sets the authorization header for axios requests and stores the token in localStorage.
 *
 * @param {string} token - The token to be set in the Authorization header.
 */
const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};