import React, { Component, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Challenge from './Challenge';
import ChallengeSkeleton from '../../util/ChallengeSkeleton';

import { connect } from 'react-redux';
import { 

  getAcceptingUsers, 
  getCompletedChallenges, 
  showNav, 
  hideNav 
} from '../../redux/actions/dataActions';

import { withStyles } from '@mui/styles';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import searchStyles from './styles/searchStyles';

const auth = getAuth();
const algoliaClient = algoliasearch(
  'NGAO27P2B5',
  'cb398cda25d3e47c5d89065f53de82cd'
);
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

class SearchChallenge extends Component {
  state = {
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    searchChall: true,
    socialName: '',
  }
  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({
          socialName: user.displayName
        });
      }
    })
  }

  render() {
    const { loading } = this.props.data;
    const {
      classes,
      // user: {
      //  credentials: { handle, createdAt, imageUrl, bio, website, location, twitch, mods, banned },
      //  authenticated
      // }
    } = this.props;
    let Hit = !loading ? (
      (challenge) => (
        challenge.hit.influencerAtName === this.props.userHandle ?
          <Challenge viewChall={this.props.viewChall} spacing='none' searching='true' key={challenge.hit.challengeId} challenge={challenge.hit} />
          :
          <div></div>
      )
    ) : (
      <ChallengeSkeleton />
    );

    return (
      <Fragment>
        <div style={{ paddingTop: '50px 32px' }} className={classes.challWrap}>
          <Grid className={classes.challs} container>
            <div className={classes.challsBox}>
              <InstantSearch searchClient={searchClient} indexName="dev_CHALLENGES">
                <SearchBox className={classes.searchInput} />
                <h2 className={classes.homeTitle}>Search Results</h2>
                <Hits className={classes.results} hitComponent={Hit} />
              </InstantSearch>
            </div>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

SearchChallenge.propTypes = {
  getAcceptingUsers: PropTypes.func.isRequired,
  getCompletedChallenges: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  authenticated: state.user.authenticated,
  data: state.data
});

export default connect(
  mapStateToProps,
  { getCompletedChallenges, getAcceptingUsers, hideNav, showNav }
)(withStyles(searchStyles)(SearchChallenge));
