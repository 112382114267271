import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { format } from "date-fns";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import algoliasearch from "algoliasearch/lite";
import CircularProgress from "@mui/material/CircularProgress";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
// MUI
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/layout/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// Icons
//Redux
import { connect } from "react-redux";
import {
  logoutUser,
  mod,
  ban,
  unban,
  unmod,
  AuthenticateWithTwitch,
  AuthenticateWithStreamlabs,
  AuthenticateWithDiscord,
  unlinkTwitch,
  unlinkDiscord,
  unlinkStreamlabs,
  stripeConnect,
  transferToConnectedAccount,
  stripeConnectDash,
  editUserDetails,
  linkEmail,
  updateModLevel,
} from "../redux/actions/userActions";
import Challenge from "../components/challenge/Challenge";
import ChallengeSkeleton from "../util/ChallengeSkeleton";
import { toast } from "react-toastify";
import settingsStyles from "./styles/settingsStyles";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getBanLevelDescription } from "../util/banLevelValues";

const analytics = getAnalytics();
const auth = getAuth();
const authedUser = auth.currentUser;

// import followIcon from '../../images/plus.png';

const algoliaClient = algoliasearch(
  "NGAO27P2B5",
  "cb398cda25d3e47c5d89065f53de82cd"
);
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const searchClient2 = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

class Settings extends Component {
  // TOOD: Prune this to the needed values.
  state = {
    matches: window.matchMedia("(min-width: 768px)").matches,
    username: "",
    bio: "",
    socialName: "",
    socialImg: "",
    selectBan: "",
    selectUnban: "",
    banLevel: "30min",
    selectMod: "",
    selectUnmod: "",
    tabValue: 0,
    mods: [],
    editingModIndex: null, // Index of the mod currently being edited
    newModLevel: "",
    modLevel: "Basic",
    tempModLevel: "", // Temporary variable to hold the selected level before saving
    selectedMod: null,
    showModPanel: false,
    showBanPanel: false,
    agreeMod: false,
    agreeBan: false,
    open: false,
    openDetails: false,
    openUpdate: false,
    openLabs: false,
    openDiscord: false,
    emailVerified: false,
    sentVerification: false,
    page: 0,
    rowsPerPage: 5,
    hideResults: false,
    hideResults2: false,
    errors: {},
    // Values for profile? (Shouldn't be needed...)
    boostAlertsActive: this.props.user.credentials.boostAlertsActive ?? false,
    meedBot: this.props.user.credentials.meedBotEnabled ?? false,
    createAlertsActive: this.props.user.credentials.createAlertsActive ?? false,
  };

  handleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenLabs = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openLabs: true });
  };
  handleCloseLabs = () => {
    this.setState({ openLabs: false });
  };

  handleOpenDiscord = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openDiscord: true });
  };
  handleCloseDiscord = () => {
    this.setState({ openDiscord: false });
  };

  handleOpenDetails = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openDetails: true });
  };
  handleCloseDetails = () => {
    this.setState({ openDetails: false });
  };

  handleOpenUpdate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ openUpdate: true });
  };

  handleCloseUpdate = () => {
    this.setState({ openUpdate: false });
  };

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  };

  // For the section on the right side of the screen?
  handleModLevelChange = (event, index) => {
    this.setState({
      newModLevel: event.target.value.toLowerCase(), // Store in lowercase
    });
  };

  handleBanLevelChange = (event) => {
    this.setState({ banLevel: event.target.value });
  };

  // For the table row?
  handleTempModLevelChange = (event) => {
    this.setState({ tempModLevel: event.target.value.toLowerCase() }); // Store in lowercase
  };

  /**
   * Cancels the editing operation.
   * This method is triggered when the "Cancel" button is clicked while editing a moderator's level.
   * It resets the component state, exiting the editing mode without saving any changes.
   */
  handleCancelEdit = () => {
    this.setState({ editingModIndex: null, tempModLevel: "" });
  };

  /**
   * Handles the action to switch the table row into edit mode.
   * This method is triggered when the "Edit" button is clicked for a moderator.
   * It sets the state to reflect the currently editing moderator's details,
   * allowing the user to change the moderator's level.
   *
   * @param {number} index - The index of the row in the table corresponding to the moderator.
   * @param {Object} mod - The moderator object containing details of the moderator being edited.
   */
  handleEditMod = (index, mod) => {
    this.setState({
      editingModIndex: index,
      tempModLevel: mod.level.toLowerCase(), // Store the level in lowercase
      selectedMod: mod, // Store the entire mod object
    });
  };

  /**
   * Submits the updated moderator level.
   * This method is triggered when the "Save" button is clicked after editing a moderator's level.
   * It sends the updated level to the backend and handles the response,
   * updating the component state to reflect the changes and exit the editing mode.
   */
  handleSubmitModLevelChange = async () => {
    const { selectedMod, tempModLevel } = this.state;
    console.log({ selectedMod, tempModLevel });
    if (selectedMod) {
      const modDetails = {
        modId: selectedMod.userId, // Assuming `userId` is the correct identifier
        newLevel: tempModLevel,
      };

      try {
        await this.props.updateModLevel(modDetails);
        // Handle successful update
        this.setState({
          editingModIndex: null,
          newModLevel: tempModLevel,
          tempModLevel: "",
          selectedMod: null,
        });
      } catch (error) {
        // Handle error
        console.error("Error updating mod level:", error);
      }
    }
  };

  searchMeed = () => {};

  showModPanel = () => {
    console.log(this.state.showModPanel);
    this.setState({ showModPanel: !this.state.showModPanel });
  };

  showBanPanel = () => {
    this.setState({ showBanPanel: !this.state.showBanPanel });
  };

  hidePanels = () => {
    this.setState({
      showBanPanel: false,
      showModPanel: false,
    });
  };

  selectMod = async (user) => {
    await this.setState({
      selectedMod: user,
      hideResults: true,
    });
  };

  selectUnmod = async (user) => {
    await this.setState({
      selectedUnmod: user,
      hideResults2: true,
    });
    this.handleUnmod();
  };

  showResults = async (user) => {
    await this.setState({ hideResults: false });
  };

  showResults2 = async (user) => {
    await this.setState({ hideResults2: false });
  };

  selectBan = (user) => {
    this.setState({
      selectedBan: user,
      hideResults2: true,
    });
  };

  selectUnban = async (user) => {
    await this.setState({
      selectedUnban: user,
      hideResults2: true,
    });
    this.handleUnban();
  };

  handleCheckMod = (event) => {
    this.setState({ agreeMod: !this.state.agreeMod });
  };

  handleCheckBan = (event) => {
    this.setState({ agreeBan: !this.state.agreeBan });
  };

  handleSubmitMod = async (event) => {
    event.preventDefault();
    const modDetails = {
      handle: this.state.selectedMod,
      level: this.state.modLevel,
    };

    try {
      await this.props.mod(modDetails);
      toast.success("SUCCESS: Mod submitted!");
    } catch (err) {
      toast.error("Failed: Mod not submitted.");
    }
    logEvent(analytics, "mod_user", {
      currentUserHandle: this.props.user.authenticated
        ? this.props.user.credentials.handle
        : "anon",
    });
  };

  handleUnmod = async (event) => {
    try {
      const res = await this.props.unmod({ uid: this.state.selectedUnmod });
      if (res.message === "User unmodded") {
        toast.success("SUCCESS: unmod submitted!");
      } else {
        toast.error("Failed: unmod not submitted.");
      }
    } catch (err) {
      toast.error("Failed: unmod not submitted.");
    }
    logEvent(analytics, "unmod_user", {
      currentUserHandle: this.props.user.authenticated
        ? this.props.user.credentials.handle
        : "anon",
    });
  };

  handleSubmitBan = async (event) => {
    event.preventDefault();
    try {
      const banDetails = {
        handle: this.state.selectedBan,
        // TODO: Switch to using the submitting user's ID (influencer still uses handle because of legacy decisions)
        influencerHandle: this.props.user.credentials.handle,
        level: this.state.banLevel, // Include the selected ban level
      };

      const res = await this.props.ban(banDetails);
      // Check the success boolean in the response
      if (res.success) {
        toast.success("SUCCESS: User Banned!");
      } else {
        toast.error(`Failed: ${res.error}`);
      }
    } catch (err) {
      toast.error("Failed: ban not submitted.");
      console.error(err);
    }
    logEvent(analytics, "ban_user", {
      currentUserHandle: this.props.user.authenticated
        ? this.props.user.credentials.handle
        : "anon",
      banLevel: this.state.banLevel, // Optionally log the ban level for analytics
    });
  };

  handleUnban = async (event) => {
    try {
      // TODO: Switch to using the submitting user's ID (influencer still uses handle because of legacy decisions)
      const res = await this.props.unban({
        uid: this.state.selectedUnban,
        influencerHandle: this.props.user.credentials.handle,
      });
      if (res.success) {
        toast.success("SUCCESS: User Unbanned!");
      } else {
        toast.error("Failed: User not unbanned.");
      }
    } catch (err) {
      toast.error("Failed: User not unbanned.");
    }
    logEvent(analytics, "unban_user", {
      currentUserHandle: this.props.user.authenticated
        ? this.props.user.credentials.handle
        : "anon",
    });
  };

  connectStreamlabs = async () => {
    try {
      const res = await this.props.AuthenticateWithStreamlabs({
        clientId: "9be6b7d0-e8b2-4e76-bf1d-e8cf72a92167",
        redirectUri: "https://meed.app/oauth/streamlabs",
      });
  
      // Now checking the success property directly
      if (res.success) {
        toast.success("Streamlabs Account Linked! Refresh to see the changes.");
      } else {
        toast.error(`Failed: Streamlabs Account not Linked. ${res.error ? res.error : ''}`);
      }
    } catch (err) {
      toast.error(`Exception: Failed to link Streamlabs Account. ${err.message}`);
    }
  };  

  // TODO: move these to react env vars
  disconnectStreamlabs = async () => {
    this.handleCloseLabs();
    try {
      const res = await this.props.unlinkStreamlabs();
      if (res && res.message === "Streamlabs account unlinked successfully") {
        toast.success("SUCCESS: Streamlabs account unlinked successfully!");
      } else {
        toast.error("Failed: Streamlabs Account not unlinked.");
      }
    } catch (err) {
      toast.error("Failed: Streamlabs Account not unlinked.");
    }
  };

  // TODO: move these to react env vars and refrsh user
  connectDiscord = async () => {
    try {
      const res = await this.props.AuthenticateWithDiscord({
        clientId: "1044064242598412359",
        redirectUri: "https://meed.app/auth/discord",
      });
      if (res && res === "linked") {
        toast.success("Discord Account Linked! Refresh to see the changes.");
      } else {
        toast.error("Failed: Discord Account not Linked.");
      }
    } catch (err) {
      toast.error("Failed: Discord Account not Linked.");
    }
  };

  // TODO: move these to react env vars
  disconnectDiscord = async () => {
    this.handleCloseDiscord();
    try {
      const res = await this.props.unlinkDiscord();
      if (res && res.message === "Discord account unlinked successfully") {
        toast.success("SUCCESS: Discord account unlinked successfully!");
      } else {
        toast.error("Failed: Discord Account not unlinked.");
      }
    } catch (err) {
      toast.error("Failed: Discord Account not unlinked.");
    }
  };

  // TODO: move these to react env vars and refrsh user
  connectTwitch = async () => {
    try {
      const res = await this.props.AuthenticateWithTwitch({
        clientId: "ldsz98zpapc5tayyp34ypb0rgxds2j",
        redirectUri: "https://meed.app/",
      });
      if (res && res.status === true) {
        toast.success(res.message);
      } else {
        toast.error(res.message || "Failed: Twitch Account not Linked.");
      }
    } catch (err) {
      toast.error(
        "Failed: Twitch Account not Linked. There was an internal error."
      );
    }
  };

  disconnectTwitch = async () => {
    // TODO: move these to react env vars
    this.handleClose();
    try {
      const res = await this.props.unlinkTwitch();
      if (res && res.message === "Twitch account unlinked successfully") {
        toast.success("SUCCESS: Twitch account unlinked successfully!");
      } else {
        toast.error("Failed: Twitch Account not unlinked.");
      }
    } catch (err) {
      toast.error("Failed: Twitch Account not unlinked.");
    }
  };

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleChangeDetails = (event) => {
    const { name, value } = event.target;
    const isBooleanField = [
      "boostAlertsActive",
      "createAlertsActive",
      "meedBot",
    ].includes(name);
    this.setState({
      [name]: isBooleanField ? value === "true" : value,
    });
  };

  htmlDecode = (input) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };
  handleSubmitUpdate = async (event) => {
    let regEx = /^[0-9a-zA-Z]+$/;
    event.preventDefault();

    this.setState({ loading: true });

    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };

    if (newUserData.password !== newUserData.confirmPassword) {
      toast.error("Passwords do not Match");
      this.setState({ loading: false }); // Set loading to false as the process ends here
      return;
    }

    try {
      await this.props.linkEmail(
        newUserData,
        this.props.history,
        this.props.match.params.code || ""
      );
      toast.success("Email Linked and password updated!");
    } catch (err) {
      toast.error("Link failed. Log out, log in and try again please.");
    } finally {
      this.setState({ loading: false });
    }
  };
  handleSubmit = async () => {
    const userDetails = {};
    const regEx = /^[0-9a-zA-Z]+$/;

    if (this.state.bio) {
      userDetails.bio = this.state.bio;
    }
    if (this.state.username) {
      userDetails.handle = this.state.username;
      if (!userDetails.handle.match(regEx)) {
        toast.error(
          "Details not updated, username must not have any special characters."
        );
        return;
      }
    }
    if (this.state.maxchalls) {
      userDetails.maxChallengesPerViewer = Number(this.state.maxchalls);
    }
    if (this.state.creationfee || Number(this.state.creationfee) === 0) {
      userDetails.minChallengeCost = Number(this.state.creationfee);
    }
    if (this.state.minBoostAmount || Number(this.state.minBoostAmount) === 0) {
      userDetails.minBoostAmount = Number(this.state.minBoostAmount);
    }
    if (
      this.state.minCreateAmount ||
      Number(this.state.minCreateAmount) === 0
    ) {
      userDetails.minCreateAmount = Number(this.state.minCreateAmount);
    }
    console.log(
      "states",
      this.state.boostAlertsActive,
      this.state.createAlertsActive,
      this.state.meedBot
    );
    // Convert string values from the form to boolean for 'boostAlertsActive'
    userDetails.boostAlertsActive = this.state.boostAlertsActive === true;

    // Convert string values from the form to boolean for 'createAlertsActive'
    userDetails.createAlertsActive = this.state.createAlertsActive === true;

    // Convert string values from the form to boolean for 'meedBot'
    userDetails.meedBotEnabled = this.state.meedBot === true;
    console.log("states", { userDetails });

    try {
      const res = await this.props.editUserDetails(userDetails);

      if (res && res.message === "Details added successfully") {
        toast.success("SUCCESS: Details Updated!");
        this.setState({
          bio: "",
          username: "",
          maxchalls: "",
          creationfee: "",
          // ... reset other state properties as necessary
        });
      } else {
        toast.error("Failed: Details not updated.");
      }

      if (res && res.error) {
        toast.error(res.error);
      }
    } catch (error) {
      console.error("Error updating details:", error);
      toast.error("An error occurred while updating details.");
    }

    this.handleCloseDetails();
  };

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  transferToConnectedAccount = () => {
    // if (firebase.auth().currentUser && firebase.auth().currentUser.emailVerified) {
    //   this.props.transferToConnectedAccount()
    //   .then((res)=>{
    //     if (res) {
    //       console.log('res: ', res);
    //       toast.success('Nice.');
    //     } else {
    //       console.log('err: ', res)
    //       toast.error('Failure: Stripe connect server error, try again later.');
    //     }
    //   })
    // }
  };
  /**
   * Initiates the connection process with Stripe.
   * This function checks if the current Firebase user is authenticated and their email is verified.
   * If the conditions are met, it attempts to connect with Stripe using the `stripeConnect` prop method.
   * On success, it redirects the user to the URL provided by the Stripe response.
   * In case of failure or if the response does not contain a URL, an error message is displayed.
   *
   * @async
   * @function connectStripe
   */
  connectStripe = async () => {
    if (authedUser && authedUser.emailVerified) {
      try {
        const res = await this.props.stripeConnect();

        if (res && res.url) {
          window.location.href = res.url;
        } else {
          // console.log('err: ', res);
          toast.error("Failure: Stripe connect server error, try again later.");
        }
      } catch (error) {
        // Handle any errors that might occur during the stripeConnect call
        console.log("Error connecting to Stripe:", error);
        toast.error("Failure: Error connecting to Stripe, try again later.");
      }
    }
  };
  /**
   * Handles the Stripe dashboard connection for a specific account.
   * This function first verifies if the current Firebase user is authenticated and their email is verified.
   * If verified, it initiates a connection to the Stripe dashboard for the provided account ID using the `stripeConnectDash` prop method.
   * On successful response with a URL, it redirects the user to that URL.
   * If the response does not contain a URL or if there's an error, an error message is displayed.
   *
   * @async
   * @function stripeConnectDash
   * @param {string} id - The Stripe Connected Account ID for which the dashboard connection is to be established.
   */
  stripeConnectDash = async (id) => {
    if (authedUser && authedUser.emailVerified) {
      try {
        const res = await this.props.stripeConnectDash({
          stripeConnectedAccountId: id,
        });

        if (res && res.url) {
          window.location.href = res.url;
        } else {
          toast.error("Failure: Stripe connect server error, try again later.");
        }
      } catch (error) {
        console.log("Error with Stripe dashboard connection:", error);
        toast.error(
          "Failure: Error connecting to Stripe dashboard, try again later."
        );
      }
    }
  };
  // resend verification email from firebase
  verifyEmail = async () => {
    try {
      await sendEmailVerification(authedUser);
      // Email verification sent!
      this.setState({ sentVerification: true });
      toast.success(`SUCCESS: Email sent to ${authedUser.email}`);
    } catch (error) {
      // Handle error
      toast.error("Failure: Email not sent.");
    }
  };
  /**
   * A comparison function that helps in sorting elements in descending order.
   * It compares two elements based on a specified property.
   *
   * @param {Object} a - The first object to compare.
   * @param {Object} b - The second object to compare.
   * @param {string} orderBy - The property name to use for comparison.
   * @returns {number} - Returns -1 if `a` should come before `b`, 1 if `a` should come after `b`, or 0 if their order should remain unchanged.
   */
  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  // sort table according to params
  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  };
  handleMediaQueryChange = (e) => {
    this.setState({ matches: e.matches });
  };
  // Perform these tasks on page load
  componentDidMount() {
    console.log(
      "settings, this.props.user.credentials",
      this.props.user.credentials
    );
    this.setFormValuesFromProps();
    window.scrollTo(0, 0);
    window
      .matchMedia("(min-width: 768px)")
      .addListener(this.handleMediaQueryChange);
  }

  componentWillUnmount() {
    // Remove the event listener when the component will unmount
    window
      .matchMedia("(min-width: 768px)")
      .removeListener(this.handleMediaQueryChange);
  }

  componentDidUpdate(prevProps) {
    // You can also add more conditions to check specific props if needed
    if (this.props.user.credentials !== prevProps.user.credentials) {
      this.setFormValuesFromProps();
    }
  }

  setFormValuesFromProps() {
    const { credentials } = this.props.user;
    console.log({ credentials });
    this.setState({
      boostAlertsActive: credentials.boostAlertsActive ?? false, // Default to false if undefined
      createAlertsActive: credentials.createAlertsActive ?? false, // Default to false if undefined
      bio: credentials.bio || "",
      username: credentials.displayName || credentials.handle || "",
      creationfee: credentials.minChallengeCost || "",
      maxchalls: credentials.maxChallengesPerViewer || "",
      minBoostAmount:
        credentials.minBoostAmount !== null &&
        credentials.minBoostAmount !== undefined
          ? credentials.minBoostAmount
          : "",
      minCreateAmount:
        credentials.minCreateAmount !== null &&
        credentials.minCreateAmount !== undefined
          ? credentials.minCreateAmount
          : "",
      meedBot: credentials.meedBotEnabled ?? false, // Default to false if undefined
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      classes,
      user: {
        credentials: {
          handle,
          bio,
          minChallengeCost,
          maxChallengesPerViewer,
          minBoostAmount,
          minCreateAmount,
          displayName,
          linkedAccounts,
          meedBotEnabled,
          twitch = {}, // Default to an empty object if twitch is undefined
          discord = {}, // Default to an empty object if discord is undefined
          streamlabs = {}, // Default to an empty object if streamlabs is undefined
          stripeConnectedAccountId = "", // Default to an empty string if stripeConnectedAccountId is undefined
        },
        mods,
        bans,
      },
    } = this.props;
    const { errors } = this.state;
    const { challenges, loading } = this.props.data;
    const Hit = ({ hit }) => (
        <div
          onClick={() => this.selectMod(hit.handle)}
          className={classes.result}
        >
          {hit.handle}
        </div>
      ),
      Hit2 = ({ hit }) => (
        <div
          onClick={() => this.selectBan(hit.handle)}
          className={classes.result}
        >
          {hit.handle}
        </div>
      );
    let thisMods = mods ? mods : [];
    let thisBans = bans ? bans : [];
    let recentChallengesMarkup =
      !loading && challenges ? (
        challenges.map((challenge) => (
          <Challenge key={challenge.challengeId} challenge={challenge} />
        ))
      ) : (
        <ChallengeSkeleton />
      );
    let publicProfileMarkup = this.state.showBanPanel ? (
      <div className={classes.banPanel}>
        <div className={classes.modList}>
          <div className={classes.modListCol1}>
            <div className={classes.modPanelHeader}>
              <div>Ban Management</div>
              <div className={classes.point} onClick={this.hidePanels}>
                Return to Menu
              </div>
            </div>
            <hr></hr>
            <div>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell>Ban Ends</TableCell>
                    <TableCell align="center">User</TableCell>
                    <TableCell align="center">Ban Duration</TableCell>
                    <TableCell align="center">Edit Duration</TableCell>
                    <TableCell align="center">Remove User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {thisBans.map((ban, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {ban && ban.endsAt
                          ? format(
                              new Date(ban.endsAt._seconds * 1000), // Convert Firestore timestamp to JavaScript Date
                              "MMMM do, yyyy h:mma"
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ban.userHandle}
                      </TableCell>
                      <TableCell align="center">
                        {getBanLevelDescription(ban.level)}
                      </TableCell>
                      <TableCell
                        onClick={() => this.selectUnban(ban.userId)}
                        align="center"
                      >
                        <Button className={classes.tableBtn}>Remove</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={thisBans.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
          <div className={classes.searchUser}>
            <form onSubmit={this.handleSubmitBan}>
              <Typography className={classes.whiteText} variant="h5">
                Ban a User
              </Typography>
              <div className={classes.searchInputDad}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName="dev_USERS"
                >
                  <SearchBox
                    className={classes.searchInput}
                    onClick={this.showResults2}
                  />
                  <Hits
                    className={`${classes.results} ${
                      this.state.hideResults2 ? classes.hideResults : ""
                    }`}
                    hitComponent={Hit2}
                  />
                </InstantSearch>
              </div>
              <Typography className={classes.whiteText} variant="body1">
                Select Ban Duration:
              </Typography>
              <select
                value={this.state.banLevel}
                onChange={this.handleBanLevelChange}
                className={classes.selectModLevel} // You might want to adjust the class name to something more general
              >
                <option value="30min">30 Minutes</option>
                <option value="1day">1 Day</option>
                <option value="1week">1 Week</option>
                <option value="1month">1 Month</option>
                <option value="permanent">Permanent</option>
              </select>

              <Typography className={classes.whiteText} variant="body1">
                I am banning this user{" "}
                {this.state.selectedBan ? (
                  <div
                    className={classes.selected}
                  >{`(${this.state.selectedBan})`}</div>
                ) : (
                  ""
                )}{" "}
                from creating challenges for me.
              </Typography>
              <Typography className={classes.whiteText} variant="body1">
                I understand and agree.
                <Checkbox
                  className={classes.checkBox}
                  checked={this.state.agreeBan}
                  onChange={this.handleCheckBan}
                  color="primary"
                  name="Ban Agreement"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={!this.state.agreeBan}
              >
                Ban User
              </Button>
            </form>
          </div>
        </div>
      </div>
    ) : this.state.showModPanel ? (
      <div className={classes.modPanel}>
        <div className={classes.modList}>
          <div className={classes.modListCol1}>
            <div className={classes.modPanelHeader}>
              <div>Mod Management</div>
              <div className={classes.point} onClick={this.hidePanels}>
                Return to Menu
              </div>
            </div>
            <hr></hr>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Mod Level</TableCell>
                  <TableCell align="center">Edit Level</TableCell>
                  <TableCell align="center">Remove User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {thisMods.map((mod, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {mod.createdAt
                        ? format(new Date(mod.createdAt), "MMMM do, yyyy h:mma")
                        : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {["linked", "owner"].includes(mod.level)
                        ? mod.platform
                        : mod.userHandle}
                    </TableCell>
                    <TableCell align="center">{mod.level}</TableCell>
                    <TableCell align="center">
                      {this.state.editingModIndex === index ? (
                        <div>
                          <FormControl
                            className={classes.formControl}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Select
                              value={
                                this.state.tempModLevel
                                  .charAt(0)
                                  .toUpperCase() +
                                this.state.tempModLevel.slice(1)
                              }
                              onChange={this.handleTempModLevelChange}
                              displayEmpty
                            >
                              <MenuItem value="Basic">
                                Basic - Can ban users, flag, and hide
                                challenges.
                              </MenuItem>
                              {/* <MenuItem value="Intermediate">Intermediate - All previous and can create challenges for free.</MenuItem> */}
                              <MenuItem value="Full">
                                Full - All previous and can accept/decline and
                                complete/fail challenges.
                              </MenuItem>
                            </Select>
                            <Button
                              onClick={() =>
                                this.handleSubmitModLevelChange(index)
                              }
                            >
                              Save
                            </Button>
                            <Button onClick={this.handleCancelEdit}>
                              Cancel
                            </Button>
                          </FormControl>
                        </div>
                      ) : (
                        <Button
                          className={classes.tableBtn}
                          onClick={() => this.handleEditMod(index, mod)}
                        >
                          Edit
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() => this.selectUnmod(mod.userId)}
                      align="center"
                    >
                      <Button className={classes.tableBtn}>Remove</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className={classes.searchUser}>
            <form onSubmit={this.handleSubmitMod}>
              <Typography className={classes.whiteText} variant="h5">
                Add a Moderator
              </Typography>
              <div className={classes.searchInputDad}>
                <InstantSearch
                  searchClient={searchClient2}
                  indexName="dev_USERS"
                >
                  <SearchBox className={classes.searchInput} />
                  <Hits
                    className={`${classes.results} ${
                      this.state.hideResults ? classes.hideResults : ""
                    }`}
                    hitComponent={Hit}
                  />
                </InstantSearch>
                {/* <OutlinedInput
                  id="standard-adornment-amount"
                  name='query'
                  variant='outline'
                  className={classes.searchInput}
                  onChange={this.searchMeed()}
                  startAdornment={
                    <InputAdornment position="start">
                      <img
                        src={search}
                        alt="Meed"
                        className={classes.logo}
                      />
                    </InputAdornment>
                  }
                /> */}
              </div>
              <Typography className={classes.whiteText} variant="body1">
                Select Moderator Level:
              </Typography>
              <select
                value={this.state.modLevel}
                onChange={this.handleModLevelChange}
                className={classes.selectModLevel}
              >
                <option value="Basic">
                  Basic - Can ban users, flag, and hide challenges.
                </option>
                <option value="Intermediate">
                  Intermediate - All previous and can create challenges for
                  free.
                </option>
                <option value="Full">
                  Full - All previous and can accept/decline and complete/fail
                  challenges.
                </option>
              </select>
              <Typography className={classes.whiteText} variant="body1">
                I am giving permission to this user{" "}
                {this.state.selectedMod ? (
                  <div
                    className={classes.selected}
                  >{`(${this.state.selectedMod})`}</div>
                ) : (
                  ""
                )}{" "}
                to moderate my content.
              </Typography>
              <Typography className={classes.whiteText} variant="body1">
                I understand and agree.
                <Checkbox
                  className={classes.checkBox}
                  checked={this.state.agreeMod}
                  onChange={this.handleCheckMod}
                  color="primary"
                  name="Mod Agreement"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={!this.state.agreeMod}
              >
                Add Mod
              </Button>
            </form>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.public}>
        <div onClick={this.showModPanel}>Mod Management</div>
        <hr></hr>
        <div onClick={this.showBanPanel}>Banning/Unbanning</div>
        <hr></hr>
        <div onClick={this.handleOpenDetails}>
          Creation Fee Amount: $
          {this.props.user &&
            this.props.user.credentials &&
            this.props.user.credentials.minChallengeCost}
        </div>
        <hr></hr>
        <div onClick={this.handleOpenDetails}>
          Max Open Challenges Per User:{" "}
          {this.props.user &&
            this.props.user.credentials &&
            this.props.user.credentials.maxChallengesPerViewer}
        </div>
        <hr></hr>
        <div onClick={this.handleOpenDetails}>
          Boost Challenge amount to trigger Streamlabs Meed donation alerts:{" "}
          {(this.props.user &&
            this.props.user.credentials &&
            this.props.user.credentials.minBoostAmount) ||
            "$0"}
        </div>
        <hr></hr>
        <div onClick={this.handleOpenDetails}>
          Create Challenge amount to trigger Streamlabs Meed donation alerts:{" "}
          {(this.props.user &&
            this.props.user.credentials &&
            this.props.user.credentials.minCreateAmount) ||
            "$0"}
        </div>
      </div>
    );

    return (
      <Paper className={classes.paper}>
        <div className={classes.profile}>
          <hr />
          <div className="profile-details">
            <AppBar
              className={classes.tabHeader}
              position="static"
              color="default"
            >
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChange}
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabHeader}
                aria-label="scrollable auto tabs example"
              >
                <Tab label="General" {...this.a11yProps(0)} />
                <Tab label="Public Profile" {...this.a11yProps(1)} />
                <Tab label="Security and Privacy" {...this.a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel
              value={this.state.tabValue}
              className={classes.tabPanel}
              index={0}
            >
              <div className={classes.general}>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>Get Paid</div>
                  <div className={classes.generalBoxDesc}>
                    Create a Stripe connected account to receive funds earned on
                    Meed.
                  </div>
                  {!stripeConnectedAccountId ? (
                    <Button
                      disabled={this.state.sentVerification}
                      className={classes.generalBoxBtn}
                      onClick={
                        this.state.emailVerified
                          ? this.connectStripe
                          : this.verifyEmail
                      }
                    >
                      {this.state.sentVerification
                        ? "Sent, now verify & refresh."
                        : this.state.emailVerified
                        ? "Connect with Stripe"
                        : "Verify Email to Connect"}
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        this.stripeConnectDash(stripeConnectedAccountId)
                      }
                      className={classes.generalBoxBtn}
                    >
                      Dashboard
                    </Button>
                  )}
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Change Biography
                  </div>
                  <div className={classes.generalBoxDesc}>
                    {this.props.user &&
                    this.props.user.credentials &&
                    this.props.user.credentials.bio
                      ? `Your current bio is: ${this.htmlDecode(
                          this.props.user.credentials.bio
                        )}`
                      : `For now, the “About” section on your Host User page is automatically filled with the text from your Twitch Bio when the Twitch account is connected.`}
                  </div>
                  <Button
                    className={classes.generalBoxBtn}
                    onClick={this.handleOpenDetails}
                  >
                    Update
                  </Button>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Connect Twitch Account
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Let users know who you are on Twitch.
                  </div>
                  {(twitch && twitch.id) ||
                  (linkedAccounts && linkedAccounts.twitch) ? (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.handleOpen}
                    >
                      Unlink Twitch Account
                    </Button>
                  ) : (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.connectTwitch}
                    >
                      Connect Twitch Account
                    </Button>
                  )}
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>
                      Are you sure you want to unlink your Twitch Account?
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={this.disconnectTwitch} color="secondary">
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Connect Streamlabs Account
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Allow challenges to show up on stream.
                  </div>
                  {Object.keys(streamlabs).length > 0 ? (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.handleOpenLabs}
                    >
                      Unlink Streamlabs Account
                    </Button>
                  ) : (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.connectStreamlabs}
                    >
                      Connect Streamlabs Account
                    </Button>
                  )}
                  <Dialog
                    open={this.state.openLabs}
                    onClose={this.handleCloseLabs}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>
                      Are you sure you want to unlink your Streamlabs Account?
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={this.handleCloseLabs} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={this.disconnectStreamlabs}
                        color="secondary"
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Connect Discord Account
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Allow challenges to show up on stream.
                  </div>
                  {(discord && Object.keys(discord).length) ||
                  (linkedAccounts && linkedAccounts.discord) ? (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.handleOpenDiscord}
                    >
                      Unlink Discord Account
                    </Button>
                  ) : (
                    <Button
                      className={classes.generalBoxBtn}
                      onClick={this.connectDiscord}
                    >
                      Connect Discord Account
                    </Button>
                  )}
                  <Dialog
                    open={this.state.openDiscord}
                    onClose={this.handleCloseDiscord}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>
                      Are you sure you want to unlink your Discord Account?
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={this.handleCloseDiscord} color="primary">
                        Cancel
                      </Button>
                      <Button
                        onClick={this.disconnectDiscord}
                        color="secondary"
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Update Username
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Your current username:{" "}
                    {(this.props.user &&
                      this.props.user.credentials &&
                      this.props.user.credentials.displayName) ||
                      this.props.user.credentials.handle}
                  </div>
                  <Button
                    className={classes.generalBoxBtn}
                    onClick={this.handleOpenDetails}
                  >
                    Update
                  </Button>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>Log Out</div>
                  <div className={classes.generalBoxDesc}>
                    Log out of your account.
                  </div>
                  <Button
                    className={classes.generalBoxBtn}
                    onClick={this.handleLogout}
                  >
                    Log Out
                  </Button>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Disable account
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Disable and delete my account permanently.
                  </div>
                  <Button className={classes.generalBoxBtn}>Disable</Button>
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value={this.state.tabValue}
              className={classes.tabPanel}
              index={1}
            >
              {publicProfileMarkup}
            </TabPanel>
            <TabPanel
              value={this.state.tabValue}
              className={classes.tabPanel}
              index={2}
            >
              <div className={classes.general}>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Link Your E-mail Address
                  </div>
                  <div className={classes.generalBoxDesc}>
                    {" "}
                    If you created your Meed account with a social account, and
                    have not linked an email address, click the button below to
                    link an email address and password to this account.
                  </div>
                  <Button
                    className={classes.generalBoxBtn}
                    onClick={this.handleOpenUpdate}
                  >
                    Link
                  </Button>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Update Password
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Feature coming soon.
                  </div>
                  <Button className={classes.generalBoxBtn}>Update</Button>
                </div>
                <div className={classes.generalBox}>
                  <div className={classes.generalBoxHeader}>
                    Two-Factor Authentication
                  </div>
                  <div className={classes.generalBoxDesc}>
                    Feature coming soon.
                  </div>
                  <Button className={classes.generalBoxBtn}>Add</Button>
                </div>
              </div>
            </TabPanel>
            <hr />

            <br></br>
            {/* { (authenticated) ? (
              <div>
              <Button className={classes.logout} variant="contained" onClick={this.handleLogout}>
                Logout
              </Button>
              <br></br>
              <Button className={classes.logout} variant="contained" onClick={this.connectTwitch}>
              Connect Twitch Account
              </Button>
              </div>
              ) : (<div></div>)
            } */}
          </div>
        </div>
        <Dialog
          open={this.state.openUpdate}
          onClose={this.handleCloseUpdate}
          fullWidth
          maxWidth="sm"
          className={classes.modal}
        >
          <DialogTitle>Link your email address</DialogTitle>
          <DialogContent>
            <form noValidate onSubmit={this.handleSubmitUpdate}>
              <TextField
                variant="standard"
                id="email"
                name="email"
                type="email"
                label="Email to Link"
                className={classes.textField}
                helperText={errors.email}
                error={errors.email ? true : false}
                value={this.state.email}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              <TextField
                variant="standard"
                id="password"
                name="password"
                type="password"
                label="Create Password"
                className={classes.textField}
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              <TextField
                variant="standard"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                className={classes.textField}
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={this.state.confirmPassword}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonDefault}
                disabled={this.state.loading}
              >
                Update
                {this.state.loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openDetails}
          onClose={this.handleCloseDetails}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit your details</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                variant="standard"
                name="bio"
                type="text"
                label="Bio"
                multiline
                rows="3"
                inputProps={{
                  maxLength: 1000,
                }}
                placeholder={bio ? bio : `A short bio about yourself`}
                className={classes.textField}
                value={this.state.bio}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              <TextField
                variant="standard"
                name="username"
                type="text"
                label="Username"
                placeholder={displayName ? displayName : handle}
                className={classes.textField}
                value={this.state.username}
                onChange={this.handleChangeDetails}
                inputProps={{
                  maxLength: 20,
                }}
                fullWidth
              />
              <TextField
                variant="standard"
                name="creationfee"
                type="number"
                label="Min Challenge Creation Fee"
                placeholder={minChallengeCost ? minChallengeCost : `$0`}
                className={classes.textField}
                value={this.state.creationfee}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              <TextField
                variant="standard"
                name="maxchalls"
                type="number"
                label="Max Open Challenges Per User"
                placeholder={
                  maxChallengesPerViewer ? maxChallengesPerViewer : `100`
                }
                className={classes.textField}
                value={this.state.maxchalls}
                onChange={this.handleChangeDetails}
                fullWidth
              />
              <Tooltip
                className={classes.topZ}
                title={
                  "If set to Yes, when a Challenge is Boosted with a dollar amount greater than or equal to the amount you specify an alert will be sent using your Streamlabs' settings for donations. If set to No, then no alert will be sent."
                }
                placement="top"
              >
                <div>Boost Alert Active</div>
              </Tooltip>
              <RadioGroup
                aria-label="Boost Alert Active?"
                name="boostAlertsActive"
                label="Boost Alert Active"
                className={classes.goal}
                value={this.state.boostAlertsActive.toString()} // Handle potential undefined state and convert boolean to string
                onChange={this.handleChangeDetails}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              <TextField
                variant="standard"
                name="minBoostAmount"
                type="number"
                label="Boost Challenge alert fee."
                placeholder={
                  minBoostAmount !== null && minBoostAmount !== undefined
                    ? minBoostAmount.toString()
                    : `$0`
                }
                className={classes.textField}
                value={this.state.minBoostAmount}
                onChange={this.handleChangeDetails}
                disabled={!this.state.boostAlertsActive}
                fullWidth
              />
              <Tooltip
                className={classes.topZ}
                title={
                  "If set to Yes, when a Challenge is created with a dollar amount greater than or equal to the amount you specify an alert will be sent using your Streamlabs' settings for donations. If set to No, then no alert will be sent."
                }
                placement="top"
              >
                <div>Create Alert Active</div>
              </Tooltip>
              <RadioGroup
                aria-label="Create Alert Active?"
                name="createAlertsActive"
                label="Create Alert Active"
                className={classes.goal}
                value={this.state.createAlertsActive.toString()} // Provide a default value before calling toString()
                onChange={this.handleChangeDetails}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              <TextField
                variant="standard"
                name="minCreateAmount"
                type="number"
                label="Create Challenge alert fee."
                // Setting the placeholder for 'minCreateAmount'.
                // If 'minCreateAmount' is not 'null' or 'undefined', display it as the placeholder.
                // Using '.toString()' to convert the number to a string as placeholder expects a string value.
                // If 'minCreateAmount' is 'null' or 'undefined', default to '$0'.
                placeholder={
                  minCreateAmount !== null && minCreateAmount !== undefined
                    ? minCreateAmount.toString()
                    : `$0`
                }
                className={classes.textField}
                value={this.state.minCreateAmount}
                onChange={this.handleChangeDetails}
                disabled={!this.state.createAlertsActive}
                fullWidth
              />
              <div>Allow MeedBot Twitch Chatbot Integration?</div>
              <RadioGroup
                aria-label="Create Alert Active?"
                name="meedBot"
                label="Create Alert Active"
                className={classes.goal}
                value={this.state.meedBot.toString()} // Provide a default value before calling toString()
                onChange={this.handleChangeDetails}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDetails} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  authenticated: state.user.authenticated,
});

const mapActionsToProps = {
  linkEmail,
  logoutUser,
  mod,
  updateModLevel,
  ban,
  unban,
  unmod,
  AuthenticateWithTwitch,
  AuthenticateWithStreamlabs,
  AuthenticateWithDiscord,
  unlinkTwitch,
  unlinkDiscord,
  unlinkStreamlabs,
  stripeConnect,
  stripeConnectDash,
  editUserDetails,
  transferToConnectedAccount,
};

Settings.propTypes = {
  editUserDetails: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  mod: PropTypes.func.isRequired,
  updateModLevel: PropTypes.func.isRequired,
  ban: PropTypes.func.isRequired,
  unmod: PropTypes.func.isRequired,
  unban: PropTypes.func.isRequired,
  linkEmail: PropTypes.func.isRequired,
  AuthenticateWithTwitch: PropTypes.func.isRequired,
  AuthenticateWithStreamlabs: PropTypes.func.isRequired,
  AuthenticateWithDiscord: PropTypes.func.isRequired,
  unlinkTwitch: PropTypes.func.isRequired,
  unlinkDiscord: PropTypes.func.isRequired,
  unlinkStreamlabs: PropTypes.func.isRequired,
  stripeConnect: PropTypes.func.isRequired,
  transferToConnectedAccount: PropTypes.func.isRequired,
  stripeConnectDash: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(settingsStyles)(Settings));
