import React, { Component, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import ChallengeSkeleton from '../../util/ChallengeSkeleton';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import mascot from '../../images/no-img.png';

import { connect } from 'react-redux';
import { getAcceptingUsers, getCompletedChallenges, showNav, hideNav } from '../../redux/actions/dataActions';

import { withStyles } from '@mui/styles';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

import SearchUserStyles from './styles/SearchUserStyles';

const auth = getAuth();
const algoliaClient = algoliasearch(
  'NGAO27P2B5',
  'cb398cda25d3e47c5d89065f53de82cd'
);
const searchClient = {
  ...algoliaClient,
};

class SearchUser extends Component {
  state = {
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    searchChall: true,
    socialName: '',
    searchActive: false
  }
  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    // Assign the media query to a class property
    this.mediaQueryList = window.matchMedia("(min-width: 768px)");

    // Define the handler as a class property
    this.handler = e => this.setState({ matches: e.matches });

    // Add the listener to the media query list
    this.mediaQueryList.addListener(this.handler);

    // Set up the Firebase auth state change listener
    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({
          socialName: user.displayName
        });
      }
    });

    // Add event listener to handle clicks outside the search area
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.handler); // Remove the listener
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  searchActive = async () => {
    if (!this.state.searchActive)
      this.setState({ searchActive: true });
  }
  searchActiveToggle = async () => {
    this.setState({ searchActive: !this.state.searchActive });
  }
  selectedUser = async (handle) => {
    if (this.state.selectedUser === handle) {
      this.setState(
        {
          selectedUser: '',
        }
      )
      this.props.selectUser('')
      return;
    }
    this.props.selectUser(handle);
    this.setState(
      {
        selectedUser: handle,
      }
    )
  }

  /**
   * Set the wrapper reference. This method is used to store a reference to the 
   * component's outer div, which is used to detect clicks outside of it.
   * 
   * @param {HTMLElement} node - The DOM node of the wrapper element.
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }
  /**
   * Handle clicks outside of the search area. If the click is outside the 
   * search area and the search is active, toggle the search area back to its smaller state.
   * 
   * @param {MouseEvent} event - The DOM event triggered by a mouse click.
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // Check if the search is active and if so, toggle it
      if (this.state.searchActive) {
        this.searchActiveToggle();
      }
    }
  }

  /**
   * Renders a card component based on the provided user data.
   * 
   * This method returns a `ChallengeSkeleton` component when the component is in a loading state.
   * If the user data is available and contains the required fields, it renders a `Card` component
   * with the user's information. The card is clickable and will trigger the `selectedUser` method
   * when clicked. If the user data is not available or incomplete, it returns an empty `div`.
   *
   * @param {Object} user - The user object containing information to be displayed.
   * @param {Object} user.hit - The nested object within the user object containing specific user details.
   * @param {string} user.hit.handle - The user's handle or unique identifier.
   * @param {Object} [user.hit.twitch] - Optional object containing Twitch-related information.
   * @param {string} [user.hit.twitch.profile_image_url] - Optional URL of the user's Twitch profile image.
   * @returns {JSX.Element} The JSX element representing the user card or a loading/skeleton component.
   */
  Hit = (user) => {
    const { classes } = this.props;
    const { loading, selectedUser } = this.state;

    // Return a different component if loading
    if (loading) {
      return <ChallengeSkeleton />;
    }

    // Return the card if user and user.hit are defined
    if (user && user.hit && user.hit.handle) {
      return (
        <Card key={user.hit.handle} className={`${classes.userCard} ${user.hit.handle === selectedUser ? classes.active : ''}`} onClick={() => { this.selectedUser(user.hit.handle) }}>
          {
            user.hit.twitch && user.hit.twitch.profile_image_url ?
              <img
                src={user.hit.twitch.profile_image_url}
                alt="profile"
                className={classes.profileImage}
              />
              :
              <img
                src={mascot}
                alt="profile"
                className={classes.profileImage2}
                onClick={this.menuClick}
              />
          }
          <CardContent className={classes.content}>
            <Typography className={classes.hideOverflow} variant="body1">{user.hit.handle}</Typography>
          </CardContent>
        </Card>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <Fragment>
        <div style={{ paddingTop: '50px 32px' }} className='usersWrap' ref={this.setWrapperRef}>
          <Grid className={classes.challs} container>
            <div className={classes.challsBox}>
              <InstantSearch translations={{ placeholder: 'Search for a user on Meed!' }} searchClient={searchClient} indexName="dev_USERS">
                <SearchBox translations={{ placeholder: 'Search for a user on Meed!' }} onClick={this.searchActive} className={classes.searchInput} />
                <div className={classes.searchCategory}>
                  <p>
                    Top Users on Meed
                    <svg className={`${classes.caret} ${this.state.searchActive ? classes.rotate180 : ''}`} onClick={this.searchActiveToggle} xmlns="http://www.w3.org/2000/svg" width="13.596" height="5.725" viewBox="0 0 13.596 5.725">
                      <path id="Path_126" data-name="Path 126" d="M6.8,0l6.8,5.725H0Z" transform="translate(13.596 5.725) rotate(180)" fill="#fff" />
                    </svg>
                  </p>
                </div>
                <Hits className={`${this.state.searchActive ? classes.resultsExpanded : classes.results}`} hitComponent={this.Hit} />
              </InstantSearch>
            </div>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

SearchUser.propTypes = {
  getAcceptingUsers: PropTypes.func.isRequired,
  getCompletedChallenges: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  authenticated: state.user.authenticated,
  data: state.data
});

export default connect(
  mapStateToProps,
  { getCompletedChallenges, getAcceptingUsers, hideNav, showNav }
)(withStyles(SearchUserStyles)(SearchUser));