/**
 * @fileoverview Loading screen component for Meed.
 * This component displays loading indicators and randomized tips.
 * Tips are grouped by categories, though the current implementation
 * defaults to showing tips from all categories. This groundwork allows
 * for future development to display tips based on specific contexts or categories.
 */

import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import meedIconLogo from '../../images/meedIconLogo.png';
import { LoadingContainer, Logo, LoadingText, TipText } from './styles/LoadingScreenStyles';
import { getRandomChallenge } from '../../util/challengeGenerator';

/**
 * Tips categorized by their relevance. 
 * Currently not used for category-specific display but provides
 * a foundation for future implementation.
 */
const tipsByCategory = {
  General: [
    "Use the 'History' tab to track your past challenges and see your progress over time.",
    "Regularly check the 'Upcoming Challenges' section to stay updated and participate in new activities.",
    "Invite friends to Meed and enjoy creating and completing challenges together.",
    "Got a challenge idea? Use the 'Create A Challenge' feature to bring it to life and see who takes it on.",
    "Balance is key! Enjoy a mix of easy and complex challenges to keep things interesting.",
    "Encourage fair play and sportsmanship by voting honestly in the challenge outcomes.",
    "Share your completed challenges on social media to inspire others and grow the Meed community.",
    "Establish your own challenge goals and enjoy the thrill of achieving them, one challenge at a time.",
  ],
  Voting: [
    "Tip: Your vote counts! Participate in the voting phase to decide the outcome of challenges.",
    "Tip: Everyone can vote on challenges, but only 'qualified' votes, as set during Challenge creation, count towards the final decision.",
    "Tip: Challenge funds are securely held and released only after a successful completion with more official pass votes than official fail votes.",
    "Voting Type 'All': Every vote counts towards the final decision in this inclusive voting method.",
    "Voting Type 'Both': In this format, only the challenge creator and the recipient's votes are considered for the final outcome.",
    "Voting Type 'Boost': Your financial support in a challenge gives you a say, as only the votes of contributors are counted.",
    "Voting Type 'Creator': Here, the challenge creator has the sole authority to decide the outcome with their official vote.",
    "Voting Type 'Custom': This flexible option allows the challenge creator to designate specific users who hold the power to vote.",
    "Voting Type 'Default': In this scenario, only the streamer or challenge recipient's vote is counted officially."
  ],
  Creativity: [
    "Tip: Engage with your favorite streamers by suggesting unique challenges they've never tried before.",
    "Tip: Remember, the more creative and fun the challenge, the more likely it is to get votes!"
  ],
  Challenges: [
    // Call getRandomChallenge and wrap the result in an array
    () => getRandomChallenge(),
  ]
  // Add more categories and tips as needed
};

/**
 * Retrieves a random tip from the given category.
 * If 'default' or no category is specified, it selects a random tip 
 * from all categories.
 * 
 * @param {string} [category='default'] - The category of tips to select from.
 * @returns {string} A random tip.
 */
function getRandomTip(category = 'default') {
  if (category === 'Challenges') {
    // Directly return a random challenge when the "Challenges" category is specified
    return getRandomChallenge();
  }
  let tips;
  if (category === 'default') {
    // Combine all tips from all categories if 'default' category is passed
    tips = Object.values(tipsByCategory).flat();
  } else {
    tips = tipsByCategory[category] || [];
    // If the selected category contains a function, evaluate it to get the challenge
    tips = tips.map(tip => typeof tip === 'function' ? tip() : tip);
  }
  return tips[Math.floor(Math.random() * tips.length)];
}

/**
 * LoadingScreen component, displaying a loading icon and random tips.
 * 
 * @param {Object} props - Component props.
 * @param {string} [props.category='default'] - The category of tips to display.
 * @returns {React.Component} The LoadingScreen component.
 */
function LoadingScreen({ category = 'default' }) {
  const [tip, setTip] = useState(getRandomTip(category));
  // Update the tip every certain number of seconds
  useEffect(() => {
    const tipInterval = setInterval(() => {
      setTip(getRandomTip(category));
    }, 10000);

    return () => clearInterval(tipInterval);
  }, [category]);

  return (
    <LoadingContainer>
      <Logo src={meedIconLogo} alt="Meed's Logo" />
      <LoadingText>Loading...</LoadingText>
      <CircularProgress color="secondary" />
      <TipText>{tip}</TipText> {/* Display the tip */}
    </LoadingContainer>
  );
}

export default LoadingScreen;
