import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const LoadingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh', // Full viewport height
  background: '#000', // Black background, adjust as needed
});

const Logo = styled('img')({
  marginBottom: '20px',
  width: '150px', // Adjust the width as needed for your logo
  height: 'auto' // Keeps the aspect ratio of the image
});

const LoadingText = styled(Typography)({
  color: '#fff', // White text color, adjust as needed
  fontSize: '24px', // Adjust the font size as needed
  marginBottom: '20px',
});

const TipText = styled(Typography)({
  color: '#fff', // Choose a color that contrasts well with your background
  fontSize: '18px', // Choose an appropriate font size
  marginTop: '20px', // Spacing from the element above it
  maxWidth: '80%', // Maximum width to ensure it doesn't stretch too wide
  textAlign: 'center', // Center align the text for better aesthetics
  lineHeight: '1.5', // Line height for better readability
});

export { LoadingContainer, Logo, LoadingText, TipText };

