const settingsStyles = (theme) => ({
    ...theme.custom,
    logout: {
        textAlign: 'left',
        marginTop: '10px',
        background: '#F1B6D4',
        color: '#fff'
    },
    topZ: {
        zIndex: 9999
    },
    paper: {
        boxShadow: 'none',
        background: 'transparent'
    },
    userIcon: {
        display: 'inline-block'
    },
    userHead: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative'
    },
    userMeta: {
        display: 'inline-block',
        marginLeft: 40
    },
    follow: {
        background: '#14C7FC',
        color: '#5B5A57',
        position: 'absolute',
        top: 10,
        right: 10
    },
    plusIcon: {
        marginRight: 10
    },
    tabHeader: {
        boxShadow: 'none',
        background: 'transparent',
        '@media (max-width: 600px)': {
            marginTop: 28,
        },
    },
    tabPanel: {
        marginLeft: 56,
        marginTop: 56,
        '@media (max-width: 600px)': {
            marginLeft: 0
        },
    },
    challs: {
        flexDirection: 'column',
        '& h2': {
            opacity: 0.6
        }
    },
    challsBox: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    general: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridTemplateRows: 'auto auto',
        columnGap: '20px',
        rowGap: '20px',
        '@media (max-width: 1367px)': {
            gridTemplateColumns: 'auto auto',
            gridTemplateRows: 'unset',
        },
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'unset',
        },
    },
    generalBox: {
        position: 'relative',
        maxWidth: 371,
        minHeight: 180,
        background: '#3F2D74',
        padding: 20,
        '& div': {
            color: '#fff',
            minWidth: 370,
            '@media (max-width: 600px)': {
                minWidth: 240,
            },
        },
        '& button': {
            background: '#fff',
            color: '#6E55D9'
        },
        '@media (max-width: 600px)': {
            minWidth: 240,
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'unset',
        },
    },
    generalBoxHeader: {
        fontSize: '20px',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    generalBoxBtn: {
        borderRadius: 0,
        position: 'absolute',
        bottom: 10,
        right: 10,
        opacity: .9,
        '&:hover': {
            opacity: 1,
            background: '#fff'
        }
    },
    public: {
        '& div': {
            fontWeight: 700,
            textTransform: 'uppercase',
            cursor: 'pointer',
            borderBottom: 'solid 1px #707070 !important',
            padding: '10px 0'
        },
    },
    modList: {
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'auto',
            maxWidth: '350px',
        },
    },
    modListCol1: {
        '@media (max-width: 600px)': {
            maxWidth: 'inherit',
            padding: 0
        },
        padding: '0 20px',
        textTransform: 'uppercase',
        '& hr': {
            border: 'solid 1px #000',
            width: '100%',
            opacity: 0.2,
            margin: '20px 0'
        }
    },
    searchUser: {
        background: '#fff',
        padding: 20,
        color: '#5B5A57',
        height: '100%',
        textAlign: 'center',
        maxWidth: 280,
        minHeight: 500,
        '@media (max-width: 600px)': {
            maxWidth: 'inherit',
            minHeight: 'unset',
        },
    },
    searchInputDad: {
        width: '100%',
        textAlign: 'left',
    },
    searchInput: {
        '& input': {
            background: '#fff',
            padding: '5px',
            margin: 10,
            marginLeft: 0,
            borderRadius: 8,
            outline: 'none',
            border: 'none'
        },
        '& button': {
            display: 'none'
        },
        '& .ais-Hits-list': {
            flexWrap: 'nowrap',
            position: 'absolute',
            flexDirection: 'column',
            background: '#5B5A57',
            listStyleType: 'none !important',
            margin: 0,
            padding: '0 10px',
        },
    },
    modPanelHeader: {
        width: '100%',
        display: 'flex',
        fontWeight: 700,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    table: {
        textTransform: 'uppercase',
        '@media (max-width: 600px)': {
            maxWidth: '350px',
        },
        '& th': {
            '@media (max-width: 600px)': {
                padding: 7,
                '@media (max-width: 600px)': {
                    fontSize: '.65rem',
                },
            },
        },
        '& td': {
            '@media (max-width: 600px)': {
                padding: 5,
                '@media (max-width: 600px)': {
                    fontSize: '.65rem',
                },
            },
        }
    },
    point: {
        cursor: 'pointer'
    },
    tableBtn: {
        background: '#14C7FC',
        '@media (max-width: 600px)': {
            fontSize: '.65rem',
        },
    },
    tableHeader: {
        verticalAlign: 'top',
        '& th': {
            fontWeight: 900
        }
    },
    whiteText: {
        textAlign: 'left',
        color: '#5B5A57',
        margin: '10px 0'
    },
    submitButton: {
        color: '#fff',
        background: '#14C7FC',
        textTransform: 'uppercase'
    },
    checkBox: {
        display: 'inline-block'
    },
    result: {
        cursor: 'pointer',
        '&:hover': {
            color: '#14C7FC'
        }
    },
    selected: {
        color: '#14C7FC',
        fontWeight: 700
    },
    results: {
        '& ul': {
            background: '#5B5A57',
            maxWidth: 250,
            flexDirection: 'column',
            flexWrap: 'nowrap',
            width: '100%',
            zIndex: 1,
            position: 'fixed',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '100%',
                height: 4
            },
        },
        '& li': {
            display: 'block',
            background: '#5B5A57',
            color: '#fff',
            padding: 10
        }
    },
    hideResults: {
        display: 'none'
    },
    goal: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        '& span': {
            fontSize: '12px',
        }
    }
});

export default settingsStyles;