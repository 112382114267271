const searchStyles = {
    active: {
        background: '#14C7FC !important',
        color: '#fff !important'
    },
    searchHeader: {
        background: '#fff',
        position: 'relative',
        color: '#5B5A57',
        fontWeight: 700,
        textTransform: 'uppercase',
        width: '100%',
        marginTop: 64,
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 32px',
        '& div': {
            padding: '6px 8px',
        },
        '& button': {
            marginLeft: 10,
            background: '##F3F3F3',
            color: '#5B5A57',
            borderRadius: 0,
            '@media (max-width: 600px)': {
                fontSize: '0.575rem',
            },
        },
        '@media (max-width: 600px)': {
            fontSize: '0.575rem',
            padding: '10px 0'
        },
    },
    card: {
        position: 'relative',
        display: 'flex',
        marginBottom: 20,
        borderRadius: '20px'
    },
    image: {
        minWidth: 200
    },
    liveTip: {
        backgroundColor: '#FFDF57',
        position: 'absolute',
        left: '32px',
        top: '52px',
        padding: '5px',
        fontWeight: '700'
    },
    challWrap: {
        padding: '0px 32px',
        position: 'relative',
        '@media (max-width: 600px)': {
            padding: '0px 10px',
        },
    },
    challs: {
        flexDirection: 'column',
        marginLeft: 'unset',
        marginTop: 'unset',
        width: '100%',
        '& h2': {
            opacity: 0.6
        }
    },
    challsBox: {
        marginTop: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    userCard: {
        backgroundColor: '#3F2D74',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
        marginRight: 20,
        borderRadius: '0px',
        maxWidth: '350px',
        minWidth: '350px',
        width: '100%',
        color: '#fff',
    },
    yellowCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#5B5A57',
        borderRadius: '50%',
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px'
    },
    hideOverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
        paddingRight: '55px',
        fontWeight: '700'
    },
    verifiedUser: {
        display: 'flex',
        marginBottom: '5px',
        color: '#fff',
        textTransform: 'uppercase'
    },
    profileImage: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '50px',
        height: '50px',
        borderRadius: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    content: {
        objectFit: 'cover',
        overflow: 'hidden',
        paddingBottom: '6px !important',
        padding: '16px',
        color: '#fff'
    },
    horizLine: {
        background: '#B464FA',
        width: '100%',
        height: 2
    },
    homeTitle: {
        marginBottom: 0,
        '@media (max-width: 600px)': {
            padding: '0px 20px',
        },
    },
    searchUser: {
        background: '#B464FA',
        padding: 20,
        color: '#fff',
        height: '100%',
        textAlign: 'center'
    },
    searchInputDad: {
        width: '100%',
        textAlign: 'left',
    },
    searchInput: {
        '& .ais-Hits-list': {
            listStyleType: 'none !important',
            margin: 0,
            padding: 0,
            '@media (max-width: 600px)': {
                padding: '0px 20px',
            },
        },
        '& button': {
            display: 'none'
        },
        '& input': {
            border: 'solid 1px rgba(0, 0, 0, 0.23)',
            background: 'transparent',
            height: 30,
            width: 400,
            padding: '5px',
            margin: 10,
            marginLeft: 0,
            borderRadius: 4,
            outline: 'none',
            '@media (max-width: 600px)': {
                width: '100%'
            },
        }
    },
    results: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        '& li': {
            width: 'unset',
            margin: 10
        },
    },
    notMobile: {
        '@media (max-width: 600px)': {
            display: 'none'
        },
    }
};

export default searchStyles;