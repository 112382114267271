import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Challenge from '../components/challenge/Challenge';
import { getChallengeById } from '../redux/actions/dataActions';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

import ChallengeSkeleton from '../util/ChallengeSkeleton';

import { connect } from 'react-redux';
import { getUserProfileData } from '../redux/actions/userActions';
import { getChallenge } from '../redux/actions/dataActions';
import { fulfillMeedPoints } from '../redux/actions/userActions';

import { withStyles } from '@mui/styles';
import userChallengeQueueStyles from './styles/userChallengeQueueStyles';
let gettingChall = false;

let userInterval;
let viewing = false;

class userChallengeQueue extends Component {
  state = {
    profile: {},
    challengeIdParam: '',
    clickedChallenge: {},
    payAmount: '',
    handle: '',
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    twitchFrameParent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"],
    challengePanelOpen: false,
    challengeCreatePanelOpen: false,
    challengeSearchPanelOpen: false,
    chatPanel: true,
    activeChallId: '',
    challengesQueue: [],
    challengesCreated: [],
    challengesRequested: [],
    challengesCompleted: [],
    fetched: false,
    totalUpvotes: false,
    money: false,
    timeRemaining: false,
    stateOpen: true,
    stateActive: true,
    acceptTimeSmall: true,
    acceptTimeMedium: true,
    acceptTimeLarge: true
  };

  getData = async () => {
    const handle = this.props.match.params.handle,
      challengeId = this.props.match.params.challengeId,
      handler = e => this.setState({ matches: e.matches });
    await this.fetchUser(handle);
    window.matchMedia("(min-width: 768px)").addListener(handler);
    this.setState({ handle: handle });
    if (challengeId && challengeId !== 'paymentSuccessful' && this.state.challengesRequested) {
      this.setState({
        challengeId: challengeId,
        challengePanelOpen: true,
        clickedChallenge: this.state.challengesRequested.filter((challenge) => { return challenge.challengeId === challengeId })[0]
      });
    } else if (this.state.challengesRequested && this.state.challengesRequested.length < 1) {
      await this.props.getChallengeById(challengeId).then((chall) => {
        this.setState({ clickedChallenge: chall });
      });
    }

    if (this.state.profile && !challengeId && !this.state.profile.twitch) {
      this.setState({ challengeCreatePanelOpen: true });
    }

    if (!userInterval) {
      userInterval = setInterval(() => {
        this.refreshUser(this.props.match.params.handle)
      }, 10000);
    }
  }

  refreshUser = async (handle) => {
    await this.props.getUserProfileData(handle)
      .then((res) => {
        this.setState({
          challengesQueue: res.challengesRequested
        });
      })
  }

  updateChallenge = async (id) => {
    console.log("This is the updateChallenge called when we click buttons");
    await this.refreshUser(this.props.match.params.handle)
    if (this.state.challengesQueue && this.state.challengesQueue.filter((chall) => chall.challengeId === id).length)
      this.setState({
        clickedChallenge: this.state.challengesQueue.filter((chall) => chall.challengeId === id)[0]
      })
    else
      this.setState({
        clickedChallenge: {},
        challengePanelOpen: false,
      })
  }

  fetchUser = async (handle) => {
    try {
      const res = await this.props.getUserProfileData(handle);

      if (res.user) {
        this.setState({
          profile: res.user,
          challengesQueue: res.challengesRequested,
          challengesCreated: res.challengesCreated,
          challengesCompleted: res.challengesCompleted,
          challengesRequested: res.challengesRequested,
          // Grouped keys for created challenges
          lastCreatedKey1: res.keys.lastCreatedKey1,
          lastCreatedKey2: res.keys.lastCreatedKey2,

          // Grouped keys for requested challenges
          lastRequestedKey1: res.keys.lastRequestedKey1,
          lastRequestedKey2: res.keys.lastRequestedKey2,

          // Grouped keys for completed challenges
          lastCompletedKey1: res.keys.lastCompletedKey1,
          lastCompletedKey2: res.keys.lastCompletedKey2,

          // Grouped keys for voted challenges
          lastVotedKey1: res.keys.lastVotedKey1,
          lastVotedKey2: res.keys.lastVotedKey2,
          fetched: true,
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };

  viewChallenge = async (challenge) => {
    if (viewing)
      return;
    viewing = true;
    setTimeout(() => {
      viewing = false;
    }, 1000);
    await this.setState({
      challengeId: challenge.challengeId,
      clickedChallenge: challenge,
      challengePanelOpen: true,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      chatPanel: false
    });
  }

  activeChall = async (id) => {
    if (gettingChall)
      return;
    gettingChall = true;
    if (id !== this.state.activeChallId) {
      this.setState({
        activeChallId: id
      });
    }
    setTimeout(() => {
      gettingChall = false;
    }, 400);
  }

  handleCheck = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  handleFilterCheck = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  sortChallenges = (a, b) => {
    if (this.state.selectedValue && this.state.selectedValue === 'timeRemaining')
      return new Date(a.createdAt) - new Date(b.createdAt);
    else if (this.state.selectedValue && this.state.selectedValue === 'totalUpvotes')
      return b.likeCount - a.likeCount;
    else
      return b.initialCreatorPaymentAmount - a.initialCreatorPaymentAmount;
  }

  // filterChallenges = (chall) => {
  //   if (this.state.stateOpen)
  //     return (this.state.stateOpen === 'accepted')
  // }

  componentDidUpdate(prev) {
    if (this.state.handle && (this.props.match.params.handle !== prev.match.params.handle)) {
      this.getData();
      this.setState({
        clickedChallenge: {},
        challengePanelOpen: false,
      })
    }
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.getChallenge(this.props.match.params.challengeId).then(async (chall) => {
      await this.getData();
    });
  }
  componentWillUnmount() {
    //this.state.embed
    clearInterval(userInterval);
  }
  render() {
    const { loading } = this.props.data,
      { challengeIdParam } = this.state,
      {
        classes
      } = this.props,
      challengesMarkupAccepted = loading ? (
        <ChallengeSkeleton noMargin={true} />
      ) : this.state.challengesQueue === null ? (
        <p className={classes.paddIt}>No challenges from this user</p>
      ) : !challengeIdParam && this.state.challengesQueue && this.state.challengesQueue.length ? (
        this.state.challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return this.sortChallenges(a, b);
          })
          .map((currentChallenge) =>
            <div className={`${viewing ? classes.viewing : ''} ${classes.challWrap}`} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
              <Challenge
                challengeId={currentChallenge.challengeId}
                spacing="none"
                noMargin="true"
                key={currentChallenge.challengeId}
                challenge={currentChallenge}
                activeChall={this.activeChall}
                clickedChallId={this.state.activeChallId}
              />
            </div>
          )
      ) : this.state.challengesQueue && this.state.challengesQueue.length ? (
        this.state.challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return this.sortChallenges(a, b);
          }).map((challenge) => {
            if (challenge.challengeId !== challengeIdParam)
              return <Challenge spacing="none" noMargin="true" key={challenge.challengeId} challenge={challenge} />;
            else return <Challenge spacing="none" noMargin="true" key={challenge.challengeId} challenge={challenge} openDialog />;
          })
      )
        : (<ChallengeSkeleton noMargin={true} />);

    const challengesMarkupCreated = loading ? (
      <ChallengeSkeleton noMargin={true} />
    ) : this.state.challengesQueue === null ? (
      <p className={classes.paddIt}>No challenges from this user</p>
    ) : !challengeIdParam && this.state.challengesQueue && this.state.challengesQueue.length ? (
      this.state.challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return this.sortChallenges(a, b);
        })
        .map((currentChallenge) =>
          <div className={classes.challWrap} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
            <Challenge
              challengeId={currentChallenge.challengeId}
              spacing="none"
              oneMargin="true"
              key={currentChallenge.challengeId}
              challenge={currentChallenge}
              activeChall={this.activeChall}
              clickedChallId={this.state.activeChallId}
            />
          </div>
        )
    ) : this.state.challengesQueue && this.state.challengesQueue.length ? (
      this.state.challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return this.sortChallenges(a, b);
        }).map((challenge) => {
          if (challenge.challengeId !== challengeIdParam)
            return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} />;
          else return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} openDialog />;
        })
    )
      : (<ChallengeSkeleton noMargin={true} />);

    return (
      <div className={classes.userTopElem}>
        <Grid className={classes.wrapper} container>
          {/* The challenge section */}
          <Grid className={`${classes.gridSpacingNone} ${classes.challRight}`} item xs={12}>
            <div className={classes.challWrapInner}>
              <div className={classes.challengeToolBar}>
                <div className={classes.challengeSearch}>
                  <div> Challenges </div>
                </div>
                <Accordion className={classes.filter}>
                  <AccordionSummary className={classes.filterSummary} expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                    <Typography className={classes.heading}>Filter</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.filterOptions}>
                    <Typography className={classes.filterHeader}>
                      Sort by:
                    </Typography>
                    <Typography>
                      Time Remaining
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'timeRemaining'}
                        onChange={this.handleCheck}
                        name="timeRemaining"
                        value="timeRemaining"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography>
                      Total Upvotes
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'totalUpvotes'}
                        onChange={this.handleCheck}
                        name="totalUpvotes"
                        value="totalUpvotes"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography>
                      $$$
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'money'}
                        onChange={this.handleCheck}
                        name="money"
                        value="money"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography className={classes.filterHeader}>
                      Filter by:
                    </Typography>
                    <Typography className={classes.filterSubHeader}>
                      State
                    </Typography>
                    <Typography>
                      Active
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.stateActive}
                        onChange={this.handleFilterCheck}
                        name="stateActive"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography>
                      Open
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.stateOpen}
                        onChange={this.handleFilterCheck}
                        name="stateOpen"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    {/* <Typography className={classes.filterSubHeader}>
                      Duration/Time to Accept
                    </Typography>
                    <Typography>
                      Small 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeSmall}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeSmall"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography>
                    <Typography>
                      Medium 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeMedium}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeMedium"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography>
                    <Typography>
                      Large 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeLarge}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeLarge"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography> */}
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={classes.rightChalls}>
                {challengesMarkupAccepted}
                {challengesMarkupCreated}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

userChallengeQueue.propTypes = {
  getUserProfileData: PropTypes.func.isRequired,
  getChallenge: PropTypes.func.isRequired,
  getChallengeById: PropTypes.func.isRequired,
  fulfillMeedPoints: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data
});

export default connect(
  mapStateToProps,
  { getUserProfileData, fulfillMeedPoints, getChallenge, getChallengeById }
)(withStyles(userChallengeQueueStyles)(userChallengeQueue));
