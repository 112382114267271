// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const SET_FIREBASE_USER = 'SET_FIREBASE_USER';
export const SET_INFLUENCER = 'SET_INFLUENCER';
export const LOADING_INFLUENCER = 'LOADING_INFLUENCER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SHOW_NAV = 'SHOW_NAV';
export const HIDE_NAV = 'HIDE_NAV';
// Data reducer types
export const SET_CHALLENGES = 'SET_CHALLENGES';
export const SET_CHALLENGE = 'SET_CHALLENGE';
export const SET_ACCEPTING_USERS = 'SET_ACCEPTING_USERS';
export const SET_COMPLETED_CHALLENGES = 'SET_COMPLETED_CHALLENGES';
export const SET_STAFF_CHALLENGES = 'SET_STAFF_CHALLENGES';
export const LIKE_CHALLENGE = 'LIKE_CHALLENGE';
export const UNLIKE_CHALLENGE = 'UNLIKE_CHALLENGE';
export const DELETE_CHALLENGE = 'DELETE_CHALLENGE';
export const POST_CHALLENGE = 'POST_CHALLENGE';
export const POST_SUGGESTION = 'POST_SUGGESTION';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const GET_TOTAL_RAISED = 'GET_TOTAL_RAISED';
export const ACCEPT_CHALLENGE = 'ACCEPT_CHALLENGE';
export const DECLINE_CHALLENGE = 'DECLINE_CHALLENGE';
export const COMPLETE_CHALLENGE = 'COMPLETE_CHALLENGE';
export const FAIL_CHALLENGE = 'FAIL_CHALLENGE';
export const CANCEL_CHALLENGE = 'CANCEL_CHALLENGE';
export const BOOST_CHALLENGE = 'BOOST_CHALLENGE';
export const REPORT_CHALLENGE = 'REPORT_CHALLENGE';
export const OTHERS_DECIDE_CHALLENGE = 'OTHERS_DECIDE_CHALLENGE';
export const CAST_PASS_VOTE = 'CAST_PASS_VOTE';
export const CAST_FAIL_VOTE = 'CAST_FAIL_VOTE';
export const REMOVE_VOTE = 'REMOVE_VOTE';
// CSRF tokens
export const SET_CSRF_TOKEN = 'SET_CSRF_TOKEN';
export const CLEAR_CSRF_TOKEN = 'CLEAR_CSRF_TOKEN';
export const CSRF_FAILURE = 'CSRF_FAILURE';
// Rate limiter actions
export const RATE_LIMIT_FAILURE = 'RATE_LIMIT_FAILURE';
export const SHOW_TOAST = "SHOW_TOAST";
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE';
