const privacyStyles = (theme) => ({
    ...theme.custom,
    buttonDefault: {
        width: '100%',
        marginTop: 30,
        borderRadius: '2px',
        maxWidth: '220px'
    },
    label: {
        color: '#fff',
        borderColor: '#fff',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
    },
    gang: {
        '& label.Mui-focused': {
            color: '#fff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#fff',
            },
            '&:hover fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fff',
            },
        },
    },
});

export default privacyStyles;