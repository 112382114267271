import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import { toast } from 'react-toastify';

// MUI Stuff
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { connect } from 'react-redux';
import { declineChallenge } from '../../redux/actions/dataActions';

const styles = {
  declineButton: {
    background: '#E1E0DE',
    color: '#5B5A57',
    position: 'relative',
    border: 'solid 1px #E1E0DE',
    borderRadius: 8,
    padding: '6px 16px',
    fontSize: 16,
    fontWeight: 700,
    marginRight: 5,
    opacity: 0.9,
    transition: 'all .4s',
    '&:hover': {
      opacity: 1,
      border: 'solid 1px #5B5A57',
      background: '#E1E0DE'
    },
  }
};

class DeclineChallenge extends Component {
  state = {
    open: false,
    disabled: false
  };
  handleOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  declineChallenge = async () => {
    try {
      this.setState({ disabled: true });
      const response = await this.props.declineChallenge(this.props.challengeId);
      if (response?.success) {
        toast.success('SUCCESS: Challenge Declined!');
        this.props.updateChallenge(this.props.challengeId);
      } else {
        throw new Error('Challenge not Declined');
      }
    } catch (err) {
      toast.error('Failure: Challenge not Declined!');
      // handle additional error logic if needed
    } finally {
      this.setState({ disabled: false, open: false });
    }
  };  
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <MyButton
          tip="Decline Challenge"
          onClick={this.handleOpen}
          btnClassName={classes.declineButton}
          disabled={this.state.disabled}
        >
          Decline
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to decline this challenge ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose}>
              Cancel
            </Button>
            <Button onClick={this.declineChallenge}>
              Decline
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeclineChallenge.propTypes = {
  declineChallenge: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  challengeId: PropTypes.string.isRequired
};

export default connect(
  null,
  { declineChallenge }
)(withStyles(styles)(DeclineChallenge));
