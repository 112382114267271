import React, { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// MUI Stuff
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/layout/TabPanel';
// Redux stuff
// import Filter from 'bad-words';
// Redux stuff
import { connect } from 'react-redux';
import swords from '../images/swords.png';
import integrationsTwitchStyles from './styles/integrationsTwitchStyles';

// money only counts for ppl with accounts on both sides
// only email, no from field
class integrationsTwitch extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No',
      tabValue: 0
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true
    });
  }
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  scrollToTopOfList = () => {
    const headerElement = document.getElementById('topOfList'); // Replace 'yourHeaderId' with the actual ID of your header
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.once) {
      if (this.props.home) {

      }
    }
  }
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`} xs={12}>
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Available Commands</h1>
              </div>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.scrollToTopOfList}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875">
                    <path id="Icon_awesome-chevron-circle-down" data-name="Icon awesome-chevron-circle-down" d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                  </svg>

                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.moreWrap}>
          <Grid className={`${classes.gridSpacingNone} ${classes.mainGrid} ${classes.needHelpDescFormat}`} item sm={6} xs={12}>
            <div className={`${classes.pTextSize}`}>
              <span className={classes.pTextSizeBold}>Welcome to the MeedBot Command Hub for Twitch. </span>
              Ready to enhance your stream with interactive challenges? Connect your Twitch to Meed, and let your viewers join the fun with the commands outlined below. Let the games begin!
            </div>
          </Grid>
          <Grid className={`${classes.gridSpacingNone} ${classes.needHelpIntegFormat}`} item sm={6} xs={12}>
            <Grid container className={classes.integrationSection}>
              <Grid className={classes.integrationSectionText1} item md={6} xs={12}> Ready to add Meed to your stream? </Grid>
              <Grid className={classes.integrationSectionText2} item md={6} xs={12}>
                <Button
                  className={classes.startIntegrationButton}
                  href="https://meed.app/settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Start Integration
                </Button>
              </Grid>

              {/*<img className={classes.needHelpImg} src={thumb}></img>*/}
            </Grid>
          </Grid>
        </Grid>
        <Fragment>
          <div id="topOfList" className={`${classes.commandContainerFormat1}`}>
            <AppBar className={`${classes.tabHeader} ${classes.commandListFormat}`} position="static" color="default">
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                indicatorColor="#ECECEC"
                textColor="#FFF"
                fullWidth="true"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabHeader}
                aria-label="scrollable auto tabs example"
              >
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 0 ? classes.commandActiveButton : ''} `} label="All" {...this.a11yProps(0)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 1 ? classes.commandActiveButton : ''} `} label="Challenge" {...this.a11yProps(1)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 2 ? classes.commandActiveButton : ''} `} label="Donate" {...this.a11yProps(2)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 3 ? classes.commandActiveButton : ''} `} label="Help" {...this.a11yProps(3)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 4 ? classes.commandActiveButton : ''} `} label="User" {...this.a11yProps(4)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 5 ? classes.commandActiveButton : ''} `} label="AI Challenge" {...this.a11yProps(4)} />
                {/*<Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 5 ? classes.commandActiveButton : ''} `} label="Help" {...this.a11yProps(5)} />
                <Tab className={`${classes.commandHeaderButton} ${this.state.tabValue === 6 ? classes.commandActiveButton : ''} `} label="Random" {...this.a11yProps(6)} />*/}

              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={0}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-challenge
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Embark on a quest of valor with "!meed-challenge". Command a fellow adventurer to prove their mettle by typing their username and the epic task at hand. For example, “!meed-challenge @RingBearer Retrieve the ancient artifact in record time” would call upon @RingBearer to undertake a legendary journey. Forge bonds of fellowship by linking your Twitch account with Meed and join forces in the spirit of friendly competition.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-challenge [@user] [challenge description]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-donate
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Support your allies in their quests with "!meed-donate". Summon a link to bestow treasures upon your chosen champion by typing their call sign. For instance, “!meed-donate @HobbitHero” will conjure up a pathway to @HobbitHero’s hoard, where you can pledge your gold. Be it a token of gratitude or a bounty for a daring deed, your contribution fuels the adventures ahead.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-donate [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-help
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Should you find yourself at a crossroads, uncertain of the path ahead, invoke "!meed-help". Like a guiding light in the darkest of mines or wretched of spider lairs, this command will reveal knowledge about Meed and provide a scroll to this very compendium. Type "!meed-help" and receive the wisdom you seek to navigate your fellowship through the challenges that await.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-help

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-user
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      To discover the legends behind the names in your fellowship, use "!meed-user". By invoking this rune with a user’s moniker, you can unearth their saga on Meed. For example, typing "!meed-user @ElvenArcher" would unveil the profile of @ElvenArcher, should they be registered in the annals of our chronicles. A link shall be forged, leading you to their tale of quests and conquests.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-user [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-gpt
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Spark your imagination with "!meed-gpt". Just type the command followed by a user's name, a space, and any text you want to use to seed the challenge, and watch as the AI conjures up a creative and fun challenge. Perfect for when you want to add a twist of surprise and excitement to your gaming sessions!
                    </div>

                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-gpt [@user] [text]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={1}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-challenge
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Embark on a quest of valor with "!meed-challenge". Command a fellow adventurer to prove their mettle by typing their username and the epic task at hand. For example, “!meed-challenge @RingBearer Retrieve the ancient artifact in record time” would call upon @RingBearer to undertake a legendary journey. Forge bonds of fellowship by linking your Twitch account with Meed and join forces in the spirit of friendly competition.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-challenge [@user] [challenge description]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={2}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-donate
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Support your allies in their quests with "!meed-donate". Summon a link to bestow treasures upon your chosen champion by typing their call sign. For instance, “!meed-donate @HobbitHero” will conjure up a pathway to @HobbitHero’s hoard, where you can pledge your gold. Be it a token of gratitude or a bounty for a daring deed, your contribution fuels the adventures ahead.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-donate [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={3}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-help
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      Should you find yourself at a crossroads, uncertain of the path ahead, invoke "!meed-help". Like a guiding light in the darkest of mines or wretched of spider lairs, this command will reveal knowledge about Meed and provide a scroll to this very compendium. Type "!meed-help" and receive the wisdom you seek to navigate your fellowship through the challenges that await.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-help

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={4}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <div className={`${classes.thick}`}>
                      !meed-user
                    </div>
                    <div className={`${classes.commandDescription}`}>
                      To discover the legends behind the names in your fellowship, use "!meed-user". By invoking this rune with a user’s moniker, you can unearth their saga on Meed. For example, typing "!meed-user @ElvenArcher" would unveil the profile of @ElvenArcher, should they be registered in the annals of our chronicles. A link shall be forged, leading you to their tale of quests and conquests.
                    </div>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-user [@user]

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={5}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} alt="Icon of crossed swords symbolizing competition or challenge" src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <p className={`${classes.thick}`}>
                      !meed-gpt
                    </p>
                    <p className={`${classes.commandDescription}`}>
                      Spark your imagination with "!meed-gpt". Just type the command followed by a user's name, a space, and any text you want to use to seed the challenge, and watch as the AI conjures up a creative and fun challenge. Perfect for when you want to add a twist of surprise and excitement to your gaming sessions!
                    </p>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-gpt [@user] [text]
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            {/*
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={5}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <p className={`${classes.thick}`}>
                      !meed-help
                    </p>
                    <p className={`${classes.commandDescription}`}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque consequat imperdiet quam in tristique. Curabitur
                      eget tellus viverra lacus eleifend egestas vitae a ligula.
                      Curabitur aliquet eget risus nec porta.
                    </p>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-help text here

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={6}>
              <Grid container className={`${classes.commandContainerFormat}`}>
                <Grid className={`${classes.commandFormat2}`} item sm={3} xs={12}>
                  <img className={`${classes.jobs}`} src={swords}></img>
                </Grid>
                <Grid container className={`${classes.commandFormat3}`} item sm={9} xs={12}>
                  <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={8} xs={12}>

                    <p className={`${classes.thick}`}>
                      !meed-random
                    </p>
                    <p className={`${classes.commandDescription}`}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque consequat imperdiet quam in tristique. Curabitur
                      eget tellus viverra lacus eleifend egestas vitae a ligula.
                      Curabitur aliquet eget risus nec porta.
                    </p>
                  </Grid>
                  <Grid className={`${classes.mid}`} item sm={4} xs={12}>
                    <Button
                      className={`${classes.commandExample} `}
                    >
                      !meed-random text here

                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
              */}
          </div>
        </Fragment>
      </div>
    );
  }
}

integrationsTwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(integrationsTwitchStyles)(integrationsTwitch));
