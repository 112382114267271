import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser, authenticateViaGoogle, authenticateViaFacebook, authenticateViaTwitter } from '../redux/actions/userActions';
import { toast } from 'react-toastify';
import loginStyles from './styles/loginStyles';

class login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      auth: {},
      open: false
    };
    //this.emailInputRef = React.createRef();   REMOVE
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  signInWithGoogle = async (event) => {
    console.log("Sign in with Google", { event });
    const response = await this.signInWithSocialMedia(event, this.props.authenticateViaGoogle);
    console.log("After Sign in with Google", { response });
    return response
  }

  signInWithFacebook = async (event) => {
    await this.signInWithSocialMedia(event, this.props.authenticateViaFacebook);
  }

  signInWithTwitter = async (event) => {
    await this.signInWithSocialMedia(event, this.props.authenticateViaTwitter);
  }

  signInWithSocialMedia = async (event, authenticationFunction) => {
    event.preventDefault();
    this.setState({ submitted: true });
    console.log("Sign in with social media: ", { event, authenticationFunction })
    try {
      const response = await authenticationFunction(this.props.history);
      console.log({ response });
      if (response) {
        toast.success('SUCCESS: Logged in! Redirecting...');
        this.props.history.goBack();
      } else {
        toast.error('Failed: Incorrect username or password, try again.');
      }
    } catch (err) {
      toast.error('Authentication failed.');
    } finally {
      this.setState({ submitted: false });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.setState({ submitted: true });

    try {
      const res = await this.props.loginUser(userData, this.props.history);
      if (res) {
        toast.success('SUCCESS: Logged in! Redirecting...');
        this.props.history.push(this.state.location ? this.state.location.from : '/home');
      } else {
        toast.error('Failed: Incorrect username or password, try again.');
      }
    } catch (err) {
      console.log('replyerr: ', err);
      toast.error('Authentication failed.');
    } finally {
      this.setState({ submitted: false });
    }
  };
  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const location = this.props.location && this.props.location.state ? this.props.location.state : '/home';
    /*const emailRef = useRef(null);
    const emailFieldValue = emailRef.current.value;
    this.setState({ location: location });

    console.log('BEFORE!!!!!!!1',emailFieldValue);
    emailFieldValue === '' ? emailFieldValue = 'THIS IS A TEST': console.log('Before!!!!!!!!!!!!2, ', emailFieldValue);//trying to check if the textfield is empty and replacing it

    setTimeout(() => {
      console.log('After!!!!!!!!!!!!, ', emailFieldValue)
    }, 2000);*/
    //if this.email.   this.state.email

    //console.log('***************, ', location)
    //console.log('*************** ', this.props.location.from)
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.email === "") {

    }
    if (prevState.email !== this.state.email) {
      const emailFieldValue = this.state.email;
      if (emailFieldValue === '') {
        this.setState({ email: '' });
      }
    }
  }

  render() {
    const {
      classes,
    } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Grid container className={classes.form}>
          <Grid className={`${classes.gang} ${classes.gang2}`} item sm={6} xs={12}>
            <div className={classes.formLogin}>
              <Typography
                variant="h4"
                className={classes.label}
              >
                Login
              </Typography>
              <form value="Outlined" noValidate className={classes.cpiece} onSubmit={this.handleSubmit}>
                <div>
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    //inputRef={emailRef}
                    name="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    className={classes.loginInput}
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={this.state.email}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      maxLength: 100,
                      //form: {autoComplete: 'off'}, 
                      style: { borderRadius: 35 },

                    }}
                    fullWidth
                  />
                  <Typography
                    variant="p"
                    className={classes.pageTitle2}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    className={classes.loginInput}
                    helperText={errors.password}
                    error={errors.password ? true : false}
                    value={this.state.password}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      maxLength: 100,
                      style: { borderRadius: 35 },
                    }}
                    fullWidth
                  />
                  {errors.general && (
                    <Typography color="#fff" variant="body2" className={classes.customError}>
                      {errors.general}
                    </Typography>
                  )}
                  <div className={classes.remember}>
                    <div>
                      <Checkbox
                        checked={this.state.remember}
                        onChange={this.handleCheck('remember')}
                        value="remember"
                      />
                      <span className={`${classes.extraAction} ${classes.bold}`}>Remember Me</span>
                    </div>
                    <div className={classes.flexCenter}>
                      <span className={`${classes.extraAction} ${classes.purple}`}>
                        <Link to="/forgotPassword" className={`${classes.purple} ${classes.bold}`}>Forgot Password?</Link>
                      </span>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttonSecondary}
                    disabled={this.state.submitted || this.state.loading}
                  >
                    Login
                    {this.state.loading && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                  <br />
                  <span className={`${classes.extraAction} ${classes.bold}`}>
                    Not registered yet? <Link to="/signup" className={classes.purple}>Create an account here</Link>.
                  </span>
                </div>
                <br />
                <div>
                  <div className={classes.horizLine}></div>
                  <small className={classes.extraAction2}>
                    Or Sign in with
                  </small>
                  <div className={classes.horizLine}></div>
                </div>
                <div className={classes.socials}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.signInWithGoogle}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-google"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.signInWithFacebook}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-facebook-f"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                  </Button>
                  {/*<Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.signInWithTwitter}
                    className={classes.buttonDefault}
                  >
                    <i className={"fa-brands fa-twitter"}></i>
                    {this.state.submitted && (
                      <CircularProgress size={30} className={classes.progress} />
                    )}
                    </Button>*/}
                </div>
              </form>
            </div>
          </Grid>
          <Grid className={`${classes.gang}`} item sm={6} xs={12}>
            <div className={classes.bgImgTitle}>
              <Typography
                variant="h1"
                className={classes.pageTitle}
              >
                Challenge someone or get ready to be Challenged!
              </Typography>
              <div className={classes.downArrow}>
                <i className={"fa-solid fa-arrow-down"}></i>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticateViaGoogle: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  authenticateViaFacebook: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  authenticateViaGoogle,
  loginUser,
  authenticateViaFacebook,
  authenticateViaTwitter
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(loginStyles)(login));