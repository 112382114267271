import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
// MUI Stuff
import Typography from '@mui/material/Typography';
import { getAnalytics, logEvent } from "firebase/analytics";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
// Redux stuff
import { postChallenge } from '../redux/actions/dataActions';
import { toast } from 'react-toastify';
// import Filter from 'bad-words';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser, contactUs } from '../redux/actions/userActions';
import { authenticateViaTwitter } from '../redux/actions/userActions';
import integrationStyles from './styles/integrationStyles';
import { discordLink, twitchExtensionIntegrationLink } from '../util/variables';

const analytics = getAnalytics();

// money only counts for ppl with accounts on both sides
// only email, no from field
class integrations extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false,
      open1: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      Twitch: window.Twitch,
      searchChall: true,
      socialName: '',
      payAmount: 0,
      open: false,
      body: '',
      endDate: 'xxlarge',
      completeByDate: 'xxlarge',
      influencer: '',
      timeout: null,
      loading: false,
      userHandleUrl: null,
      challengeCost: 0,
      mpChecked: true,
      tChecked: false,
      disabled: false,
      goal: 'No',
      tabValue: 0
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleClickOpen = async (e, challenge) => {
    e.stopPropagation();
    e.preventDefault();
    await this.setState({
      open1: true
    });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    // this.props.clearErrors();
    this.setState({ open1: false, errors: {} });
  };
  handleChange = (event) => {
    if (event.target.name === 'payAmount' && event.target.value < 0) {
      event.target.value = 0
    }

    this.setState({ [event.target.name]: event.target.value });
  };
  cleanInput = () => {
    this.setState({
      title: '',
      body: '',
      payAmount: 0
    })
  }
  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  handleChangeTab = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  };
  scrollToTopOfList = () => {
    const headerElement = document.getElementById('topOfList'); // Replace 'yourHeaderId' with the actual ID of your header
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // TODO: Add the contact us flow to reuse that redux action?
  handleSubmit = async (event) => {
    this.setState({ disabled: true });
    event.preventDefault();

    const { email, message } = this.state;
    // Check for title and email conditions
    // Input validation
    if (!this.state.email) {
      toast.error('Failed: Please enter your email.');
      return;
    }
    if (!this.state.message) {
      toast.error('Failed: Please enter a message.');
      return;
    }

    // Prepare userDetails
    const userDetails = {
      email,
      message,
      // Add any other user details that are necessary
    };

    try {
      // Call contactUs function and wait for its completion
      const response = await this.props.contactUs(userDetails);

      if (response) {
        // Display success toast message
        toast.success('SUCCESS: Message sent successfully!');
      }
      // Handle the response as needed
      // Optionally, log event to Firebase Analytics
      logEvent(analytics, 'integrations_contact', {
        currentUserHandle: this.props.user.authenticated ? this.props.user.credentials.handle : 'anon'
      });
    } catch (error) {
      console.error('Error during contactUs or Firebase Analytics', error);
      // Optionally, display an error toast message
      toast.error('ERROR: Failed to send message. Please try again.');
    } finally {
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.setState({ disabled: false });
    }
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.state.once) {
      if (this.props.home) {

      }
    }
  }
  render() {
    const { errors } = this.state;
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.team}>
        <Grid container className={classes.reelWrap}>
          {/* <div className={classes.bgThumb}></div> */}
          <Grid className={`${classes.gridSpacingNone} ${classes.topJoint} ${classes.mainGrid}`} xs={12}>
            <div className={classes.cta}>
              <div className={classes.ctaText}>
                <h1>Integrate with Multiple Platforms!</h1>
              </div>
              <div className={classes.ctaBtn}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={this.scrollToTopOfList}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="0 0 34.875 34.875">
                    <path id="Icon_awesome-chevron-circle-down" data-name="Icon awesome-chevron-circle-down" d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.434,17.434,0,0,1,35.438,18ZM19.2,26.009l9.527-9.527a1.681,1.681,0,0,0,0-2.384l-1.2-1.2a1.681,1.681,0,0,0-2.384,0L18,20.046,10.856,12.9a1.681,1.681,0,0,0-2.384,0l-1.2,1.2a1.681,1.681,0,0,0,0,2.384L16.8,26.009A1.689,1.689,0,0,0,19.2,26.009Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                  </svg>

                </Button>
              </div>
            </div>
          </Grid>
        </Grid>

        <div id="topOfList" className={`${classes.integrationsContainerFormat1}`}>

          <Grid container className={`${classes.commandContainerFormat}`}>
            <Grid className={`${classes.commandFormat2}`} item sm={4} xs={12}>
              <span className={`${classes.discordImageContainer}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" /></svg>
              </span>
            </Grid>
            <Grid container className={`${classes.integrationSection}`} item sm={8} xs={12}>
              <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={12} xs={12}>

                <div className={`${classes.thick}`}>
                  Discord
                </div>
                <div className={`${classes.integrationDescription}`}>
                  Meed has a Discord Bot that adds Meed integration to any server with a click of a button! Simply press {'<Add Meedbot>'} to add MeedBot to your server. Members of a Discord server can then use Meed without needing to make an account on Meed! For an explanation of available commands, click {'<Commands>'}.
                </div>
                <Button
                  variant="contained"
                  className={classes.startIntegrationButton}
                  href={discordLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320c-17.7 0-32 14.3-32 32s14.3 32 32 32H447.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM23 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H32.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" /></svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Add Meedbot
                  </span>
                </Button>
                <Button
                  className={classes.startIntegrationButton}
                  variant="contained"
                  href="https://meed.app/integrations/discord"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M64 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H128C57.3 32 0 89.3 0 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Commands
                  </span>

                </Button>

                <div />

              </Grid>

            </Grid>
          </Grid>

          <Grid container className={`${classes.commandContainerFormat}`}>
            <Grid className={`${classes.commandFormat2}`} item sm={4} xs={12}>
              <span className={`${classes.streamlabsImageContainer}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-57 185 300 300"><path class="st0" d="M51.7,336.3c0-12.5,0-18.7,2.4-23.5c2.1-4.2,5.5-7.6,9.7-9.7c4.8-2.4,11-2.4,23.5-2.4h17.8
	c12.5,0,18.7,0,23.5,2.4c4.2,2.1,7.6,5.5,9.7,9.7c2.4,4.8,2.4,11,2.4,23.5v5.1c0,12.5,0,18.7-2.4,23.5c-2.1,4.2-5.5,7.6-9.7,9.7
	c-4.8,2.4-11,2.4-23.5,2.4H72c-7.1,0-10.7,0-13.4-1.4c-2.4-1.2-4.3-3.2-5.6-5.6c-1.4-2.7-1.4-6.3-1.4-13.4V336.3z M83.5,338.8
	c0-3.5,2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4v12.7c0,3.5-2.8,6.4-6.4,6.4c-3.5,0-6.4-2.8-6.4-6.4V338.8z M115.3,332.5
	c-3.5,0-6.4,2.9-6.4,6.4v12.7c0,3.5,2.9,6.4,6.4,6.4c3.5,0,6.4-2.8,6.4-6.4v-12.7C121.7,335.3,118.8,332.5,115.3,332.5L115.3,332.5z
	 M115.3,332.5"/><path class="st0" d="M73.9,281.4c0.4,3.5-2,6.7-5.5,7.1c-1.9,0.2-3.5,0.6-4.8,1c-11,3.4-19.6,12-23,23c-0.4,1.4-0.8,2.9-1,4.8
   c-0.4,3.5-3.6,5.9-7.1,5.5c-3.5-0.4-6-3.6-5.5-7.1c0.3-2.5,0.8-4.8,1.5-7.1c4.7-15,16.4-26.7,31.4-31.4c2.2-0.7,4.5-1.2,7.1-1.5
   C70.3,275.5,73.5,277.9,73.9,281.4L73.9,281.4z M73.9,281.4"/></svg>
              </span>
            </Grid>
            <Grid container className={`${classes.integrationSection}`} item sm={8} xs={12}>
              <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={12} xs={12}>

                <div className={`${classes.thick}`}>
                  Streamlabs
                </div>
                <div className={`${classes.integrationDescription}`}>
                  If you stream, Meed can send notifications directly to your stream (using your existing donation flow) whenever a new Challenge is created. You can also customize when the notifications are sent to reward viewers. To set up alerts with Streamlabs, head to the settings section, and connect your Meed account to Streamlabs!

                </div>

                <Button
                  variant="contained"
                  className={classes.startIntegrationButton}
                  href="https://meed.app/settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320c-17.7 0-32 14.3-32 32s14.3 32 32 32H447.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM23 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H32.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" /></svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Connect
                  </span>
                </Button>
                <div />

              </Grid>

            </Grid>
          </Grid>

          <Grid container className={`${classes.commandContainerFormat}`}>
            <Grid className={`${classes.commandFormat2}`} item sm={4} xs={12}>
              <span className={`${classes.twitchImageContainer}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z" /></svg>
              </span>
            </Grid>
            <Grid container className={`${classes.integrationSection}`} item sm={8} xs={12}>
              <Grid className={`${classes.ctaTextCard} ${classes.mid}`} item sm={12} xs={12}>

                <div className={`${classes.thick}`}>
                  Twitch
                </div>
                <div className={`${classes.integrationDescription}`}>
                  Stream on Twitch? Enhance your chat with Meed by adding MeedBot. Viewers can issue challenges by typing !meed-challenge without signing up. Connect your Twitch account in settings to enable MeedBot. Explore commands by clicking {'<Add Meedbot>'}, or add our Twitch Extension for more features.
                </div>
                <Button
                  variant="contained"
                  className={classes.startIntegrationButton}
                  href="https://meed.app/settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> <path d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320c-17.7 0-32 14.3-32 32s14.3 32 32 32H447.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM23 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H32.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" /></svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Add Meedbot
                  </span>
                </Button>
                <Button
                  variant="contained"
                  className={classes.startIntegrationButton}
                  href="https://meed.app/integrations/twitch"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M64 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H128C57.3 32 0 89.3 0 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Commands
                  </span>

                </Button>
                <Button
                  variant="contained"
                  className={classes.startIntegrationButton}
                  href={twitchExtensionIntegrationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={`${classes.buttonIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#9146FF" width="24px" height="24px">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path fill="#FFFFFF" d="M8 5v14l11-7z" />
                    </svg>
                  </span>
                  <span className={`${classes.buttonTextFormat}`}>
                    Add Twitch Extension
                  </span>
                </Button>

                <div />

              </Grid>

            </Grid>
          </Grid>
        </div>
        <div >
          <Grid container className={classes.contactContainerFormat}>
            <Grid item sm={6} xs={12}>
              <span className={classes.pTextSizeBold}> Missing a platform? Message us! </span>

              <br></br>
              <form className={classes.gang} noValidate onSubmit={this.handleSubmit}>
                <div>
                  <div>
                    <TextField
                      className={classes.roundedRadius}
                      variant='outlined'
                      id="email"
                      name="email"
                      type="email" //Text
                      label="Email"
                      margin="normal"
                      inputProps={{
                        maxLength: 100,
                        disableUnderline: true,
                        style: { borderRadius: 25 },
                      }}
                      value={this.state.email}
                      onChange={this.handleChange}
                    //fullWidth
                    />
                  </div>

                  <TextField
                    className={`${classes.roundedRadius} ${classes.messageFormat}`}
                    variant='outlined'
                    id="message"
                    name="message"
                    type="text"
                    label="Message"
                    margin="normal"
                    multiline
                    rows="4"
                    inputProps={{
                      maxLength: 1000,
                      disableUnderline: true
                    }}
                    value={this.state.message}
                    onChange={this.handleChange}
                  //fullWidth
                  />

                </div>
                {errors.general && (
                  <Typography color="#fff" variant="body2" className={classes.customError}>
                    {errors.general}
                  </Typography>
                )}

                <Button
                  type="submit"
                  //variant="contained"
                  //color="7B5DF9"
                  className={classes.buttonDefault}
                  disabled={this.state.submitted}
                >
                  Send
                  <span className={`${classes.sendIconContainer}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg>
                  </span>
                  {this.state.submitted && (
                    <CircularProgress size={30} className={classes.progress} />
                  )}
                </Button>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

integrations.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  contactUs: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  loginUser,
  authenticateViaTwitter,
  postChallenge,
  contactUs,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(integrationStyles)(integrations));
