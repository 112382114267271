import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// MUI
import MuiLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../layout/TabPanel';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Icons
//Redux
import { connect } from 'react-redux';
import {
  logoutUser, AuthenticateWithTwitch, follow, unfollow, moreRequestedChallenges,
  moreSentChallenges,
  moreHistoricChallenges,
  moreVotedChallenges
} from '../../redux/actions/userActions';
import Challenge from '../challenge/Challenge';
import ChallengeSkeleton from '../../util/ChallengeSkeleton';

import followIcon from '../../images/plusWhite.png';
import mascot from '../../images/mascot.png';
import StaticProfileStyles from './styles/StaticProfileStyles';

const auth = getAuth();

class StaticProfile extends Component {
  state = {
    matches: window.matchMedia("(min-width: 768px)").matches,
    socialName: '',
    socialImg: '',
    tabValue: 3,
    panel1: true,
    hideProfile: false,
    openProfile: false,
  }

  handleLogout = () => {
    this.props.logoutUser(this.props.history);
  };

  doNothing = () => {
  };

  follow = () => {
    this.props.follow({ handle: this.props.userHandleUrl || this.state.socialName })
      .then((res) => {
        if (res.message === 'User followed') {
          toast.success('SUCCESS: User followed!');
          this.setState({
            currentlyFollowing: true
          })
        }
        else
          toast.error('Failure: User not followed!');
        //this.cleanInput();
      }).catch((err) => {
        toast.error('Failure: User not followed!');
      })
  };

  unfollow = () => {
    this.props.unfollow({ handle: this.props.userHandleUrl || this.state.socialName })
      .then((res) => {
        if (res.message === 'User unfollowed') {
          toast.success('SUCCESS: User unfollowed!');
          this.setState({
            currentlyFollowing: false
          })
        }
        else
          toast.error('Failure: User not unfollowed!');
        //this.cleanInput();
      }).catch((err) => {
        toast.error('Failure: User not unfollowed!');
      })
  };

  connectTwitch = () => {
    // TODO: move these to react env vars
    this.props.AuthenticateWithTwitch({ clientId: 'ldsz98zpapc5tayyp34ypb0rgxds2j', redirectUri: 'https://meed.app' });
  };

  handleChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  };

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  moreRequestedChallenges = async () => {
    try {
      const data = await this.props.moreRequestedChallenges(this.props.userHandleUrl, this.state.lastRequestedKey1, this.state.lastRequestedKey2);
  
      if (data && data.challengesRequested) {
        this.setState({
          lastRequestedKey1: data.lastRequestedKey1,
          lastRequestedKey2: data.lastRequestedKey2,
          challengesRequested: this.state.challengesRequested.concat(data.challengesRequested),
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };  
  // grab 20 more sent challenges
  moreSentChallenges = async () => {
    try {
      const data = await this.props.moreSentChallenges(this.props.userHandleUrl, this.state.lastCreatedKey1, this.state.lastCreatedKey2);
  
      if (data && data.challengesCreated) {
        this.setState({
          lastCreatedKey1: data.lastCreatedKey1,
          lastCreatedKey2: data.lastCreatedKey2,
          challengesCreated: this.state.challengesCreated.concat(data.challengesCreated)
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };  

  // grab 20 more compelted challenges
  moreHistoricChallenges = async () => {
    try {
      const data = await this.props.moreHistoricChallenges(this.props.userHandleUrl, this.state.lastCompletedKey1, this.state.lastCompletedKey2);
  
      if (data && data.challengesCompleted) {
        this.setState({
          lastCompletedKey1: data.lastCompletedKey1,
          lastCompletedKey2: data.lastCompletedKey2,
          challengesCompleted: this.state.challengesCompleted.concat(data.challengesCompleted),
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };  

  // grab 20 more challenges in voting stage
  moreVotedChallenges = async () => {
    try {
      const data = await this.props.moreVotedChallenges(this.props.userHandleUrl, this.state.lastVotedKey1, this.state.lastVotedKey2);
  
      if (data && data.challengesVoted) {
        this.setState({
          lastVotedKey1: data.lastVotedKey1,
          lastVotedKey2: data.lastVotedKey2,
          challengesVoted: this.state.challengesVoted.concat(data.challengesVoted),
        });
      }
    } catch (err) {
      // console.log(err);
    }
  };  

  htmlDecode = (input) => {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  expand = () => {
    if (window.innerWidth < 601) {
      this.setState({ panel1: !this.state.panel1 })
    }
  }

  componentDidUpdate(prev) {
    if (this.state.handle && (this.props.userHandleUrl !== prev.userHandleUrl)) {
      this.setState({
        challengesRequested: this.props.challengesRequested,
        challengesCompleted: this.props.challengesCompleted,
        challengesVoted: this.props.challengesVoted,
        challengesCreated: this.props.challengesCreated,
        lastCreatedKey1: this.props.lastCreatedKey1,
        lastCreatedKey2: this.props.lastCreatedKey2,
        lastRequestedKey1: this.props.lastRequestedKey1,
        lastRequestedKey2: this.props.lastRequestedKey2,
        lastCompletedKey1: this.props.lastCompletedKey1,
        lastCompletedKey2: this.props.lastCompletedKey2,
        handle: this.props.userHandleUrl,
        currentlyFollowing: this.props.currentlyFollowing
      })
    }
    if (this.props.openProfile !== prev.openProfile) {
      if (this.props.openProfile === 'true') {
        this.setState({ hiddenProfile: true })
        setTimeout(() => {
          this.setState({ hideProfile: false })
        }, 500);
        setTimeout(() => {
          this.setState({ hiddenProfile: false })
        }, 1500);
      }
      else
        this.setState({ hideProfile: true })
    }
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({ socialName: user.displayName });
        this.setState({ socialImg: user.photoURL });
      }
    })
    this.setState({
      challengesRequested: this.props.challengesRequested,
      challengesCompleted: this.props.challengesCompleted,
      challengesVoted: this.props.challengesVoted,
      challengesCreated: this.props.challengesCreated,
      lastCreatedKey1: this.props.lastCreatedKey1,
      lastCreatedKey2: this.props.lastCreatedKey2,
      lastRequestedKey1: this.props.lastRequestedKey1,
      lastRequestedKey2: this.props.lastRequestedKey2,
      lastCompletedKey1: this.props.lastCompletedKey1,
      lastCompletedKey2: this.props.lastCompletedKey2,
      handle: this.props.userHandleUrl,
      currentlyFollowing: this.props.currentlyFollowing
    })
    setTimeout(() => {
      if (window.innerWidth < 960)
        this.setState({ hideProfile: true })
    }, 5000);
  }

  render() {
    const {
      classes,
      user: {
        credentials: { handle },
        authenticated
      }
    } = this.props;
    const { loading } = this.props.data;
    let recentChallengesMarkup = !loading ? (
      this.state.challengesRequested && this.state.challengesRequested
        .filter((chall) => {
          return (chall.status === 'created')
        })
        .map((challenge) => <Challenge requested={true} profile='true' key={challenge.challengeId} challenge={challenge} />)
    ) : (
      <ChallengeSkeleton />
    );

    let acceptedChallengesMarkup = !loading ? (
      this.state.challengesRequested && this.state.challengesRequested
        .filter((chall) => {
          return (chall.status === 'accepted')
        })
        .map((challenge) => <Challenge profile='true' key={challenge.challengeId} challenge={challenge} />)
    ) : (
      <ChallengeSkeleton />
    );

    let recentChallengesHistory = !loading ? (
      this.state.challengesCompleted && this.state.challengesCompleted
        .map((challenge) => <Challenge profile='true' key={challenge.challengeId} challenge={challenge} />)
    ) : (
      <ChallengeSkeleton />
    );

    let votedChallenges = !loading ? (
      this.state.challengesVoted && this.state.challengesVoted
        .map((challenge) => <Challenge profile='true' key={challenge.challengeId} challenge={challenge} />)
    ) : (
      <ChallengeSkeleton />
    );

    let recentChallengesSent = !loading ? (
      this.state.challengesCreated && this.state.challengesCreated
        .sort((a, b) => {
          return b.initialCreatorPaymentAmount - a.initialCreatorPaymentAmount;
        })
        .map((challenge) => <Challenge profile='true' key={challenge.challengeId} challenge={challenge} />)
    ) : (
      <ChallengeSkeleton />
    );

    // let recentChallengesHistory = !loading ? (
    //   this.props.challengesRequested && this.props.challengesRequested
    //   .filter((chall)=> {
    //     return (chall.status === 'completed')
    //   })
    //   .sort((a, b)=> {
    //     return b.initialCreatorPaymentAmount - a.initialCreatorPaymentAmount;
    //   })
    //   .map((challenge) => <Challenge profile='true' key={challenge.challengeId} challenge={challenge} />)
    // ) : (
    //   <ChallengeSkeleton />
    // );

    return (
      <Paper className={classes.paper}>
        <div className={`${classes.profile} ${this.state.hiddenProfile ? classes.hiddenProfile : ''} ${this.state.hideProfile ? classes.hideProfile : ''}`}>
          <div className={classes.userHead}>
            {this.props.twitchProfile && this.props.twitchProfile.profile_image_url ?
            <div className={(`image-wrapper`, classes.userIcon)}>
              <img src={this.props.twitchProfile && this.props.twitchProfile.profile_image_url} alt="profile" className="profile-image" />
            </div>
            :
            this.props.imageUrl ?
            <div className={`image-wrapper ${classes.userIcon}`}>
              <img src={this.props.imageUrl} alt="profile" className="profile-image" />
            </div>
            :
            <img
              src={mascot}
              alt="profile"
              className={classes.profileImage}
              onClick={this.menuClick}
            />
            }
            <div className={classes.userMeta}>
              <MuiLink
                component={Link}
                to={`/users/${this.props.userHandleUrl || this.state.socialName}`}
                variant="h5"
                underline="hover">
                {this.props.displayName || this.props.userHandleUrl || this.state.socialName}
              </MuiLink>
              <Typography variant="body1">{this.props.followerCount || 0} Followers</Typography>
              <Typography variant="body1">{this.props.challengeCount || 0} Challenges Completed</Typography>
              {/* <Typography variant="body1">{this.props.challengesAccepted || this.props.challengeCount} Challenges Accepted</Typography> */}
            </div>
            <div className={classes.buttonProfileWrap}>
              <Link 
                to={`/users/${this.props.userHandleUrl || this.state.socialName}/donate`}                
                state={{from: window.location}}
              >
                <Button
                  variant="contained"
                  className={classes.donate}
                  
                >
                  Donate
                </Button>
              </Link>
              {this.props.twitchProfile ?
                <Button className={classes.toggleStream} variant="contained" onClick={this.props.toggleStream}>
                  Toggle Stream
                </Button>
                :
                <div></div>
              }
              {this.props.userHandleUrl !== handle ?
                  !authenticated ?
                  <Link 
                    to={`/login`}                
                    state={{from: window.location}}
                  >
                    <Button
                      variant="contained"
                      className={classes.follow}
                      
                    >
                      Sign in
                    </Button>
                  </Link>
                :
                !this.state.currentlyFollowing ?
                <Button className={classes.follow} variant="contained" onClick={this.follow}>
                  <img src={followIcon} alt="create" className={classes.plusIcon}/>
                  Follow
                </Button> :
                <Button className={classes.unfollow} variant="outlined" onClick={this.unfollow}>
                  Unfollow
                </Button> :
                <div></div>
              }
          </div>
          </div>
          <hr />
          <div className="profile-details">
            <AppBar className={classes.tabHeader} position="static">
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleChange}
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabHeader}
                aria-label="scrollable auto tabs example"
              >
                <Tab label="About" {...this.a11yProps(0)} />
                <Tab label="Challenges" {...this.a11yProps(1)} />
                <Tab label="Challenges Sent" {...this.a11yProps(2)} />
                <Tab label="Vote" {...this.a11yProps(3)} />
                <Tab label="History" {...this.a11yProps(4)} />
              </Tabs>
            </AppBar>
            {/* <OutlinedInput
            id="standard-adornment-amount"
            name='query'
            variant='outline'
            className={classes.searchInput}
            startAdornment={
              <InputAdornment position="start">
                <img 
                  src={search} 
                  alt="Meed"
                  className={classes.logo} 
                />
              </InputAdornment>
            }
          /> */}
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={0}>
              {this.props.bio ?
                this.htmlDecode(this.props.bio) :
                this.props.twitchProfile ? this.props.twitchProfile.description :
                  `Did you know that you can easily link your Twitch account to Meed and have your stream show up here?
               Doing so will make it easier for your fans to make Challenges for you while still watching your stream and chatting away with Meed’s built-in support for Twitch chat!
                To connect your account, head over to “Settings” and use the “Connect Twitch Account” card. Once connected, your Twitch biography will automatically appear on Meed!`
              }
              <br></br>
              {this.props.socialLinkFacebook ?
                <Typography
                  component={Link}
                  variant='body1'
                  to={`${this.props.socialLinkFacebook ? this.props.socialLinkFacebook : ''}`}>
                  Facebook
                </Typography>
                :
                <span></span>
              }
              {this.props.socialLinkInstagram ?
                <Typography
                  component={Link}
                  variant='body1'
                  to={`${this.props.socialLinkInstagram ? this.props.socialLinkInstagram : ''}`}>
                  Instagram
                </Typography>
                :
                <span></span>
              }
              {this.props.socialLinkYoutube ?
                <Typography
                  component={Link}
                  variant='body1'
                  to={`${this.props.socialLinkYoutube ? this.props.socialLinkYoutube : ''}`}>
                  Youtube
                </Typography>
                :
                <span></span>
              }
              {this.props.socialLinkTwitter ?
                <Typography
                  component={Link}
                  variant='body1'
                  to={`${this.props.socialLinkTwitter ? this.props.socialLinkTwitter : ''}`}>
                  Twitter
                </Typography>
                :
                <span></span>
              }
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={1}>
              <Grid className={classes.challs} container>
                <h2 className={classes.sectionTitle}>Challenges</h2>
                <div className={classes.challsBox}>
                  {acceptedChallengesMarkup}
                  {recentChallengesMarkup}
                  <Button disabled={!this.state.lastRequestedKey1 && !this.state.lastRequestedKey2} className={classes.moreChalls} variant="outlined" onClick={this.moreRequestedChallenges}>
                    {!this.state.lastRequestedKey1 && !this.state.lastRequestedKey2 ?
                      'No More Challenges' : 'More Challenges'
                    }
                  </Button>
                </div>

              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={2}>
              <Grid className={classes.challs} container>
                <h2 className={classes.sectionTitle}>Challenges Sent</h2>
                <div className={classes.challsBox}>
                  {recentChallengesSent}
                  <Button disabled={!this.state.lastCreatedKey1 && !this.state.lastCreatedKey2} className={classes.moreChalls} variant="outlined" onClick={this.moreSentChallenges}>
                    {!this.state.lastCreatedKey1 && !this.state.lastCreatedKey2 ?
                      'No More Challenges' : 'More Challenges'
                    }
                  </Button>
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={3}>
              <Grid className={classes.challs} container>
                <h2 className={classes.sectionTitle}>Challenge Voting</h2>
                <div className={classes.challsBox}>
                  {votedChallenges}
                  <Button disabled={!this.state.lastVotedKey1 && !this.state.lastVotedKey2} className={classes.moreChalls} variant="outlined" onClick={this.moreVotedChallenges}>
                    {!this.state.lastVotedKey1 && !this.state.lastVotedKey2 ?
                      'No More Challenges' : 'More Challenges'
                    }
                  </Button>
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={4}>
              <Grid className={classes.challs} container>
                <h2 className={classes.sectionTitle}>Voting</h2>
                <div className={classes.challsBox}>
                  {votedChallenges}
                  <Button disabled={!this.state.lastVotedKey1 && !this.state.lastVotedKey2} className={classes.moreChalls} variant="outlined" onClick={this.moreHistoricChallenges}>
                    {!this.state.lastVotedKey1 && !this.state.lastVotedKey2 ?
                      'No More Challenges' : 'More Challenges'
                    }
                  </Button>
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={this.state.tabValue} className={classes.tabPanel} index={4}>
              <Grid className={classes.challs} container>
                <h2 className={classes.sectionTitle}>Challenge History</h2>
                <div className={classes.challsBox}>
                  {recentChallengesHistory}
                  <Button disabled={!this.state.lastCompletedKey1 && !this.state.lastCompletedKey2} className={classes.moreChalls} variant="outlined" onClick={this.moreHistoricChallenges}>
                    {!this.state.lastCompletedKey1 && !this.state.lastCompletedKey2 ?
                      'No More Challenges' : 'More Challenges'
                    }
                  </Button>
                </div>
              </Grid>
            </TabPanel>


            {/* Expansion Panel Begin */}


            <Accordion expanded={this.state.panel1} className={classes.accordian2}>
              <AccordionSummary className={classes.AccordionSummary}>
                <Typography className={classes.heading}>About</Typography>
              </AccordionSummary>
              <AccordionDetails onClick={window.innerWidth < 601 ? this.expand : this.doNothing} className={classes.AccordionDetails}>
                <Typography>
                  {this.props.bio ?
                    this.htmlDecode(this.props.bio) :
                    this.props.twitchProfile && this.props.twitchProfile.description !== '' ? this.props.twitchProfile.description :
                      `Did you know that you can easily link your Twitch account to Meed and have your stream show up here?
               Doing so will make it easier for your fans to make Challenges for you while still watching your stream and chatting away with Meed’s built-in support for Twitch chat!
                To connect your account, head over to “Settings” and use the “Connect Twitch Account” card. Once connected, your Twitch biography will automatically appear on Meed!`
                  }
                  <br></br>
                  {this.props.socialLinkFacebook ?
                    <Typography
                      component={Link}
                      variant='body1'
                      to={`${this.props.socialLinkFacebook ? this.props.socialLinkFacebook : ''}`}>
                      Facebook
                    </Typography>
                    :
                    <span></span>
                  }
                  {this.props.socialLinkInstagram ?
                    <Typography
                      component={Link}
                      variant='body1'
                      to={`${this.props.socialLinkInstagram ? this.props.socialLinkInstagram : ''}`}>
                      Instagram
                    </Typography>
                    :
                    <span></span>
                  }
                  {this.props.socialLinkYoutube ?
                    <Typography
                      component={Link}
                      variant='body1'
                      to={`${this.props.socialLinkYoutube ? this.props.socialLinkYoutube : ''}`}>
                      Youtube
                    </Typography>
                    :
                    <span></span>
                  }
                  {this.props.socialLinkTwitter ?
                    <Typography
                      component={Link}
                      variant='body1'
                      to={`${this.props.socialLinkTwitter ? this.props.socialLinkTwitter : ''}`}>
                      Twitter
                    </Typography>
                    :
                    <span></span>
                  }
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordian}>
              <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Challenges Received</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetails}>
                <Typography>
                  <h2>Challenges Received</h2>
                  <div className={classes.challsBox}>
                    {acceptedChallengesMarkup}
                    {recentChallengesMarkup}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordian}>
              <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Challenges Sent</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetails}>
                <Typography>
                  <h2>Challenges Sent</h2>
                  <div className={classes.challsBox}>
                    {recentChallengesSent}

                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordian}>
              <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Challenges History</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetails}>
                <Typography>
                  <h2>Challenge History</h2>
                  <div className={classes.challsBox}>
                    {recentChallengesHistory}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <hr />
          </div>
        </div>
      </Paper>
    );
  }
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapActionsToProps = {
  logoutUser, AuthenticateWithTwitch, follow, unfollow, moreRequestedChallenges,
  moreSentChallenges,
  moreHistoricChallenges,
  moreVotedChallenges
};

StaticProfile.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  follow: PropTypes.func.isRequired,
  unfollow: PropTypes.func.isRequired,
  moreRequestedChallenges: PropTypes.func.isRequired,
  moreSentChallenges: PropTypes.func.isRequired,
  moreHistoricChallenges: PropTypes.func.isRequired,
  moreVotedChallenges: PropTypes.func.isRequired,
  AuthenticateWithTwitch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(StaticProfileStyles)(StaticProfile));
