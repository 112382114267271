// Importing the background image if needed, otherwise remove this line
import berryBg from '../../images/berryBg-removebg.png';

const NotFoundstyles = (theme) => ({
    // ...theme.spreadThis, // If you have common styles defined in your theme, make sure to spread them here
    ...theme.custom,
    notFoundContainer: {
        position: 'absolute', // Adjust if needed to align with your layout
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Added for vertical alignment
        left: 0,
        top: 0, // Updated to start from the very top
        width: '100%',
        height: '100vh', // Updated to cover full view height
        textAlign: 'center',
        padding: theme.spacing[3],
        backgroundImage: `url(${berryBg})`, // Assuming you want to keep the background image
        backgroundSize: 'cover', // Ensure the background covers the full page
        backgroundPosition: 'center', // Center the background image
    },
    notFoundText: {
        fontSize: '10rem', // Adjust the font size as needed
        fontWeight: 'bold',
        color: theme.palette.primary.main, // Use the primary color from your theme
        marginBottom: theme.spacing[4],
    },
    explanationText: {
        fontSize: '1.5rem',
        color: theme.palette.text.primary, // Use the text color from your theme
        marginBottom: theme.spacing[4],
    },
    textContainer: {
        backgroundColor: '#fff', // Solid white background
        padding: theme.spacing.unit * 4, // Increased padding for more space around contents
        borderRadius: theme.spacing.unit * 2, // Optional: adds rounded corners to the background
        maxWidth: '75%', // Maximum width of the container to 75% of its parent
        margin: 'auto', // Center the container
        display: 'flex', // Use flexbox to handle inner layout
        flexDirection: 'column', // Stack children vertically
        alignItems: 'center', // Center-align the items horizontally
        textAlign: 'center', // Center-align the text
    },
    buttonContainer: { // A new style for the container of the buttons
        display: 'flex',
        justifyContent: 'center', // This centers the buttons in the container
        marginTop: theme.spacing.unit * 2, // Add some top margin to space it from the text
    },
    button: {
        margin: theme.spacing.unit, // Spacing between buttons
        borderRadius: '20px', // This gives the buttons a rounded appearance
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`, // Adjust padding as needed
        textTransform: 'none', // Prevents uppercase transformation of button text
        backgroundColor: '#5E2A84', // Example dark purple color
        color: '#fff', // White text to contrast the dark background
        '&:hover': {
            backgroundColor: '#4B0082', // A slightly different shade for hover state
        },
        // ... other button styles you might have
    },
    returnHomeButton: {
        // This class only needs to modify what's different from `.button`
        '&:hover': {
            backgroundColor: '#3C2872', // A slightly different shade for hover state
        },
    },
    reportProblemButton: {
        // Since it's outlined, we don't need to set the background color here
        borderColor: '#5E2A84', // If you want to set a specific color for the border
        '&:hover': {
            borderColor: '#4B0082', // A slightly different shade for hover state
            // backgroundColor: 'transparent', // Maintain the outlined style on hover
        },
    },
    searchUserButton: {
        // backgroundColor: '#9C77B9', // A lighter purple, different from the return home button
        '&:hover': {
            backgroundColor: '#8A5DA8', // A slightly different shade for hover state
        },
    },
    // Your other styles like buttonDefault, label, gang etc. can remain unchanged if they are still required
});

export default NotFoundstyles;
