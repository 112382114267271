import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Challenge from '../components/challenge/Challenge';
import { getChallengeById } from '../redux/actions/dataActions';
import PostChallenge from '../components/challenge/PostChallenge';
import SearchChallenge from '../components/challenge/SearchChallenge';
import StaticProfile from '../components/profile/StaticProfile';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import ProfileSkeleton from '../util/ProfileSkeleton';

import { connect } from 'react-redux';
import { getUserProfileData, getUserQueueData } from '../redux/actions/userActions';
import { getChallenge } from '../redux/actions/dataActions';
import { fulfillMeedPoints } from '../redux/actions/userActions';

import { withStyles } from '@mui/styles';
import { toast } from 'react-toastify';

import imageUrl from '../images/plusWhite.png';
import searchIcon from '../images/searchWhite2x.png';
import personIcon from '../images/no-img.png';
import whiteLink from '../images/whiteLink2.png';

import userStyles from './styles/userStyles';

const auth = getAuth();
let gettingChall = false;
let userInterval;
let viewing = false;

const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

/**
 * Filters the provided list of challenges to return only those that are accepted and match the given active state.
 * This function is useful for isolating a subset of challenges based on their status and an external condition (e.g., an active state).
 *
 * @param {Array} challenges - The list of challenge objects to filter.
 * @param {boolean} stateActive - A boolean value representing the external condition (e.g., whether a certain feature or state is active).
 * @return {Array} An array of challenge objects that are both accepted and match the given active state.
 */
const filterAcceptedChallenges = (challenges, stateActive) => {
  return challenges.filter(chall => chall.status === 'accepted' && stateActive) || [];
};

/**
 * Returns a list of challenges that are in the 'challengesAfter' list but not in the 'challengesBefore' list.
 * This helps identify newly completed challenges.
 * 
 * @function
 * @param {Array} challengesAfter - The list of challenges after the update.
 * @param {Array} challengesBefore - The list of challenges before the update.
 * @returns {Array} An array of challenges that are new in the 'challengesAfter' list.
 */
const getNewCompletedChallenges = (challengesAfter, challengesBefore) => {
  return challengesAfter.filter(challAfter =>
    !challengesBefore.some(challBefore => challAfter.challengeId === challBefore.challengeId)
  );
}

/**
 * Sorts challenges based on the provided sorting criterion.
 * The function compares two challenge objects and sorts them according to
 * the specified sorting preference.
 *
 * @param {Object} a - The first challenge object to compare.
 * @param {Object} b - The second challenge object to compare.
 * @param {string} selectedValue - The selected sorting criterion from the component's state.
 * @returns {number} - Returns a negative value if 'a' should come before 'b',
 *                     a positive value if 'a' should come after 'b', or
 *                     zero if they are considered equivalent for the sort order.
 *
 * The sorting criteria are:
 * - 'timeRemaining': Sorts by the creation date, with newer challenges first.
 * - 'totalUpvotes': Sorts by the like count, with more liked challenges first.
 * - Default: Sorts primarily by the initial creator payment amount in descending order,
 *            and secondarily by challenge name in ascending order if payments are equal.
 */
const sortChallenges = (a, b, selectedValue) => {
  if (selectedValue === 'timeRemaining')
    return new Date(a.createdAt) - new Date(b.createdAt);
  else if (selectedValue === 'totalUpvotes')
    return b.likeCount - a.likeCount;
  else
    return cmp(b.initialCreatorPaymentAmount, a.initialCreatorPaymentAmount) || cmp(a.challengeName, b.challengeName);
};

/**
 * A basic comparison function for sorting purposes.
 * Compares two values and returns an integer based on their relation.
 *
 * @param {any} x - The first value to compare. Can be of any type that supports comparison operators.
 * @param {any} y - The second value to compare. Can be of any type that supports comparison operators.
 * @returns {number} - Returns 1 if 'x' is greater than 'y', -1 if 'x' is less than 'y', 
 *                     or 0 if they are equal.
 */
const cmp = (x, y) => {
  return x > y ? 1 : (x < y ? -1 : 0);
}

class user extends Component {
  state = {
    profile: {},
    challengeIdParam: '',
    clickedChallenge: {},
    payAmount: '',
    handle: '',
    matches: window.matchMedia("(min-width: 768px)").matches,
    Twitch: window.Twitch,
    embed: {},
    twitchFrameParent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"],
    challengePanelOpen: false,
    challengeCreatePanelOpen: false,
    challengeSearchPanelOpen: false,
    chatPanel: true,
    chatPanelMobile: true,
    activeChallId: '',
    challengesQueue: [],
    challengesCreated: [],
    challengesRequested: [],
    challengesCompleted: [],
    challengesVoted: [],
    fetched: false,
    totalUpvotes: false,
    money: false,
    timeRemaining: false,
    stateOpen: true,
    stateActive: true,
    stateFlagged: true,
    acceptTimeSmall: true,
    acceptTimeMedium: true,
    acceptTimeLarge: true,
    hidden: true,
    authed: false,
    hideStream: false,
    celebrate: false,
  };

  getData = async () => {
    const handle = this.props.match.params.handle;
    const challengeId = this.props.match.params.challengeId;
    const paymentStatus = this.props.match.params.paymentSuccess || this.props.match.params.paymentFailure;
    const handler = e => this.setState({ matches: e.matches });
    const { challengesRequested } = this.state;

    //handle && await this.fetchUser(handle.toLowerCase());
    await onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.fetchUser(handle.toLowerCase())
      } else {
        //user is signed out
        this.fetchUser(handle.toLowerCase())
      }
    });
    window.matchMedia("(min-width: 768px)").addListener(handler);
    this.setState({ handle: handle });
    if (challengeId && challengeId !== 'paymentSuccessful' && challengesRequested) {
      this.setState({
        challengeId: challengeId,
        challengePanelOpen: true,
        clickedChallenge: challengesRequested.filter((challenge) => { return challenge.challengeId === challengeId })[0]
      });
      if (challengeId)
        await this.props.getChallengeById(challengeId).then((chall) => {
          this.setState({ clickedChallenge: chall });
          this.viewChallenge(chall);
        });
    } else if (challengesRequested && challengesRequested.length < 1) {
      if (challengeId)
        await this.props.getChallengeById(challengeId).then((chall) => {
          this.setState({ clickedChallenge: chall });
        });
    }

    if (this.state.profile && !challengeId && this.state.profile.twitch && this.state.hideStream) {
      this.setState({ chatPanel: true });
    }

    if (paymentStatus === 'paymentSuccessful' || challengeId === 'paymentSuccessful') {
      // create stripe checkout session
      this.props.fulfillMeedPoints({
        approved: true
      }).then(res => {
        toast.success('SUCCESS: Funds Added!');
        this.props.history.push(`/users/${this.props.match.params.handle}`)
      }).catch(err => {
        toast.error('Error: Funds did not get added!');
      });

    } else if (paymentStatus === 'paymentFailure') {
      this.props.fulfillMeedPoints({
        approved: false
      })
      toast.error('PAYMENT FAILED!');
    }
    // Function to refresh user data
    const refreshData = () => {
      // Calls the refreshUser method with the current user's handle
      // This is expected to update the user's data from the server
      this.refreshUser(this.props.match.params.handle);
    };

    // Check if the interval for refreshing data is not already set
    if (!userInterval) {
      // Set an interval to call refreshData every 60 seconds
      // This ensures our data stays fresh while the user is on the page
      userInterval = setInterval(refreshData, 60000);
    }

    // Function to handle visibility state changes of the page
    const handleVisibilityChange = () => {
      // When the page becomes visible to the user
      if (document.visibilityState === "visible") {
        // Immediately call refreshData to update the information
        // refreshData();
        // Check if our interval is cleared and set it if necessary
        // This re-establishes regular data fetching when the page is in focus
        if (!userInterval) {
          userInterval = setInterval(refreshData, 60000);
        }
      } else {
        // If the page is not visible, clear the interval to stop fetching data
        // This optimizes resource usage when the user is not actively viewing the page
        clearInterval(userInterval);
        userInterval = null;
      }
    };

    // Add an event listener for detecting visibility state changes
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }

  /**
   * Refreshes user data and handles UI updates for challenge events.
   * Triggers toasts for new accepted and completed challenges.
   * 
   * @async
   * @function
   * @param {string} handle - The user's handle.
   * @throws Will throw an error if the request to getUserQueueData fails.
   */
  refreshUser = async (handle) => {
    try {
      const { stateActive, selectedValue } = this.state;
      // Fetch the latest user queue data
      const res = await this.props.getUserQueueData(handle);

      // If no response, exit the function
      if (!res) return;

      // Process the response to get new and updated challenge lists
      const acceptedChallengesBefore = filterAcceptedChallenges(this.state.challengesQueue, stateActive);
      const acceptedChallengesAfter = filterAcceptedChallenges(res.challengesRequested, stateActive);
      const newAcceptedChallenges = acceptedChallengesAfter.filter(challenge =>
        !acceptedChallengesBefore.some(previousChallenge => previousChallenge.challengeId === challenge.challengeId)
      );

      // Process new completed challenges, assuming getNewCompletedChallenges is defined similarly
      const newCompletedChallenges = getNewCompletedChallenges(res.challengesVoted, this.state.challengesVoted);

      // Update the state with the latest data from the response
      this.setState({
        challengesQueue: res.challengesRequested.sort((a, b) => sortChallenges(a, b, selectedValue)),
        challengesCreated: res.challengesCreated, // Assuming this needs to be updated
        challengesCompleted: res.challengesCompleted, // Update with the latest completed challenges
        challengesVoted: res.challengesVoted, // Update with the latest voted challenges
        challengesRequested: res.challengesRequested,
        // Grouped keys for created challenges
        lastCreatedKey1: res.keys.lastCreatedKey1,
        lastCreatedKey2: res.keys.lastCreatedKey2,

        // Grouped keys for requested challenges
        lastRequestedKey1: res.keys.lastRequestedKey1,
        lastRequestedKey2: res.keys.lastRequestedKey2,

        // Grouped keys for completed challenges
        lastCompletedKey1: res.keys.lastCompletedKey1,
        lastCompletedKey2: res.keys.lastCompletedKey2,

        // Grouped keys for voted challenges
        lastVotedKey1: res.keys.lastVotedKey1,
        lastVotedKey2: res.keys.lastVotedKey2,
      });

      // Handle new accepted challenges (if any)
      newAcceptedChallenges.forEach(challenge => {
        toast.success(`${handle} just accepted a challenge! Check the Challenge Queue to see: "${htmlDecode(challenge.challengeName)}"`, { autoClose: false });
      });

      // Handle new completed challenges (if any)
      newCompletedChallenges.forEach(challenge => {
        toast.success(`${handle} just moved a challenge to the voting stage! Check the voting tab to see: ${htmlDecode(challenge.challengeName)}`, { autoClose: false });
      });

      // Optionally handle celebration state based on new completions
      if (newCompletedChallenges.length > 0) {
        this.setState({ celebrate: true });
        setTimeout(() => {
          this.setState({ celebrate: false });
        }, 5000); // Adjust duration as needed
      }

    } catch (error) {
      console.error("Error refreshing user data:", error);
      // Handle the error appropriately
    }
  }

  updateChallenge = async (id) => {
    const { challengesQueue } = this.state;

    await this.refreshUser(this.props.match.params.handle)
    if (challengesQueue && challengesQueue.filter((chall) => chall.challengeId === id).length)
      this.setState({
        clickedChallenge: challengesQueue.filter((chall) => chall.challengeId === id)[0]
      })
    else
      this.setState({
        clickedChallenge: {},
        chatPanel: true,
        challengePanelOpen: false,
      })
  }

  getClass = async (yup) => {
    await setTimeout(() => {
      this.setState({
        moveInLeft: true
      })
    }, 250);
    return '';
  }

  fetchUser = async (handle) => {
    try {
      let userAuthed = (this.props.user && this.props.user.authenticated) || null;
      // Use firebase.auth().currentUserId (or w/e)?
      const userId = this.props.user.credentials.userId;

      const res = await this.props.getUserProfileData(handle, userAuthed, userId);
      // Based on static profile, this is likely where the challenges get populated, so move up here?
      // Or will that not matter with how code executes?
      if (res && res.user) {

        this.setState({
          profile: res.user,
          profile2: res.userData,
          challengesQueue: res.challengesRequested,
          challengesCreated: res.challengesCreated,
          challengesCompleted: res.challengesCompleted,
          challengesRequested: res.challengesRequested,
          challengesVoted: res.challengesVoted,
          // Grouped keys for created challenges
          lastCreatedKey1: res.keys.lastCreatedKey1,
          lastCreatedKey2: res.keys.lastCreatedKey2,

          // Grouped keys for requested challenges
          lastRequestedKey1: res.keys.lastRequestedKey1,
          lastRequestedKey2: res.keys.lastRequestedKey2,

          // Grouped keys for completed challenges
          lastCompletedKey1: res.keys.lastCompletedKey1,
          lastCompletedKey2: res.keys.lastCompletedKey2,

          // Grouped keys for voted challenges
          lastVotedKey1: res.keys.lastVotedKey1,
          lastVotedKey2: res.keys.lastVotedKey2,
          fetched: true,
          openProfile: 'bruh',
          modStatus: res.modStatus,
        });
        // Get values to use with mod check
        /*
        // const influencerId = this.state.profile.userId; // Adjust this to get the correct influencer ID
        const influencerId = res.user.userId;
        ; // Adjust to get the logged-in user's ID
        console.log({ userId, influencerId });
        // Dispatch the action to check moderator status
        await this.props.checkUserModStatus(userId, influencerId);
        */
      } else {
        // No user matching the name, redirects to the 404 page
        this.props.history.push('/not-found');
      }

      if (res && res.user && res.user.twitch) {
        // Clean up any existing Twitch Embed instance before creating a new one
        if (this.state.embed && typeof this.state.embed.destroy === 'function') {
          this.state.embed.destroy();
        }

        // Create a new Twitch Embed instance
        const newEmbed = new this.state.Twitch.Embed("twitch-embed", {
          width: '100%',
          height: '100%',
          layout: 'video',
          muted: true,
          autoplay: this.props.user.credentials.handle !== handle,
          channel: res.user.twitch.login || 'meeddotapp',
          parent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"]
        });

        // Update the state with the new Twitch Embed instance
        this.setState({ embed: newEmbed });
      }


    } catch (err) {
      console.log("This is indeed the error")
      // console.log(err);
      // TODO: Handle what to do when no user exists?
      this.props.history.push('/not-found');
    }
  }

  openProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.innerWidth < 960 && this.state.profile.twitch && !this.state.hideStream) {
      this.setState({ openProfile: 'true' })
      setTimeout(() => this.setState({ openProfile: 'bruh' }), 10000)
    }
  }
  // No longer used.
  closeChat = () => {
    if (window.innerWidth < 960) {
      this.setState({ openProfile: 'true' })
      setTimeout(() => this.setState({ openProfile: 'bruh' }), 10000)
      return;
    }
    if ((this.state.profile && !this.state.profile.twitch) || this.state.hideStream)
      return;
    this.setState({
      moveInLeft: false,
      chatPanel: !this.state.chatPanel,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
      hidden: true,
    });
    this.activeChall('')
  }

  /**
   * Shares the current page using the Web Share API.
   * This method is asynchronous and uses the navigator.share method to share the page.
   * It falls back to a console message if the Web Share API is not supported.
   * Uses state and props to personalize the share message, with fallbacks if they are not available.
   */
  sharePage = async () => {
    // Default values for title and text
    const defaultTitle = "User Page";
    const defaultText = "Check out this interesting user page";
    const { profile } = this.state;

    try {
      // Check if the navigator.share is supported
      if (navigator.share) {
        await navigator.share({
          title: profile && profile.handle ?
            `${profile.handle}'s user page` : defaultTitle,
          text: profile && profile.handle ?
            `Check out ${profile.handle}'s user page` : defaultText,
          url: window.location.href, // Share the current URL
        });

        console.log('Successful share');
      } else {
        // Fallback for browsers that don't support navigator.share
        console.log('Your browser does not support Web Share API');
      }
    } catch (error) {
      console.log('Error sharing', error);
    }
  };

  toggleStream = () => {
    this.setState((prevState) => ({
      chatPanel: true,
      hideStream: !prevState.hideStream
    }), () => {
      if (!this.state.hideStream) {
        // Clean up any existing Twitch Embed instance before creating a new one
        if (this.state.embed && typeof this.state.embed.destroy === 'function') {
          this.state.embed.destroy();
        }

        // Create a new Twitch Embed instance
        const newEmbed = new this.state.Twitch.Embed("twitch-embed", {
          width: '100%',
          height: '100%',
          layout: 'video',
          muted: true,
          channel: this.state.profile.twitch ? this.state.profile.twitch.login : 'meeddotapp',
          parent: ["localhost", "meed.app", "covenant-28c21.web.app", "covenant-28c21.firebaseapp.com"]
        });

        // Update the state with the new Twitch Embed instance
        this.setState({ embed: newEmbed });
      }
    });
  }

  viewChallenges = () => {
    this.setState({
      hidden: false,
    });
  }

  hideChallenges = () => {
    this.setState({
      hidden: true,
    });
  }

  handleChallengeClose = (event) => {
    this.setState({
      chatPanel: !this.state.chatPanel,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
    });
    this.activeChall('')
  }

  backToTop = (e) => {
    window.scrollTo(0, 0);
  };

  viewChallenge = async (challenge) => {
    if (viewing)
      return;
    viewing = true;
    setTimeout(() => {
      viewing = false;
    }, 1000);
    await this.setState({
      moveInLeft: false,
      challengeId: challenge.challengeId || challenge.objectID,
      clickedChallenge: challenge,
      challengePanelOpen: true,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: false,
      chatPanel: false,
      hidden: true
    });
  }

  searchChallenge = () => {
    this.setState({
      moveInLeft: false,
      challengeCreatePanelOpen: false,
      challengeSearchPanelOpen: true,
      challengePanelOpen: false,
      chatPanel: false,
      hidden: true
    });
    this.activeChall('')
  }

  createChallenge = () => {
    this.setState({
      moveInLeft: false,
      challengeCreatePanelOpen: true,
      challengeSearchPanelOpen: false,
      challengePanelOpen: false,
      chatPanel: false,
      hidden: true
    });
    this.activeChall('')
  }

  activateChall = async (id) => {
    if (gettingChall)
      return;
    gettingChall = true;
    if (id !== this.state.activeChallId) {
      this.setState({
        activeChallId: id
      });
    }
    setTimeout(() => {
      gettingChall = false;
    }, 400);
  }

  activeChall = async (id, action) => {
    if (gettingChall)
      return;
    gettingChall = true;
    if (id !== this.state.activeChallId) {
      this.setState({
        activeChallId: id
      });
    }
    if (action) {
      this.setState({
        action: action
      });
    }
    setTimeout(() => {
      gettingChall = false;
    }, 400);
  }

  handleCheck = (e) => {
    this.setState({ selectedValue: e.target.value });
  };

  handleFilterCheck = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  // filterChallenges = (chall) => {
  //   if (this.state.stateOpen)
  //     return (this.state.stateOpen === 'accepted')
  // }

  componentDidUpdate(prev) {
    try {
      if (this.state.handle && (this.props.match.params.handle !== prev.match.params.handle)) {
        this.getData();
        this.setState({
          clickedChallenge: {},
          challengePanelOpen: false,
          hideStream: false
        })
      }
    } catch (error) {
      console.log("Error loading user page: ", error)
    }
    if (prev.modStatuses !== this.props.modStatuses) {
      console.log('modStatuses after update:', this.props.modStatuses);
    }
  }

  /**
   * Creates and appends confetti elements to the 'confetti-wrapper' element in the DOM.
   * This method generates a specified number of confetti pieces, each with random positioning,
   * rotation, color, and animation delay. It's used to add a visual celebration effect on the page.
   *
   * Confetti properties:
   * - Random rotation for diverse orientations.
   * - Randomly positioned within the viewport's width and height.
   * - Colored randomly from a predefined set of colors.
   * - Animation delay varied for a staggered falling effect.
   *
   * @function
   */
  createConfetti = () => {
    for (let i = 0; i < 100; i++) {
      var randomRotation = Math.floor(Math.random() * 360);
      var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
      var randomHeight = Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500));
      var randomAnimationDelay = Math.floor(Math.random() * 15);
      var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0'];
      var randomColor = colors[Math.floor(Math.random() * colors.length)];

      var confetti = document.createElement('div');
      confetti.className = 'confetti';
      confetti.style.top = randomHeight + 'px';
      confetti.style.right = randomWidth + 'px';
      confetti.style.backgroundColor = randomColor;
      confetti.style.transform = 'skew(15deg) rotate(' + randomRotation + 'deg)';
      confetti.style.animationDelay = randomAnimationDelay + 's';
      document.getElementById("confetti-wrapper").appendChild(confetti);
    }
  };

  async componentDidMount() {
    try {
      // adds confetti animation
      this.createConfetti();
      // scroll to top of window each time you visit someone's page
      window.scrollTo(0, 0);
      // if the page loads with a challenge id in the parameters, get that challenge from server
      if (this.props.match.params && this.props.match.params.challengeId) {
        await this.props.getChallenge(this.props.match.params.challengeId).then(async (chall) => {
          await this.getData();
        });
      } else {
        await this.getData();
      }
    } catch (error) {
      console.log("Error loading user page: ", error);
    }
  }

  componentWillUnmount() {
    // Clear userInterval if it's set
    if (userInterval) {
      clearInterval(userInterval);
      userInterval = null;
    }

    // Remove event listener for visibility changes
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);

    // Clean up the Twitch Embed instance
    if (this.state.embed && typeof this.state.embed.destroy === 'function') {
      this.state.embed.destroy();
    }
  }
  render() {
    const { loading } = this.props.data,
      { challengeIdParam, selectedValue, challengesQueue, activeChallId, profile, challengePanelOpen, hideStream, handle, challengeSearchPanelOpen, challengeCreatePanelOpen, chatPanel } = this.state,
      {
        classes
      } = this.props,
      challengesMarkupAccepted = loading ? (
        <div></div>
      ) : challengesQueue === null ? (
        <div></div>
      ) : !challengeIdParam && challengesQueue?.length ? (
        challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return sortChallenges(a, b, selectedValue);
          })
          .map((currentChallenge) =>
            <div className={`${viewing ? classes.viewing : ''} ${classes.challWrap}`} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
              <Challenge
                queue={true}
                challengeId={currentChallenge.challengeId}
                spacing="none"
                key={currentChallenge.challengeId}
                challenge={currentChallenge}
                activeChall={this.activeChall}
                clickedChallId={activeChallId}
              />
            </div>
          )
      ) : challengesQueue?.length ? (
        challengesQueue
          .filter((chall) => {
            return (chall.status === 'accepted' && this.state.stateActive)
          })
          .sort((a, b) => {
            return sortChallenges(a, b, selectedValue);
          }).map((challenge) => {
            if (challenge.challengeId !== challengeIdParam)
              return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} />;
            else return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} openDialog />;
          })
      )
        : (<div></div>);

    const challengesMarkupCreated = loading ? (
      <div></div>
    ) : challengesQueue === null ? (
      <div></div>
    ) : !challengeIdParam && challengesQueue && challengesQueue.length ? (
      challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return sortChallenges(a, b, selectedValue);
        })
        .map((currentChallenge) =>
          <div className={classes.challWrap} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
            <Challenge
              queue={true}
              challengeId={currentChallenge.challengeId}
              spacing="none"
              key={currentChallenge.challengeId}
              challenge={currentChallenge}
              activeChall={this.activeChall}
              clickedChallId={activeChallId}
            />
          </div>
        )
    ) : challengesQueue && challengesQueue.length ? (
      challengesQueue
        .filter((chall) => {
          return (chall.status === 'created' && this.state.stateOpen)
        })
        .sort((a, b) => {
          return sortChallenges(a, b, selectedValue);
        }).map((challenge) => {
          if (challenge.challengeId !== challengeIdParam)
            return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} />;
          else return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} openDialog />;
        })
    )
      : (<div></div>);

    /*const challengesMarkupFlagged = loading ? (
      <div></div>
    ) : challengesQueue === null ? (
      <div></div>
    ) : !challengeIdParam && challengesQueue && challengesQueue.length ? (
      challengesQueue
      .filter((chall)=> {
        return (chall.status === 'created' || chall.status ==='active' && this.state.stateOpen)
      })
      .sort((a, b)=> {
        return sortChallenges(a, b, selectedValue);
      })
      .map((currentChallenge) => 
        <div className={classes.challWrap} key={currentChallenge.challengeId} onClick={() => this.viewChallenge(currentChallenge)}>
            <Challenge 
              queue={true}
              challengeId={currentChallenge.challengeId} 
              spacing="none" 
              key={currentChallenge.challengeId} 
              challenge={currentChallenge} 
              activeChall={this.activeChall} 
              clickedChallId={activeChallId}
            />
        </div>
      )
    ) : challengesQueue && challengesQueue.length ? (
      challengesQueue
      .filter((chall)=> {
        return (chall.status === 'created' && this.state.stateOpen)
      })
      .sort((a, b)=> {
        return sortChallenges(a, b, selectedValue);
      }).map((challenge) => {
        if (challenge.challengeId !== challengeIdParam)
          return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} />;
        else return <Challenge spacing="none" key={challenge.challengeId} challenge={challenge} openDialog />;
      })
    )
    : (<div></div>);
    */

    return (
      <div className={classes.userTopElem}>
        <div className={this.state.celebrate ? '' : classes.hideConfetti} id="confetti-wrapper">
        </div>
        <Grid className={classes.wrapper} container>
          {profile && profile.twitch && !hideStream ?
            <Grid className={`${classes.gridSpacingNone} ${classes.notOnMobile}`} item sm={12} md={(challengePanelOpen || challengeCreatePanelOpen || challengeSearchPanelOpen || chatPanel) ? 6 : 9} lg={(challengePanelOpen || challengeCreatePanelOpen || challengeSearchPanelOpen || chatPanel) ? 8 : 10} xs={12}>
              <div className={classes.mainBanner}>
                {profile === null ? (
                  <ProfileSkeleton />
                ) : (
                  <div className={classes.twitch}>
                    <div id="twitch-embed" className={classes.twitchVideo}>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            : <div></div>
          }
          {(profile === null || (profile && !profile.twitch)) ? (
            <div></div>
          ) : (
            <Grid className={`${classes.gridSpacingNone} ${challengeSearchPanelOpen ? classes.searching : ''} ${classes.chat} ${challengePanelOpen === true || challengeCreatePanelOpen === true || challengeSearchPanelOpen === true || chatPanel ? '' : classes.noShot}`} item sm={12} md={(!challengePanelOpen && !challengeCreatePanelOpen && !challengeSearchPanelOpen && !chatPanel) ? false : 3} lg={(!challengePanelOpen && !challengeCreatePanelOpen && !chatPanel && !challengeSearchPanelOpen) ? false : 2} xs={12}>
              {(challengePanelOpen === false & challengeCreatePanelOpen === false && challengeSearchPanelOpen === false && chatPanel) ? (
                profile && profile.twitch ?
                  <div className={`${classes.twitchChat} ${classes.notOnMobile}`} >
                    <Accordion expanded={this.state.chatPanelMobile} onClick={this.openProfile} className={classes.accordian}>
                      <AccordionSummary className={classes.AccordionSummary}>
                        <Typography className={classes.heading}>Profile</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.AccordionDetails}>
                        <Typography>
                          <iframe src={`https://www.twitch.tv/embed/${profile.twitch.login}/chat?parent=${process.env.REACT_APP_ENV_TYPE === 'production' ? 'meed.app' : 'meed-dev.app'}`}
                            width="100%">
                          </iframe>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <iframe className={classes.desktopChat} src={`https://www.twitch.tv/embed/${profile.twitch.login}/chat?parent=${process.env.REACT_APP_ENV_TYPE === 'production' ? 'meed.app' : 'meed-dev.app'}`}
                      width="100%">
                    </iframe>
                  </div>
                  : <div></div>
              ) : challengeSearchPanelOpen ? (
                <div className={`${classes.challengePanel} ${classes.searchPadding} ${challengeSearchPanelOpen ? this.getClass(classes.moveIn) : ''} ${challengeSearchPanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <SearchChallenge viewChall={this.viewChallenge} refresh={this.refreshUser} userHandle={handle} />
                </div>
              ) : challengeCreatePanelOpen ? (
                <div className={`${classes.challengePanel} ${challengeCreatePanelOpen ? this.getClass(classes.moveIn) : ''} ${challengeCreatePanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <PostChallenge viewChall={this.viewChallenge} refresh={this.refreshUser} userHandle={handle} influencerId={this.state.profile.userId} />
                </div>
              ) : challengePanelOpen ? (
                <div className={`${classes.challengePanel} ${challengePanelOpen ? this.getClass(classes.moveIn) : ''} ${challengePanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <Challenge updateChallenge={this.updateChallenge} action={this.state.action || ''} clickedChallenge={this.state.clickedChallenge} challengeId={this.state.clickedChallenge && this.state.clickedChallenge && this.state.clickedChallenge.challengeId} challenge={this.state.clickedChallenge} />
                </div>
              ) : <div></div>
              }
            </Grid>
          )}
          {(profile && !profile.twitch) ?
            <Grid className={classes.gridSpacingNone} item sm={12} md={2} lg={(!challengePanelOpen && !challengeCreatePanelOpen && !challengeSearchPanelOpen && !chatPanel) ? false : 2} xs={12}>
              {challengeCreatePanelOpen ? (
                <div className={`${classes.challengePanel} ${challengeCreatePanelOpen ? this.getClass(classes.moveIn) : ''} ${challengeCreatePanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <PostChallenge viewChall={this.viewChallenge} refresh={this.refreshUser} userHandle={handle} influencerId={this.state.profile.userId} />
                </div>
              ) : challengeSearchPanelOpen ? (
                <div className={`${classes.challengePanel} ${challengeSearchPanelOpen ? this.getClass(classes.moveIn) : ''} ${challengeSearchPanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <SearchChallenge queue={true} viewChall={this.viewChallenge} refresh={this.refreshUser} userHandle={handle} />
                </div>
              ) : challengePanelOpen ? (
                <div className={`${classes.challengePanel} ${challengePanelOpen ? this.getClass(classes.moveIn) : ''} ${challengePanelOpen && this.state.moveInLeft ? classes.moveIn : classes.moveOut}`}>
                  <div className={classes.close} onClick={this.handleChallengeClose}></div>
                  <Challenge updateChallenge={this.updateChallenge} action={this.state.action || ''} clickedChallenge={this.state.clickedChallenge} challengeId={this.state.clickedChallenge && this.state.clickedChallenge.challengeId} challenge={this.state.clickedChallenge} />
                </div>
              ) : <div></div>
              }
            </Grid>
            :
            <div></div>
          }
          {/* The challenge section */}
          <Grid className={`${classes.challRight}`} item sm={12} md={2} lg={2} xs={12}>
            <div className={`${classes.challWrapInner}`}>
              <div className={`${classes.challengeToolBar}`}>
                {/* <Button variant="outlined" className={classes.viewChallenges} onClick={this.state.hidden ? this.viewChallenges : this.hideChallenges}>
                  {this.state.hidden ? 'View Challenges' : 'View Profile'}
                    
                </Button> */}
                <Button variant="outlined" className={classes.createButton} onClick={this.createChallenge}>
                  <img src={imageUrl} alt="create" className={classes.plusIcon} /> Create a Challenge
                </Button>
                <div className={`${classes.challengeSearch}`}>
                  <div onClick={this.sharePage}>
                    <Tooltip title='Share' placement="top">
                      <img
                        src={
                          window.innerWidth < 601
                            ? (profile && profile.twitch && profile.twitch.profile_image_url
                              ? profile.twitch.profile_image_url
                              : personIcon)
                            : whiteLink
                        }
                        alt="Share"
                        className={classes.userIcon}
                      />
                    </Tooltip>
                  </div>
                  <div> Challenges </div>
                  <div onClick={this.searchChallenge}> <Tooltip title='Search challenges for this user' placement="top"><img src={searchIcon} alt="Search for Challenges" className={classes.plusIcon2} /></Tooltip> </div>
                </div>
                <Accordion className={`${classes.filter}`}>
                  <AccordionSummary className={classes.filterSummary} expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                    <Typography className={classes.heading}>Filter</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.filterOptions}>
                    <Typography className={classes.filterHeader}>
                      Sort by:
                    </Typography>
                    <Typography>
                      Time Remaining (Least)
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'timeRemaining'}
                        onChange={this.handleCheck}
                        name="timeRemaining"
                        value="timeRemaining"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography>
                      Total Upvotes (Most)
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'totalUpvotes'}
                        onChange={this.handleCheck}
                        name="totalUpvotes"
                        value="totalUpvotes"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography>
                      $$$ (Most)
                      <Radio
                        className={classes.checkbox}
                        checked={this.state.selectedValue === 'money'}
                        onChange={this.handleCheck}
                        name="money"
                        value="money"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    <Typography className={classes.filterHeader}>
                      Filter by:
                    </Typography>
                    <Typography className={classes.filterSubHeader}>
                      State
                    </Typography>  {/*add ternary statement for handle*/}
                    <Typography>
                      Active
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.stateActive}
                        onChange={this.handleFilterCheck}
                        name="stateActive"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>
                    {/* <Typography>
                      Flagged 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.stateFlagged}
                        onChange={this.handleFilterCheck}
                        name="stateFlagged"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography>*/}
                    <Typography>
                      Open
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.stateOpen}
                        onChange={this.handleFilterCheck}
                        name="stateOpen"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Typography>

                    {/* <Typography className={classes.filterSubHeader}>
                      Duration/Time to Accept
                    </Typography>
                    <Typography>
                      Small 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeSmall}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeSmall"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography>
                    <Typography>
                      Medium 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeMedium}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeMedium"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography>
                    <Typography>
                      Large 
                      <Checkbox
                        className={classes.checkbox}
                        checked={this.state.acceptTimeLarge}
                        onChange={this.handleFilterCheck}
                        name="acceptTimeLarge"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />  
                    </Typography> */}
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={`${classes.rightChalls}`}>
                {challengesMarkupAccepted}
                {challengesMarkupCreated}
                <div onClick={this.backToTop} className={classes.backToTop}><span>Back to Top</span></div>
              </div>
            </div>
          </Grid>
          {/* The profile tabbed section */}
          <Grid className={`${classes.gridSpacingNone} ${(profile && !profile.twitch) || hideStream ? classes.noTwitch : ''}`} item sm={(profile && !profile.twitch) || hideStream ? 8 : 12} md={(profile && !profile.twitch) || hideStream ? 8 : 9} lg={(profile && !profile.twitch) || hideStream ? 8 : 10} xs={12}>
            {profile && this.state.fetched ? (
              <StaticProfile
                bio={profile.bio}
                imageUrl={profile.imageUrl}
                challengeCount={profile.challengesCompleted}
                challengesAccepted={profile.challengesAccepted}
                challengesQueue={challengesQueue}
                challengesRequested={this.state.challengesRequested}
                challengesCreated={this.state.challengesCreated}
                challengesCompleted={this.state.challengesCompleted}
                challengesVoted={this.state.challengesVoted}
                followerCount={profile.followerCount ? profile.followerCount : 0}
                userHandleUrl={profile.handle}
                displayName={profile.displayName}
                currentlyFollowing={profile.currentlyFollowing}
                history={this.props.history}
                twitchProfile={profile.twitch}
                lastCreatedKey1={this.state.lastCreatedKey1}
                lastCreatedKey2={this.state.lastCreatedKey2}
                lastRequestedKey1={this.state.lastRequestedKey1}
                lastRequestedKey2={this.state.lastRequestedKey2}
                lastCompletedKey1={this.state.lastCompletedKey1}
                lastCompletedKey2={this.state.lastCompletedKey2}
                toggleStream={this.toggleStream}
                openProfile={this.state.openProfile}
              />
            ) :
              <div className={classes.loading}>
                <CircularProgress className={classes.progress} />
              </div>
            }
          </Grid>
        </Grid>
      </div>
    );
  }
}

user.propTypes = {
  getUserQueueData: PropTypes.func.isRequired,
  getUserProfileData: PropTypes.func.isRequired,
  getChallenge: PropTypes.func.isRequired,
  getChallengeById: PropTypes.func.isRequired,
  fulfillMeedPoints: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
});

export default connect(
  mapStateToProps,
  { getUserProfileData, getUserQueueData, fulfillMeedPoints, getChallenge, getChallengeById }
)(withStyles(userStyles)(user));
