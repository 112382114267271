/**
 * Converts a ban level identifier from the backend into a user-friendly description.
 * 
 * @param {string} level - The level of the ban as identified in the backend system.
 * @returns {string} - A user-friendly description of the ban duration. Returns "Unknown Duration"
 *                     if the provided level does not match any known ban levels.
 */
function getBanLevelDescription(level) {
    const banLevelDescriptions = {
        "30min": "30 Minutes",
        "1day": "1 Day",
        "1week": "1 Week",
        "1month": "1 Month",
        "permanent": "Permanent"
    };

    // Return the description for the given level or a default value
    return banLevelDescriptions[level] || "Unknown Duration";
}

module.exports = { getBanLevelDescription }