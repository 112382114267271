import { logoutUser } from "./actions/userActions";
import { ERROR_MAPPING } from "./errorMapping";
import { CSRF_FAILURE, RATE_LIMIT_FAILURE, SHOW_TOAST } from "./types";

export const errorHandlingMiddleware = store => next => action => {
    if (action.type.includes('FAILURE')) {
        console.log("IN errorhandlingmiddleware 2")
        const errorType = ERROR_MAPPING[action.error.message];

        switch (errorType) {
            case CSRF_FAILURE:
                console.log('CSRF_FAILURE, logout user force')
                store.dispatch(logoutUser(null, true));
                /**
                 * Given the critical nature of a CSRF failure, we are opting to perform a full page reload 
                 * using window.location.href to navigate the user back to the login page. This approach has 
                 * the added benefit of resetting the entire application state, essentially providing a "clean slate" 
                 * which aids in maintaining a high level of security by mitigating risks associated with potential 
                 * state corruption or other security vulnerabilities that might be exploitable through a CSRF attack.
                 * 
                 * While this approach does have some downsides - notably the performance hit of a full page reload 
                 * and the loss of any unsaved application state - we believe that in this context, security takes 
                 * precedence. Furthermore, it offers a straightforward and reliable mechanism for handling this 
                 * critical error type, which is particularly important in the early stages of development.
                 * 
                 * Looking forward, especially as the development team expands and the application evolves, we might 
                 * consider a more nuanced approach to error handling that preserves the single-page application 
                 * user experience while still maintaining a robust security posture. This could potentially involve 
                 * more granular state management and error recovery strategies, sophisticated routing solutions 
                 * that integrate seamlessly with our security protocols, or other innovations in secure application 
                 * design.
                 * 
                 * For a future team revisiting this: the decision here is one rooted in a security-first approach, 
                 * acknowledging a trade-off against user experience. Please maintain a rigorous consideration of 
                 * security implications in any refactor or re-design of this error handling logic.
                 */
                window.location.href = '/login'; // Redirect to the login page
                console.log("The auto-logout was triggered");
                // window.location.reload();
                break;
            // Handle other error types...
            // case 'ANOTHER_FAILURE_TYPE':
            //     // Handle this error type
            //     break;
            case RATE_LIMIT_FAILURE:
                store.dispatch({ 
                    type: SHOW_TOAST, 
                    payload: { type: 'error', message: 'You have exceeded the rate limit. Please try again later.' }
                });
                break;            
            default:
                // Optional: Handle any unexpected errors or log them
                break;
        }

        return; // You can stop further propagation of the action if needed
    }
    return next(action);
};
