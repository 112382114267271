/**
 * Object mapping human-readable moderator levels to numeric values.
 * Each level grants different permissions for moderators within the system.
 * All are subject to change and part of an ongoing discussion.
 * - Linked (0): Offers no privileges but indicates a linked account.
 * - Tier 1 (Basic): Currently can flag/hide challenges and ban users..
 * - Tier 2 (Intermediate): Inherits permissions from Tier 1 and is also reserved for future use.
 * - Tier 3 (Full): Allows moderators to accept, decline, delete, complete, and fail challenges. Full moderators can also flag/hide challenges and ban users.
 * - Owner (99): Reserved for special status with full control over all functionalities and administrative tasks.
 *
 * Note: The permissions for each tier are subject to change as the platform evolves and further decisions are made.
 *
 * @type {Object.<string, number>}
 */
export const modLevelValues = {
    linked: 0, // No privileges
    basic: 1, // No special permissions assigned yet.
    intermediate: 2, // No special permissions assigned yet.
    full: 3, // Can accept, reject, complete, fail challenges; flag/hide challenges; ban users.
    owner: 99, // Special status with full permissions.
};

/**
 * Selector to get the moderator level for a specific user and influencer.
 * This function retrieves the moderator level information from the user's moderatedInfluencers array.
 *
 * @param {Object} user - The user object containing moderator information.
 * @param {string} influencerId - The ID of the influencer for whom the moderator level is to be retrieved.
 * @returns {string} The moderator level of the user for the specified influencer.
 *                  Returns 'none' if the moderator level is not found.
 */
export const getModeratorLevel = (user, influencerId) => {
    const influencer = user.moderatedInfluencers.find(inf => inf.influencerId === influencerId);
    return influencer ? influencer.level : 'none';
};

  