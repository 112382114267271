const userChallengeQueueStyles = (theme) => ({
    ...theme.custom,
    userTopElem: {
        overflow: 'hidden'
    },
    wrapper: {
        paddingTop: 66,
        '@media (max-width: 600px)': {
            paddingTop: 56,
        }
    },
    profile: {
        display: 'flex'
    },
    order1: {
        order: 1
    },
    order2: {
        order: 2
    },
    challengeToolBar: {
        position: 'fixed',
        maxWidth: 'inherit',
        zIndex: 1,
        width: '100%',
    },
    createButton: {
        width: '100%',
        borderRadius: 0,
        background: '#FFDF57',
        zIndex: 1,
        transition: 'all .4s',
        '&:hover': {
            textShadow: '0 0 black',
            border: 'solid 1px #5B5A57',
            background: '#FFDF57'
        },
        '@media (max-width: 600px)': {
            position: 'relative',
        }
    },
    filter: {
        width: '100%',
        minHeight: 24,
        borderRadius: 0,
        margin: 0,
        '& div': {
            borderRadius: 0
        }
    },
    filterSummary: {
        margin: 0,
        background: '#B464FA',
        '& p': {
            color: '#fff'
        },
    },
    filterHeader: {
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    filterSubHeader: {
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    filterOptions: {
        background: '#fff',
        '& p': {
            color: '#5B5A57',
            fill: '#5B5A57',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        '& span': {
            color: '#5B5A57',
            fill: '#5B5A57',
        },
        display: 'flex',
        flexDirection: 'column',
        minHeight: 24,
        borderRadius: 0,
        '& div': {
            borderRadius: 0
        }
    },
    challengeSearch: {
        width: '100%',
        borderRadius: 0,
        borderBottom: 'solid 1px #fff',
        background: '#B464FA',
        zIndex: 1,
        transition: 'all .4s',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        '& div': {
            cursor: 'pointer',
            color: '#fff',
            padding: '5px',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& div:nth-child(2)': {
            fontWeight: 700,
            borderLeft: 'solid 1px #fff',
            borderRight: 'solid 1px #fff',
            padding: '10px 20px'
        },
        '@media (max-width: 600px)': {
            position: 'relative',
        }
    },
    plusIcon: {
        marginRight: 5
    },
    gridSpacingNone: {
        padding: '0 !important'
    },
    chat: {
        overflow: 'hidden',
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 600px)': {
            order: 3,
        }
    },
    accordian: {
        display: 'none',
        '@media (max-width: 600px)': {
            display: 'block'
        },
        '& .MuiAccordionSummary-expanded': {
            background: 'purple'
        }
    },
    AccordionDetails: {
        backgroundColor: '#E1E0DE',
        padding: 0,
        minHeight: 400,
        '& p': {
            width: '100%'
        }
        // #B464FA
    },
    expandIcon: {
        color: '#fff'
    },
    AccordionSummary: {
        backgroundColor: '#E1E0DE',
        padding: '0 36px',
        '& ..MuiAccordionSummary-expandIcon': {
            right: 17
        }
    },
    challRight: {
        position: 'relative',
        padding: '0 !important',
        '@media (max-width: 600px)': {
            position: 'relative',
            order: 4,
            background: '#E1E0DE'
        }
    },
    challWrapInner: {
        position: 'fixed',
        width: '100%',
        maxWidth: 'inherit',
        background: '#5B5A57',
        height: '100vh',
        overflow: 'auto',
        paddingBottom: 60,
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 600px)': {
            background: 'unset',
            position: 'relative',
            height: 'unset'
        }
    },
    rightChalls: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        paddingBottom: 108,
        marginTop: 80,
        '&::-webkit-scrollbar': {
            width: '100%',
            height: 4
        },
        '@media (max-width: 600px)': {
            position: 'relative',
        }
    },
    stream: {
        position: 'relative'
    },
    chatOptions: {
        width: '32px',
        backgroundColor: '#FFDF57',
        position: 'absolute',
        bottom: '-36px',
        right: '0px',
        padding: '5px',
        fontWeight: '700',
        cursor: 'pointer',
        border: 'solid 1px #333',
        '@media (max-width: 600px)': {
            display: 'none',
        }
    },
    challengePanel: {
        position: 'relative',
        height: '100%',
        border: 'solid 1px #E1E0DE',
        '&:hover': {
            border: 'solid 1px #FFDF57',
        }
    },
    twitch: {
        paddingTop: '56.25%',
        position: 'relative',
        height: 0
    },
    twitchVideo: {
        paddingTop: '56.25%',
        height: 0,

        '& iframe': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0
        }
    },
    twitchChat: {
        height: '100%',
        '& iframe': {
            height: '100%',
            border: 'none'
        }
    },
    desktopChat: {
        '@media (max-width: 600px)': {
            display: 'none',
        }
    },
    hideOverflow: {
        overflow: 'hidden'
    },
    challWrap: {
        display: 'flex',
        width: '100%',
        '&:hover': {
            zIndex: 1,
            transition: 'all .4s',
        }
    },
    paddIt: {
        padding: 10
    },
    noTwitch: {
        order: -1,
        height: '100vh',
        '@media (max-width: 600px)': {
            height: 'unset',
        }
    },
    noShot: {
        display: 'none'
    },
    viewing: {
        cursor: 'progress'
    },
    searching: {
        background: '#5B5A57'
    }
});

export default userChallengeQueueStyles;