import {
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  HIDE_NAV,
  SHOW_NAV,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE
} from '../types';

const initialState = {
  loading: false,
  errors: null,
  showNav: true,
  toastMessage: '',
};

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false
      };
    case HIDE_NAV:
      return {
        ...state,
        showNav: false
      };
    case SHOW_NAV:
      return {
        ...state,
        showNav: true
      };
    case SHOW_TOAST:
      return {
        ...state,
        toastMessage: action.payload
      };
    case CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: ''
      };
    default:
      return state;
  }
}

export default uiReducer;
