const faqsStyles = (theme) => ({
    ...theme.custom,
    pageTitle: {
        textAlign: 'center',
        marginBottom: 20
    },
    faq: {
        background: '#B464FA',
        color: '#fff',
        '& p': {
            color: '#fff'
        }
    },
    buttonDefault: {
        width: '100%',
        marginTop: 30,
        borderRadius: '2px',
        maxWidth: '220px'
    },
    heading: {
        color: '#FFF',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    label: {
        color: '#fff',
        borderColor: '#fff',
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
    },
    gang: {
        '& label.Mui-focused': {
            color: '#fff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#fff',
            },
            '&:hover fieldset': {
                borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#fff',
            },
        },
    },
    faqItems: {
        padding: 10
    }
});

export default faqsStyles;