const reportChallengeStyles = {
    reportButton: {
        background: '#FFDF57',
        cursor: 'pointer',
        color: '#5B5A57',
        position: 'relative',
        border: 'solid 1px #FFDF57',
        borderRadius: 8,
        padding: '6px 16px',
        fontWeight: 700,
        transition: 'all .4s',
        '&:hover': {
            background: '#FFDF57',
            opacity: 1,
            border: 'solid 1px #5B5A57',
        }
    },
    reportChallenge: {
        fontSize: '.875rem',
        color: '#5B5A57',
        marginBottom: 5,
        cursor: 'pointer',
        '&:hover': {
            background: 'transparent',
            opacity: .9,
        }
    },
};

export default reportChallengeStyles;