import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';
import { authenticateViaTwitter } from '../redux/actions/userActions';
import faqsStyles from './styles/faqsStyles';
import { discordLink } from '../util/variables';

class faqs extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className="container">
        <Grid container className={classes.faqs}>
          <Grid className={classes.faqItems} item xs={12}>
            <Typography
              variant="h2"
              className={classes.pageTitle}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}>
              FAQs
            </Typography>
          </Grid>
          <Grid className={classes.faqItems} item md={6} xs={12}>
            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}> Enhancing Digital Interaction With Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography classesName={classes.heading}>
                  Meed revolutionizes online engagement, especially in the dynamic realms of gaming and streaming. It introduces "Challenges," allowing users to create requests with a monetary incentive for others on the platform. This feature is not just for streamers and gamers; it's a tool for everyone seeking more meaningful, direct interactions in a digital space often marked by distance and anonymity. Challenges can be boosted by the community, ensuring visibility for popular ideas and fostering a sense of connection and recognition among users.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>How do I use Meed?</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.heading}>
                  <p>Meed offers a range of features and integrations to enhance your online interactions, whether you're creating or accepting Challenges. Here’s how to make the most of it:</p>

                  <p><strong>Creating Challenges on Discord:</strong> With MeedBot, you can seamlessly create Challenges right within Discord. This integration bridges your Discord interactions with the Meed platform, offering a unique way to engage with your community. Add <a href={discordLink}>MeedBot</a></p>

                  <p><strong>Receiving Challenge Notifications with Streamlabs:</strong> For streamers, integrating Meed with Streamlabs offers an added advantage. You’ll receive real-time notifications on your stream whenever a Challenge is completed, keeping your audience engaged and informed.</p>

                  <p><strong>Setting Up and Exploring Integrations:</strong> To access these features and discover more, visit the Meed Integrations page at <a href="https://meed.app/integrations">https://meed.app/integrations</a>. Here, you can connect your Twitch account, set up MeedBot for Discord, integrate with Streamlabs, and explore other exciting functionalities.</p>

                  <p>Whether you're a fan looking to connect with your favorite creators or a creator aiming to enhance your audience engagement, Meed’s integrations offer the tools you need for a more interactive and rewarding online experience.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Understanding Challenges on Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  Challenges are the heart of Meed, offering a fun way to engage with others, especially streamers.
                  Think of a Challenge as a personal request or a playful dare – it can be anything from gaming feats
                  like excelling in a "Warzone" match to creative antics like doing a backflip while impersonating a celebrity.

                  <b>Key Points about Challenges:</b>
                  <ul>
                    <li><b>Flexibility:</b> You can set Challenges to be as broad or specific as you like.</li>
                    <li><b>Monetary Incentive:</b> While free by default, you can choose to attach a cash reward to your Challenge.</li>
                    <li><b>Secure Transactions:</b> Any pledged money is held in escrow until the Challenge is successfully completed.</li>
                    <li><b>Risk-Free:</b> If a Challenge isn’t accepted or completed, you get your money back, no strings attached.</li>
                  </ul>
                  Challenges are a great way to interact, have fun, and possibly see something extraordinary happen!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Challenge Creation Costs on Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  Creating a Challenge on Meed is generally free. However, if you wish to set a fee for others to challenge you,
                  simply navigate to Settings{` > `}Public Profile and select “Creation Fee Amount.” Setting an amount above zero
                  means anyone who wants to create a Challenge for you will include this fee in their challenge creation process.
                  It will be refunded to the account if the Challenge is not accepted or fails.
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Challenge Limits on Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  On Meed, you can have up to 100 Open Challenges at a time for a specific user. An Open Challenge is one that hasn't been accepted yet.
                  Want to adjust this limit? Simply go to <i>Settings {`>`} Public Profile</i> and select <i>“Max Open Challenges Per User”</i>.
                  Remember, once a Challenge is accepted, it no longer counts towards your limit. For instance, if your limit is one and
                  your Challenge “Do a backflip” is accepted, you can immediately create a new one, like “Do a frontflip”.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Exploring Challenge Boosting</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  Boosting in Meed lets you increase the visibility and appeal of a Challenge you're excited about.
                  Simply click on a Challenge and add to its reward. Your contribution boosts the total reward pool,
                  held securely in escrow. This makes the Challenge more enticing to the targeted user.

                  If the Challenge isn't accepted or successfully completed (as determined in the voting stage, if applicable),
                  your money is safely returned. Boosting is a great way to show support for creative and engaging Challenges!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Challenge Duration and Voting Period</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  The duration of each Challenge varies, determined by the 'Time to Complete' set during its creation. Challenges can last anywhere from 1 minute to 6 days. Additionally, there's an extra day added after the Challenge period ends, specifically for community voting. This voting period allows users to engage with the Challenge outcome and ensures a fair and exciting experience for everyone involved.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Completing and Voting on Challenges</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  Completing a Challenge involves a few simple steps. Once a Challenge is accepted, the targeted user can mark it as complete after meeting the set criteria. This is done by going to the Challenge card and selecting “Complete,” which moves the Challenge to the voting section. Challenges can be attempted multiple times until the deadline, unless specified otherwise.

                  <br />
                  <br />

                  After marking a Challenge complete, it enters the voting stage. During this phase, the community votes on whether the Challenge was successfully accomplished. This additional step ensures fairness and enhances community engagement, making the completion of Challenges a more interactive and collaborative process.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Handling Failed Challenges</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>A Challenge is considered failed under several conditions:</p>

                  <p>1. The recipient can manually mark a Challenge as failed.</p>

                  <p>2. If the Challenge receives more official 'fail' votes than 'pass' votes from the community (note: ties result in a fail).</p>

                  <p>3. If the Challenge isn't completed within the specified time, and the recipient doesn't mark it as failed.</p>

                  <p>In any of these scenarios, the Challenge is closed, and all funds held in escrow are returned. This process ensures fairness and transparency in the Challenge outcomes.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Receiving Payments for Challenges</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>Receiving your earnings from completed Challenges is straightforward. First, set up a Stripe payout account. You can do this by visiting the Settings page and navigating through the “GET PAID” section.</p>

                  <p>Payments are processed bi-monthly, being sent out on the second and fourth Sundays of each month. It’s important to note that Meed retains a 5% fee (not including any payment processing fees) from all completed Challenges. This fee is utilized to continuously develop and enhance the Challenge experience for all users.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

          </Grid>
          <Grid className={classes.faqItems} item md={6} xs={12}>
            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Moderator Roles and Management</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p><b>Adding Moderators:</b> To appoint a moderator, go to Settings &gt; Public Profile &gt; Mod Management. In this section, search for and select the user you wish to add as a moderator.</p>

                  <p><b>Moderator Capabilities:</b> Moderators are essential for maintaining a positive experience on the platform. Their primary function is to flag Challenges for review, ensuring that content aligns with community guidelines and standards. This flagging process helps maintain the integrity and quality of the challenges, enhancing the experience for all users.</p>

                  <p>We are constantly working on enhancing moderator functionalities to provide better control and maintain a healthy online environment.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Understanding Challenge Clipping</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>Challenge Clipping allows users to capture and share memorable moments from Challenges. If a Challenge results in an exciting or notable outcome, you can link a video clip for later viewing. This feature is available to the Challenge creator, the targeted user, and the user’s moderators.</p>

                  <p>Currently, Meed supports video links from YouTube and Twitch. To share a YouTube clip, click “Share” on the video’s page and paste the link into the Expanded Challenge View on Meed. For Twitch clips, follow the guidelines on <a href="https://help.twitch.tv/s/article/how-to-use-clips?language=en_US">Twitch’s help page</a> and then paste the link similarly on Meed. Note that Twitch stream videos may have an expiration date. Additionally, links to other relevant non-video content can also be added as needed.</p>

                  <p>We are actively working to expand support to other platforms like TikTok and enable direct video uploads for a more seamless experience.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Managing User Bans and Unbans</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p><b>Banning a User:</b> If a user's actions warrant a ban, you can do this in two ways. One, click on their name in the Expanded Challenge View and select “Ban User.” Alternatively, ban a user from your page via Settings &gt; Public Profile &gt; Banning/Unbanning, where you can search for the user.</p>

                  <p><b>Types of Bans:</b> There are three ban levels.
                    <ul>
                      <li><b>Page-Specific Ban:</b> The user can't create Challenges on your page but can Boost existing ones.</li>
                      <li><b>Site-Wide Ban:</b> The user is restricted from creating Challenges on any public profiles on Meed but can still Boost Challenges.</li>
                      <li><b>Total Ban:</b> This is a complete suspension of the user’s account, preventing any action on the site.</li>
                    </ul>
                  </p>

                  <p><b>Unbanning a User:</b> To reverse a ban, go to Settings &gt; Public Profile &gt; Banning/Unbanning and select "Unban" for the respective user.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Appealing a Challenge Creation Ban</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>If you've been banned from creating Challenges on a specific user's page, unfortunately, Meed cannot intervene. These bans are at the sole discretion of the page owner. However, if you believe you've been incorrectly banned from the entire site, we're here to help. Please reach out to us via the <a href="https://meed.app/contact">Contact Us</a> page with your concerns, and we'll assist you in reviewing your case.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Reporting Users and Challenges on Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p><b>Reporting a User or Challenge:</b> If you encounter a Challenge that seems inappropriate or illegal, you can report it directly from the Challenge Card. To do this, open the Expanded Challenge View and click on “Report This Challenge.” This action alerts our team to review the content and take appropriate action.</p>

                  <p><b>Handling Challenges on Your Page:</b> If you want to remove a Challenge from your page without reporting the user, simply select “Reject” in the Expanded Challenge View. This will delete the Challenge from your page. Remember, reporting a Challenge on your page will also report the user who created it, so use this feature judiciously.</p>

                  <p>At Meed, we take reports seriously and investigate each case to ensure a safe and respectful community for all users.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}> How do I know a user is who they claim to be? </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography classesName={classes.heading}>
                  As a general rule, Meed operates on a “first come, first served” basis for usernames. In order to prove that a user is who they claim to be, Meed offers the ability for users to link their Meed account to their social media profiles or streaming sites (Twitter, Twitch, YouTube, etc.). When a user connects their accounts in this fashion, a link is placed in the About section of their profile page to the right of their biography. Only links placed in this section represent authentic accounts verified by Meed.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}> Another user is using my username, what do I do? </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography classesName={classes.heading}>
                  While Meed operates on a “first come, first served” basis for usernames, and so the first user to claim a name may use it, in certain situations (such as impersonation of a public figure or brand) Meed will intervene to change the username. Please reach out to us via the <a href="https://meed.app/contact">Contact Us</a> page with your concerns.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Refund Policy and Challenge Voting on Meed</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>All transactions on Meed are final. With our voting system, the community or designated voters (depending on the voting type set for the Challenge) have the final say in determining whether a Challenge is completed successfully. This decision is conclusive and not subject to reversal.</p>

                  <p>For creators seeking specific control over the outcome, setting the voting type to "Creator" allows you to be the sole decider for the completion of your Challenges. However, once a decision is made through voting, it stands as the final verdict, and no refunds will be issued based on these outcomes.</p>

                  <p>We encourage users to carefully consider their Challenges and voting settings to ensure a fair and enjoyable experience for everyone involved. If you have any concerns or questions, please contact us at contact@meed.app.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Meed's Revenue Model</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>Meed is committed to constantly improving and evolving the Challenge experience. To support this ongoing development, we retain a 5% fee from all successfully completed Challenges (this does not include donations which have no fee besides payment processing). This revenue model enables us to continually enhance the platform, ensuring a dynamic and engaging experience for all our users.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary className={classes.faq} expandIcon={<ExpandMoreIcon className={classes.faq} />}>
                <Typography className={classes.heading}>Need More Help?</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.faq}>
                <Typography className={classes.content}>
                  <p>If your question isn't covered in our FAQ, don't worry! We're here to help. Reach out to us anytime through the "Contact Us" form, accessible via the dropdown menu under your user profile image. Our team is dedicated to providing you with the answers and assistance you need, and we'll respond to your queries as quickly as possible.</p>
                </Typography>
              </AccordionDetails>
            </Accordion>

          </Grid>
        </Grid>
      </div>
    );
  }
}

faqs.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  loginUser,
  authenticateViaTwitter
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(faqsStyles)(faqs));