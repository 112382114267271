const acceptChallengeStyles = {
    acceptButton: {
        background: '#14C7FC',
        color: '#fff',
        position: 'relative',
        border: 'solid 1px #14C7FC',
        borderRadius: 8,
        padding: '6px 16px',
        fontSize: 16,
        fontWeight: 700,
        opacity: 0.9,
        transition: 'all .4s',
        '&:hover': {
            opacity: 1,
            border: 'solid 1px #5B5A57',
            background: '#14C7FC'
        },
    }
};

export default acceptChallengeStyles;