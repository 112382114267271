import { ERROR_MAPPING } from '../errorMapping';
import {
  SET_CHALLENGES,
  LOADING_DATA,
  DELETE_CHALLENGE,
  SET_ERRORS,
  POST_CHALLENGE,
  POST_SUGGESTION,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_CHALLENGE,
  SET_ACCEPTING_USERS,
  SET_COMPLETED_CHALLENGES,
  SET_STAFF_CHALLENGES,
  GET_TOTAL_RAISED,
  SHOW_NAV,
  HIDE_NAV,
  ACCEPT_CHALLENGE,
  DECLINE_CHALLENGE,
  COMPLETE_CHALLENGE,
  FAIL_CHALLENGE,
  CANCEL_CHALLENGE,
  BOOST_CHALLENGE,
  REPORT_CHALLENGE,
  OTHERS_DECIDE_CHALLENGE,
  CAST_PASS_VOTE,
  CAST_FAIL_VOTE,
  REMOVE_VOTE,
  SHOW_TOAST,
  CLEAR_TOAST_MESSAGE,
} from '../types';
import axios from 'axios';
axios.defaults.withCredentials = true;
export const showNav = () => (dispatch) => {
  dispatch({ type: SHOW_NAV });
};

export const hideNav = () => (dispatch) => {
  dispatch({ type: HIDE_NAV });
};

export const showToastMessage = message => ({
  type: SHOW_TOAST,
  payload: message
});

export const clearToastMessage = () => ({
  type: CLEAR_TOAST_MESSAGE
});

/**
 * Fetches the list of users currently accepting challenges and dispatches an action to update the state.
 * 
 * @async
 * @function
 * @returns {Promise<void>} Nothing is returned, but the state will be updated with the retrieved data or an empty array if an error occurs.
 */
export const getAcceptingUsers = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get('/acceptingUsers');

    if (res && res.data) {
      dispatch({
        type: SET_ACCEPTING_USERS,
        payload: res.data
      });
    } else {
      dispatch({
        type: SET_ACCEPTING_USERS,
        payload: []
      });
    }
  } catch (err) {
    console.error("Error fetching accepting users:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors. You can dispatch a generic error action or just log it.
        // For example:
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    }

    dispatch({
      type: SET_ACCEPTING_USERS,
      payload: []
    });
  }
}

/**
 * Fetches the list of completed challenges, dispatches an action to update the state with the retrieved data, 
 * and returns the list of completed challenges.
 * 
 * @async
 * @function
 * @returns {Promise<Array<Object>|null>} The list of completed challenges or null if an error occurs.
 */
export const getCompletedChallenges = () => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get('/completedChallenges');

    if (res && res.data) {
      dispatch({
        type: SET_COMPLETED_CHALLENGES,
        payload: res.data
      });
      return res.data;
    }

    return null;
  } catch (err) {
    console.error("Error fetching completed challenges:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // For unexpected errors, dispatch an action to set the completed challenges as an empty array.
        dispatch({
          type: SET_COMPLETED_CHALLENGES,
          payload: []
        });
      }
    } else {
      // For errors without a specified response.data.error, dispatch an action to set the completed challenges as an empty array.
      dispatch({
        type: SET_COMPLETED_CHALLENGES,
        payload: []
      });
    }

    return null;
  }
};

/**
 * Fetch staff challenges and dispatch the appropriate action to update the state.
 *
 * @async
 * @function
 * @return {Function} A Redux Thunk action that dispatches either the action to set staff challenges 
 * or an action to set an empty list in case of an error.
 */
export const getStaffChallenges = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_DATA });

    const res = await axios.get('/staffChallenges');

    if (res && res.data) {
      dispatch({
        type: SET_STAFF_CHALLENGES,
        payload: res.data
      });
    }
  } catch (err) {
    console.error("Error fetching staff challenges:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        dispatch({ type: SET_STAFF_CHALLENGES, payload: [] });
      }
    } else {
      dispatch({ type: SET_STAFF_CHALLENGES, payload: [] });
    }
  }
};

/**
 * Fetches a challenge based on its ID and returns the challenge data.
 * 
 * @async
 * @param {string} challengeId - The ID of the challenge to retrieve.
 * @function
 * @returns {Promise<Object|null>} The challenge data or null if an error occurs.
 */
export const getChallenge = (challengeId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });

    const res = await axios.get(`/challenge/${challengeId}`);

    if (res && res.data) {
      return res.data;
    }

    return null;

  } catch (err) {
    console.error('Error fetching challenge:', err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        dispatch({
          type: SET_CHALLENGE,
          payload: {}
        });
      }
    } else {
      dispatch({
        type: SET_CHALLENGE,
        payload: {}
      });
    }

    return null;
  }
};

/**
 * Fetches a challenge based on its ID, dispatches an action to update the state 
 * with the retrieved challenge, and returns the challenge data.
 * 
 * @async
 * @param {string} challengeId - The ID of the challenge to retrieve.
 * @function
 * @returns {Promise<Object|null>} The challenge data or null if an error occurs.
 */
export const getChallengeById = (challengeId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });

    const res = await axios.get(`/challenge/${challengeId}`);

    if (res && res.data) {
      dispatch({
        type: SET_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }

    return null;

  } catch (err) {
    console.error('Error fetching challenge by ID:', err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        dispatch({
          type: SET_CHALLENGE,
          payload: {}
        });
      }
    } else {
      dispatch({
        type: SET_CHALLENGE,
        payload: {}
      });
    }

    return null;
  }
};

/**
 * Posts a new challenge. The endpoint used for the post request depends on whether the challenge 
 * has a `fromEmail` property. If the post is successful, it will dispatch an action to update the state 
 * with the new challenge and clear any existing errors. If the post fails, it will dispatch an action 
 * to set errors based on the response data. Used by the about/home page "Make A Challenge" (MAC) flow.
 * 
 * @async
 * @function
 * @param {Object} newChallenge - The challenge data to be posted.
 * @returns {Promise<Object|null>} The response data after posting the challenge or null if an error occurs.
 */
export const postChallenge = (newChallenge) => async (dispatch) => {
  // challengeHome is used by MAC flow when logged out, /challenge is used by donate flow...
  const postlink = newChallenge.fromEmail ? '/challengeHome' : '/challenge';
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post(postlink, newChallenge);

    if (res && res.data) {
      dispatch({
        type: POST_CHALLENGE,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }

    return null;
  } catch (err) {
    console.error('Error posting the challenge:', err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
      return err.response.data;
    }

    return null;
  }
};

/**
 * Initiates a donation process by sending a donation request to the server.
 * 
 * @async
 * @function donateMoney
 * @param {Object} donation - The donation details to be sent to the server.
 * @param {function} dispatch - The dispatch function from Redux to dispatch actions.
 * @returns {Promise<Object>} A promise that resolves to the response data from the server if the donation is successful.
 * @description
 * This function is used in the donation page to handle the donation process. It dispatches 
 * a loading action before making a POST request to the server with the donation details. 
 * If successful, it dispatches an action with the response data and clears any existing errors. 
 * In case of an error, it dispatches an error action with the error details.
 * 
 * Example usage:
 * ```
 * async callPostDonation(newDonation) {
 *   try {
 *     const { sessionId } = await this.props.donateMoney(newDonation);
 *     // further logic to handle successful donation
 *   } catch (err) {
 *     // handle errors
 *   }
 * }
 * ```
 */
export const donateMoney = (donation) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.post('/donate', donation);
    
    if (res && res.data) {
      dispatch({
        type: POST_CHALLENGE,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
      console.log('An error occurred during the donation process.');
      return err.response.data;
    }
  }
};

/**
 * Posts a new suggestion. If the post is successful, it will dispatch an action to update 
 * the state with the new suggestion and clear any existing errors. If the post fails, 
 * it will dispatch an action to set errors based on the response data.
 * 
 * @async
 * @function
 * @param {Object} newSuggestion - The suggestion data to be posted.
 * @returns {Promise<Object|null>} The response data after posting the suggestion or null if an error occurs.
 */
export const postSuggestion = (newSuggestion) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post('/suggestion', newSuggestion);

    if (res && res.data) {
      dispatch({
        type: POST_SUGGESTION,
        payload: res.data
      });
      clearErrors(dispatch);
      return res.data;
    }
    return null;
  } catch (err) {
    console.error('Error posting the suggestion:', err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      }
    }
    return null;
  }
};

/**
 * Searches for twitter users based on the provided challenge data. 
 * It will then filter and return only the verified users.
 * 
 * @async
 * @function
 * @param {Object} newChallenge - The challenge data to be used for searching Twitter users.
 * @returns {Promise<Array|null>} An array of verified twitter users or null if an error occurs.
 */
export const getTwitterUsers = (newChallenge) => async (dispatch) => {
  dispatch({ type: LOADING_UI });

  try {
    const res = await axios.post('/twitterUserSearch', newChallenge);

    if (res && res.data) {
      let verifiedUsers = JSON.parse(res.data).filter((user) => user.verified === true);
      return verifiedUsers;
    }
    return null;
  } catch (err) {
    console.error('Error fetching Twitter users:', err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors
        // For this function, since there's no dispatch for errors provided,
        // we'll just log the error. You may want to add error handling specific to this function.
      }
    }
    return null;
  }
};

/**
 * Fetches the total funds raised for a particular challenge.
 * Once fetched, dispatches an action to update the state with the fetched amount.
 * 
 * @async
 * @function
 * @param {string} challengeId - The ID of the challenge.
 */
export const getTotalFundraised = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/totalFundsRaised`);
    if (res && res.data) {
      dispatch({
        type: GET_TOTAL_RAISED,
        payload: { ...res.data, challengeId }
      });
    }
  } catch (error) {
    console.error('Error fetching total funds raised:', error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }
  }
}

/**
 * Sends a request to accept a specific challenge.
 * Once the request is successful, dispatches an action to update the state with the challenge acceptance data.
 * 
 * @async
 * @function
 * @param {string} challengeId - The ID of the challenge.
 * @returns {Promise<Object|null>} The response data after accepting the challenge or null if an error occurs.
 */
export const acceptChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/acceptChallenge`);
    if (res && res.data) {
      dispatch({
        type: ACCEPT_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
    return null;
  } catch (error) {
    console.error('Error accepting the challenge:', error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and return null.
    }

    return null;
  }
};

/*
export const acceptMassChallenge  = (challengeId) => async (dispatch) => {
  try {
    return await axios
    // I'd probably prefer /massChallenge/${challengeId}/acceptMassChallenge
      .get(`/challenge/${challengeId}/acceptMassChallenge`)
      .then((res) => {
        if (res && res.data){
          dispatch({
            // Should need to update reducers
            // type: ACCEPT_MASS_CHALLENGE,
            payload: res.data
          });
          return res.data;
        }
      })
      .catch((err) => console.log(err));
  } catch(err) {
    
  }
}
*/

/**
 * Decline a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to decline.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const declineChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/declineChallenge`);

    if (res.data) {
      dispatch({
        type: DECLINE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while declining challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and throw it.
    }

    throw error;
  }
};

/**
 * Let others decide on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge for others to decide.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const othersDecideChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/othersDecideChallenge`);

    if (res.data) {
      dispatch({
        type: OTHERS_DECIDE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while letting others decide on challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and throw it.
    }

    throw error;
  }
};

/**
 * Complete a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to complete.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const completeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/completeChallenge`);

    if (res.data) {
      dispatch({
        type: COMPLETE_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while completing challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and throw it.
    }

    throw error;
  }
};

/**
 * Fail a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to mark as failed.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const failChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/failChallenge`);

    if (res.data) {
      dispatch({
        type: FAIL_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while marking challenge as failed:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and throw it.
    }

    throw error;
  }
};

/**
 * Cancel a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to cancel.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const cancelChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/cancelChallenge`);

    if (res.data) {
      dispatch({
        type: CANCEL_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while canceling challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, the function will simply log the error and throw it.
    }

    throw error;
  }
};

/**
 * Boost a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to boost.
 * @param {number} amount - The amount to boost the challenge by.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const boostChallenge = (challengeId, amount) => async (dispatch) => {
  try {
    const res = await axios.post(`/challenge/${challengeId}/boostChallenge`, { amount });

    if (res.data) {
      dispatch({
        type: BOOST_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while boosting challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Log any specific error message from the server response that isn't recognized in the mapping
        // console.log('General error response for boosting', error.response.data);
      }
    }

    throw error;
  }
};

/**
 * Submit a clip for a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge.
 * @param {string} link - The link of the submitted clip.
 * @param {Function} dispatch - The dispatch function from Redux (currently not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const submitClip = (challengeId, link) => async (dispatch) => {
  try {
    const res = await axios.post(`/challenge/${challengeId}/submitClip`, { link });

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while submitting clip:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // For other errors that may not be part of the mapping, the error is just logged and rethrown
        console.error("Unhandled error response:", error.response.data);
      }
    }

    throw error;
  }
};

/**
 * Upvote a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to upvote.
 * @param {Function} dispatch - The dispatch function from Redux (currently not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const upvote = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/upvoteChallenge`);

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while upvoting challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // For other errors that may not be part of the mapping, the error is just logged and rethrown
        console.error("Unhandled error response:", error.response.data);
      }
    }

    throw error;
  }
};

/**
 * Report a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to report.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const reportChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/reportChallenge`);

    if (res.data) {
      dispatch({
        type: REPORT_CHALLENGE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error("Error while reporting challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // For other errors that may not be part of the mapping, the error is just logged and rethrown
        console.error("Unhandled error response:", error.response.data);
      }
    }

    throw error;
  }
};

/**
 * Like a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to like.
 * @param {Function} dispatch - The dispatch function from Redux (not used, but kept for consistency).
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const likeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/like`);
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while liking challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // For other errors that may not be part of the mapping, the error is just logged and rethrown
        console.error("Unhandled error response:", error.response.data);
      }
    }

    throw error;
  }
};

/**
 * Unlike a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to unlike.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const unlikeChallenge = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(`/challenge/${challengeId}/unlike`);

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error while unliking challenge:", error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
};

/**
 * Cast a pass vote on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to vote on.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const castPassVote = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`/challenge/${challengeId}/vote`, { vote: 'pass' });

    if (res.data) {
      dispatch({
        type: CAST_PASS_VOTE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while casting pass vote for challenge ${challengeId}:`, error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Cast a fail vote on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to vote on.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const castFailVote = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`/challenge/${challengeId}/vote`, { vote: 'fail' });

    if (res.data) {
      dispatch({
        type: CAST_FAIL_VOTE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while casting fail vote for challenge ${challengeId}:`, error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

/**
 * Remove a vote on a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge from which the vote is removed.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const removeVote = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.post(`/challenge/${challengeId}/vote/remove`);

    if (res.data) {
      dispatch({
        type: REMOVE_VOTE,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while removing vote for challenge ${challengeId}:`, error.response ? error.response.data : error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        // dispatch({ type: GENERIC_ERROR, error: error.response.data });
      }
    }

    throw error;
  }
}

// Submit a comment
// export const submitComment = (challengeId, commentData) => (dispatch) => {
//   axios
//     .post(`/challenge/${challengeId}/comment`, commentData)
//     .then((res) => {
//       if(res){
//         dispatch({
//           type: SUBMIT_COMMENT,
//           payload: res.data
//         });
//         clearErrors(dispatch);
//       }
//     })
//     .catch((err) => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: err.response.data
//       });
//     });
// };

/**
 * Delete a specific challenge.
 *
 * @param {string} challengeId - The ID of the challenge to be deleted.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The data from the response, if successful.
 * @throws {Error} If there's an issue with the Axios request.
 */
export const deleteChallenge = async (challengeId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/challenge/${challengeId}`);

    if (res.data) {
      dispatch({ type: DELETE_CHALLENGE, payload: challengeId });
      return res.data;
    }
  } catch (error) {
    console.error(`Error while deleting challenge ${challengeId}:`, error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      }
      // If the error isn't in the mapping, it'll just log and throw as per the original code.
    }

    throw error;  // Throwing the error further to allow more specific error handling if needed
  }
};

/**
 * Get data for a specific user.
 *
 * @param {string} userHandle - The handle of the user whose data is to be fetched.
 * @param {Function} dispatch - The dispatch function from Redux.
 */
export const getUserData = (userHandle) => async (dispatch) => {
  dispatch({ type: LOADING_DATA });

  try {
    const res = await axios.get(`/user/${userHandle}`);

    dispatch({
      type: SET_CHALLENGES,
      payload: res.data.challenges
    });
  } catch (err) {
    console.error(`Error while getting user data for ${userHandle}:`, err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // For instance, consider having a GENERIC_ERROR action:
        // dispatch({ type: GENERIC_ERROR, error: err.response.data });
      }
    } else {
      dispatch({ type: SET_CHALLENGES, payload: null });
    }

  }
}

/**
 * Clear errors from the UI.
 *
 * @param {Function} dispatch - The dispatch function from Redux.
 */
export const clearErrors = (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

/**
 * Get checkout session from the server.
 *
 * @async
 * @function
 * @param {Object} sessionMeta - Metadata for the session.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The checkout session data.
 * @throws {Error} When unable to retrieve the checkout session.
 */
export const getCheckoutSession = (sessionMeta) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });

    const response = await axios.post('create-checkout-session', sessionMeta);

    if (response && response.data) {
      clearErrors(dispatch);
      return response.data;
    }
  } catch (error) {
    console.error('Error occurred while fetching the checkout session:', error);

    if (error.response && error.response.data.error) {
      const errorType = ERROR_MAPPING[error.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: { message: error.response.data.error } });
      } else {
        // Handle unexpected errors or dispatch a generic error action
        dispatch({
          type: SET_ERRORS,
          payload: error.response.data || "Error fetching checkout session."
        });
      }
    } else {
      dispatch({
        type: SET_ERRORS,
        payload: "Error fetching checkout session."
      });
    }
    throw error;  // Throwing the error further to allow more specific error handling if needed
  }
}

/**
 * Fetch the configuration data.
 *
 * @async
 * @function
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<Object>} - The configuration data.
 * @throws {Error} When unable to fetch the configuration.
 */
export const fetchConfig = () => async (dispatch) => {
  try {
    const response = await axios.get('config');

    if (response && response.data) {
      clearErrors(dispatch);
      return response.data;
    }

  } catch (err) {
    console.error("Error fetching configuration data:", err.response ? err.response.data : err);

    if (err.response && err.response.data.error) {
      const errorType = ERROR_MAPPING[err.response.data.error];
      if (errorType) {
        dispatch({ type: errorType, error: err.response.data });
      } else {
        // Handle unexpected errors or dispatch a generic error action.
        // This part was suggested in the comments of the provided code:
        // dispatch({ type: FETCH_CONFIG_ERROR, payload: err.message });
      }
    }

    throw err;  // Throwing the error further to allow more specific error handling if needed
  }
}