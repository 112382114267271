import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// MUI Stuff
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';
import { authenticateViaTwitter } from '../redux/actions/userActions';
import NotFoundstyles from './styles/NotFoundStyles';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

class notFound extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
      submitted: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.setState({ submitted: true });

    try {
      await this.props.loginUser(userData, this.props.history);
      // Handle any additional logic post successful login, if needed
    } catch (err) {
      // Handle the error scenario here, if needed
    } finally {
      this.setState({ submitted: false });
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.notFoundContainer}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.textContainer}>
              <Typography variant="h1" className={classes.pageTitle}>
                404
              </Typography>
              <Typography variant="h5" className={classes.explanationText}>
                Sorry, the page you're looking for does not exist. If you think something is broken, please report the issue.
              </Typography>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/"
                  className={`${classes.button} ${classes.returnHomeButton}`}
                >
                  Return home
                </Button>
                <Button
                  variant="contained"
                  component={Link}
                  to="/search"
                  className={`${classes.button} ${classes.searchUserButton}`}
                >
                  Search for User
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link} // Add this line
                  to="/contact" // This will now work correctly
                  className={`${classes.button} ${classes.reportProblemButton}`}
                >
                  Report problem
                </Button>

              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

notFound.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticateViaTwitter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  loginUser,
  authenticateViaTwitter
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(NotFoundstyles)(notFound));