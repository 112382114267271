import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LOADED_USER,
  LIKE_CHALLENGE,
  UNLIKE_CHALLENGE,
  MARK_NOTIFICATIONS_READ,
  SET_CSRF_TOKEN,
  CLEAR_CSRF_TOKEN,
  SET_FIREBASE_USER,
} from '../types';

const initialState = {
  authenticated: false,
  // loading: false,
  // If we set this to true, then the loading screen waits for data to finish loading before transitioning
  // But that means the logged out navbar icons never load.
  loading: true,
  credentials: {},
  likes: [],
  notifications: [],
  firebaseUser: null,
  modStatuses: {},
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false
      };
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload
      };
    case SET_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.payload
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LOADED_USER:
      return {
        ...state,
        loading: false
      };
    case LIKE_CHALLENGE:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            bio: state.credentials.bio,
            challengeId: action.payload.challengeId
          }
        ]
      };
    case UNLIKE_CHALLENGE:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.challengeId !== action.payload.challengeId
        )
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state
      };
    case SET_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: action.payload
      };
    case CLEAR_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: null
      };
    default:
      return state;
  }
}

export default userReducer;